import * as React from "react";
import { Select, MenuItem, Typography, SelectChangeEvent } from "@mui/material";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";

type ProfileDeleteRequestDialogProps = {
  onConfirm: React.MouseEventHandler;
  open: boolean;
  onClose: React.MouseEventHandler;
  onIsoCodeChange: (isoCode: string) => void;
};

export const ProfileDeleteRequestDialog = ({
  onClose,
  open,
  onConfirm,
  onIsoCodeChange,
}: ProfileDeleteRequestDialogProps) => {
  const [isoCode, setIsoCode] = React.useState("");
  const handleChange = (event: SelectChangeEvent<string>) => {
    onIsoCodeChange(event.target.value);
    setIsoCode(event.target.value);
  };
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="profile-delete-request-dialog"
      open={open}
    >
      <DialogTitle id="profile-delete-confirm-dialog-title">
        Are you sure you want to delete this profile?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="profile-delete-confirm-dialog-description">
          Profile will be updated to remove PII.<br></br>
          Login will no longer be possible.<br></br>
          Litesport/Liteboxer memberships will be cancelled.<br></br>
          Meta-billed subscriptions must be manually cancelled by the user.
          <br></br>
        </DialogContentText>

        <Typography variant="h6">
          <br></br>User's Country:<p></p>
        </Typography>
        <Select
          labelId="iso3166-country-code-label"
          id="iso3166-country-code"
          value={isoCode}
          label="ISO 3166 Country Code"
          onChange={handleChange}
          required={true}
        >
          <MenuItem value={"US-CA"}>United States - California</MenuItem>
          <MenuItem value={"US"}>United States - All Other States</MenuItem>
          <MenuItem value={"GB"}>United Kingdom</MenuItem>
          <MenuItem value={"CA"}>Canada</MenuItem>
          <MenuItem value={"FR"}>France</MenuItem>
          <MenuItem value={"DE"}>Germany</MenuItem>
          <MenuItem value={"IT"}>Italy</MenuItem>
          <MenuItem value={"MX"}>Mexico</MenuItem>
          <MenuItem value={"NL"}>Netherlands</MenuItem>
          <MenuItem value={"PL"}>Poland</MenuItem>
          <MenuItem value={"ES"}>Spain</MenuItem>
          <MenuItem value={"SE"}>Sweden</MenuItem>
          <MenuItem value={"AT"}>Austria</MenuItem>
          <MenuItem value={"BE"}>Belgium</MenuItem>
          <MenuItem value={"BG"}>Bulgaria</MenuItem>
          <MenuItem value={"HR"}>Croatia</MenuItem>
          <MenuItem value={"CY"}>Cyprus</MenuItem>
          <MenuItem value={"CZ"}>Czech Republic</MenuItem>
          <MenuItem value={"DK"}>Denmark</MenuItem>
          <MenuItem value={"EE"}>Estonia</MenuItem>
          <MenuItem value={"FI"}>Finland</MenuItem>
          <MenuItem value={"GR"}>Greece</MenuItem>
          <MenuItem value={"HU"}>Hungary</MenuItem>
          <MenuItem value={"IE"}>Ireland</MenuItem>
          <MenuItem value={"LV"}>Latvia</MenuItem>
          <MenuItem value={"LT"}>Lithuania</MenuItem>
          <MenuItem value={"LU"}>Luxembourg</MenuItem>
          <MenuItem value={"MT"}>Malta</MenuItem>
          <MenuItem value={"NO"}>Norway</MenuItem>
          <MenuItem value={"PT"}>Portugal</MenuItem>
          <MenuItem value={"RO"}>Romania</MenuItem>
          <MenuItem value={"SK"}>Slovakia</MenuItem>
          <MenuItem value={"SI"}>Slovenia</MenuItem>
          <MenuItem value={"CH"}>Switzerland</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        {isoCode !== "" && (
          <Button variant="outlined" onClick={onConfirm} color="primary">
            Yes, delete the profile
          </Button>
        )}
        <Button
          variant="outlined"
          onClick={onClose}
          color="secondary"
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
