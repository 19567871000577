import React from "react";
import {
  FormControlLabel,
  Grid,
  TextField,
  Switch as MuiSwitch,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

const getErrorMessage = (key: any, errors: any) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};

export const ProgramGroupForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: any;
  onSubmit: any;
}) => {
  const {
    control,
    handleSubmit,
    errors,
    getValues,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });
  const history = useHistory();

  const handleMoveUp = (index: number) => {
    const currentPrograms = getValues("programs");
    if (index === 0) return;
    currentPrograms.splice(index - 1, 0, ...currentPrograms.splice(index, 1));
    setValue("programs", [...currentPrograms]);
  };

  const handleMoveDown = (index: number) => {
    const currentPrograms = getValues("programs");
    if (index === currentPrograms.length - 1) return;
    currentPrograms.splice(index + 1, 0, ...currentPrograms.splice(index, 1));
    setValue("programs", [...currentPrograms]);
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit(async (values) => {
        return onSubmit(values);
      })}
    >
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12}>
          <Controller
            name={"name"}
            rules={{ required: true }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  required
                  fullWidth
                  name={"name"}
                  label={"Name"}
                  helperText={getErrorMessage("name", errors)}
                  error={!!errors.name}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"isActive"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Is Active"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"isVisibleToPremium"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Is Visible To Premium"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"isVisibleToStandard"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Is Visible To Standard"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name={"sortOrder"}
            control={control}
            render={(props) => {
              return (
                <TextField
                  name={"sortOrder"}
                  label={"Sort Order"}
                  helperText={getErrorMessage("sortOrder", errors)}
                  error={!!errors.sortOrder}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={Number(props.value)}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Controller
          name={"programs"}
          control={control}
          render={(props) => {
            return (
              <>
                {props.value && (
                  <Grid item xs={12}>
                    <List
                      dense
                      sx={{
                        width: "100%",
                        maxWidth: 700,
                        bgcolor: "background.paper",
                      }}
                    >
                      {props.value.map((value: any, index: any) => {
                        const labelId = `checkbox-list-secondary-label-${value}`;
                        return (
                          value &&
                          value.id && (
                            <ListItem
                              key={index}
                              secondaryAction={
                                <>
                                  {index !== 0 ? (
                                    <IconButton
                                      aria-label="move up"
                                      color="primary"
                                      onClick={() => handleMoveUp(index)}
                                    >
                                      <ArrowUpward />
                                    </IconButton>
                                  ) : null}
                                  {index !== props.value.length - 1 ? (
                                    <IconButton
                                      aria-label="move down"
                                      color="primary"
                                      onClick={() => handleMoveDown(index)}
                                    >
                                      <ArrowDownward />
                                    </IconButton>
                                  ) : null}
                                  <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    onClick={() => {
                                      const newContent = [...props.value];
                                      newContent.splice(index, 1);
                                      props.onChange(newContent);
                                    }}
                                  ></IconButton>
                                </>
                              }
                              disablePadding
                            >
                              <ListItemButton>
                                <ListItemAvatar>
                                  <Avatar
                                    alt={`${value.name}`}
                                    src={`${value.imageUrl}?height=50`}
                                  />
                                </ListItemAvatar>
                                <Grid container item spacing={2}>
                                  <Grid item xs={2}>
                                    <ListItemText
                                      secondary={
                                        <span>
                                          <span>
                                            Active:{" "}
                                            <Typography
                                              sx={{ display: "inline" }}
                                              component="span"
                                              variant="body2"
                                              color="text.primary"
                                            >
                                              {value.isActive ? "Yes" : "No"}{" "}
                                            </Typography>
                                          </span>{" "}
                                          <span>
                                            Premium:{" "}
                                            <Typography
                                              sx={{ display: "inline" }}
                                              component="span"
                                              variant="body2"
                                              color="text.primary"
                                            >
                                              {value.isVisibleToPremium
                                                ? "Yes"
                                                : "No"}
                                            </Typography>
                                          </span>{" "}
                                          <span>
                                            Standard:{" "}
                                            <Typography
                                              sx={{ display: "inline" }}
                                              component="span"
                                              variant="body2"
                                              color="text.primary"
                                            >
                                              {value.isVisibleToStandard
                                                ? "Yes"
                                                : "No"}
                                            </Typography>
                                          </span>{" "}
                                        </span>
                                      }
                                      id={labelId}
                                      primary={`${value.name}`}
                                      sx={{ width: "450px", marginRight: 2 }} // Adjust the width and margin as needed
                                    />
                                  </Grid>
                                </Grid>
                              </ListItemButton>
                            </ListItem>
                          )
                        );
                      })}
                    </List>
                  </Grid>
                )}
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          type={"submit"}
          sx={{ mr: 2 }}
          disabled={isSubmitting}
        >
          Submit
        </Button>
        <Button
          variant="text"
          disabled={isSubmitting}
          onClick={() => {
            history.push("/program-groups", { replace: true });
          }}
        >
          Cancel
        </Button>
      </Grid>
    </form>
  );
};
