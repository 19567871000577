import { baseUrl } from "../utils/http";

const statsUrl = `${baseUrl}/stats`;

const findStats = async (token, startDate, endDate) => {
  const params = new URLSearchParams({
    start: startDate.toISOString(),
    end: endDate.toISOString(),
  });
  const response = await fetch(`${statsUrl}?${params}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

export { findStats };
