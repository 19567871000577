import { useAuth0, withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import {
  Avatar,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import {
  Loading,
  ReactHookFormCheckbox,
  ReactHookFormSelect,
  roleChip,
  SnackbarMessage,
} from "../components";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridCellParams,
} from "@mui/x-data-grid";
import { useFetch } from "../utils";
import { useDebounce } from "../utils/useDebounce";
import UserDetails from "./user-details";
import { User } from "../api/user-api";

const userTableColumns: GridColDef[] = [
  {
    field: "displayName",
    headerName: "Username",
    renderCell: (value: GridRenderCellParams) => {
      const val = value.value as string;
      return (
        <>
          <Avatar style={{ width: "36px", height: "36px" }}>
            {val && val[0] ? val[0].toUpperCase() : "🚫"}
          </Avatar>
          <div style={{ paddingLeft: "10px" }}>
            {val ? val : "Marketing Profile Only"}
          </div>
        </>
      );
    },
    flex: 2,
  },
  {
    field: "firstName",
    headerName: "First",
    flex: 1,
  },
  {
    field: "lastName",
    headerName: "Last",
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 2,
  },
  {
    field: "role",
    headerName: "Role",
    renderCell: (value: GridRenderCellParams) => {
      return roleChip(value.value as string);
    },
    flex: 1,
  },
  {
    field: "isTrainer",
    headerName: "Trainer",
    renderCell: (value: GridRenderCellParams) => {
      return (value.value as boolean) === true && roleChip("TRAINER");
    },
    flex: 1,
  },
];

type UsersListProps = {
  history: any;
};

const UsersList = withAuth0((props: WithAuth0Props & UsersListProps) => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const debounceSearchTerm = useDebounce(searchTerm, 500);
  const { data, error } = useFetch<User[]>(
    `/users/search?q=${debounceSearchTerm}`
  );

  return (
    <Container className="mb-5">
      <Typography variant="h1" style={{ marginBottom: "10px" }}>
        Users
      </Typography>
      <TextField
        fullWidth
        name={"search"}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        label={"Search"}
        style={{ marginBottom: "20px" }}
      />
      {data && !error ? (
        <DataGrid
          rows={data}
          columns={userTableColumns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          autoHeight
          onCellClick={(params: GridCellParams<User, User, User>) => {
            if (params.row.displayName !== "") {
              history.push(`/users/${params.row.id}/details`);
            }
          }}
        />
      ) : (
        <div>
          Nothing found. Type at least 3 characters to search the start of
          someone's email, username, first name, and last name.
        </div>
      )}
    </Container>
  );
});

type UsersEditProps = {
  match: any;
};

type PostResult = {
  result: string;
  message: string;
};

const UsersEdit = withAuth0((props: WithAuth0Props & UsersEditProps) => {
  const id = parseInt(props.match.params.id, 10);
  const { data, error } = useFetch<User>(`/users/${id}`);
  const [postData, setPostData] = useState(null);
  const [postResult, setPostResult] = useState<PostResult | null>(null);
  const { register, handleSubmit, control } = useForm();
  const { getAccessTokenSilently } = useAuth0();

  const onSubmit = (data: any) => setPostData(data);

  useEffect(() => {
    if (!postData) {
      return;
    }

    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;

      try {
        var cleanedPostData = postData;

        const token = await getAccessTokenSilently({
          audience: `https://api.liteboxer.com`,
        });

        if (!data) {
          return;
        }

        const response = await fetch(`${apiUrl}/users/${data.id}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cleanedPostData),
        });

        const responseData = await response.json();

        if (!response.ok) {
          throw new Error(
            "Error " +
              response.status +
              " " +
              response.statusText +
              " " +
              responseData.status
          );
        }

        if (responseData.status === "Unauthorized") {
          throw new Error("Unauthorized");
        }

        setPostData(null);
        setPostResult({
          result: "success",
          message: "Saved!",
        });
      } catch (error: any) {
        if (error instanceof Error) {
          setPostResult({
            result: "error",
            message: error.message,
          });
        } else {
          setPostResult({
            result: "error",
            message: error.toString(),
          });
        }
      }
    };

    fetchData();
  }, [postData, getAccessTokenSilently, data]);

  if (!data || !error) {
    return <Loading />;
  }

  return (
    <Container className="mb-5">
      <h1>
        User - {data.firstName} {data.lastName}
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            {data.imageUrl && (
              <img
                src={`${data.imageUrl}?height=512`}
                style={{ maxWidth: "70vw" }}
                alt=""
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="displayName"
              label="Display Name"
              color="secondary"
              inputRef={register}
            />
          </Grid>
          <Grid item xs={6}>
            <ReactHookFormSelect
              required
              fullWidth
              name="role"
              label="Role"
              control={control}
              variant="outlined"
              color="secondary"
              defaultValue={data.status || ""}
            >
              <MenuItem value={"MOBILE_USER"}>User</MenuItem>
              <MenuItem value={"LITEBOXER_ADMIN"}>Admin</MenuItem>
              <MenuItem value={"LITEBOXER_CONTENT_AUTHOR"}>
                Content Author
              </MenuItem>
            </ReactHookFormSelect>
          </Grid>
          <Grid item xs={6}>
            <ReactHookFormCheckbox
              name="isTrainer"
              label="Trainer"
              control={control}
              defaultValue={data.isTrainer}
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
      {error && <SnackbarMessage severity="error">{error}</SnackbarMessage>}
      {postResult && (
        <SnackbarMessage
          severity={postResult.result}
          onClose={() => setPostResult(null)}
        >
          {postResult.message}
        </SnackbarMessage>
      )}
    </Container>
  );
});

export function Users() {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:id/details`} component={UserDetails} />
      <Route path={`${match.path}/:id`} component={UsersEdit} />
      <Route path={match.path} component={UsersList} />
    </Switch>
  );
}

export default Users;
