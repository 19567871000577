import { Container, Typography } from "@mui/material";
import * as React from "react";
import * as contentBundlesApi from "../../api/content-bundles-api";
import { useHistory, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useContentBundles } from "../../api/content-bundles-api";
import { ContentBundleForm } from "./content-bundle-form";

export const EditContentBundle = () => {
  const { id } = useParams();
  const contentBundlesQuery = useContentBundles();
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  if (contentBundlesQuery.isLoading) {
    return <div>loading...</div>;
  }

  const content = contentBundlesQuery.data.find((d) => {
    return d.id.toString() === id;
  });
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Edit Content Bundle
      </Typography>
      <div style={{ marginBottom: "10px" }}>
        <Typography variant="h6" gutterBottom component="div">
          Image
        </Typography>
        <img src={content.imageUrl} alt={""} />
      </div>
      <ContentBundleForm
        imagesRequired={false}
        onSubmit={async (values, setError) => {
          const token = await getAccessTokenSilently();
          try {
            await contentBundlesApi.updateContentBundle({
              id,
              token,
              ...values,
            });
            history.push("/content_bundles");
          } catch (e) {
            Object.keys(e.message.error).forEach((key) => {
              setError(key, {
                type: "manual",
                message: e.message.error[key],
              });
            });
          }
        }}
        defaultValues={{
          bundleSku: content.bundleSku,
          contentIds: content.contentIds,
          title: content.title,
          image: content.image,
          description: content.description,
          difficulty: content.difficulty,
          status: content.status,
        }}
      />
    </Container>
  );
};
