import React, { useState } from "react";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useQuickplayContent } from "../../api/quickplayApi";
import { DataGrid } from "@mui/x-data-grid";
import AddBoxIcon from "@mui/icons-material/AddBox";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import { UploadButton } from "../../components/upload-button";
import { useHistory } from "react-router-dom";
import { DateTimePicker } from "@mui/lab";

const getErrorMessage = (key, errors) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};

const FindContent = ({ onAdd }) => {
  const quickplayContentQuery = useQuickplayContent();
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageText, setMessageText] = useState("");

  const columns = [
    {
      field: "imageUrl",
      headerName: "",
      width: 50,
      renderCell: (params) => {
        return <img alt={"preview"} src={`${params.value}?height=50`} />;
      },
    },
    {
      field: "displayName",
      headerName: "Name",
      width: 250,
      renderCell: (params) => {
        return <div>{params.value}</div>;
      },
    },
    {
      field: "artist",
      headerName: "Artist",
      width: 250,
      renderCell: (params) => {
        return <div>{params.value.name}</div>;
      },
    },
    {
      field: "genres",
      headerName: "Genre",
      width: 200,
      renderCell: (params) => {
        return <div>{params.value?.join(", ")}</div>;
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return <div>{params.value}</div>;
      },
    },
    {
      field: "isPremium",
      headerName: "Premium",
      width: 100,
    },
    {
      field: "isStandard",
      headerName: "Standard",
      width: 100,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderCell: (params) => {
        return (
          <Button
            startIcon={<AddBoxIcon />}
            variant="text"
            onClick={() => {
              setMessageOpen(true);
              setMessageText(`Added ${params.row.displayName} to challenge`);
              onAdd(params.row);
            }}
          >
            Add
          </Button>
        );
      },
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [nameFilter, setNameFilter] = React.useState("");
  const lowerNameFilter = nameFilter.toLowerCase();
  const rows =
    quickplayContentQuery.data?.songs?.filter((item) => {
      if (nameFilter !== "") {
        return item.displayName.toLowerCase().includes(lowerNameFilter);
      } else {
        return true;
      }
    }) ?? [];

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={messageOpen}
        autoHideDuration={1000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setMessageOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setMessageOpen(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {messageText}
        </Alert>
      </Snackbar>
      <Button variant="outlined" color={"secondary"} onClick={handleClickOpen}>
        Add Content
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <DialogTitle>Select Content</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: 1, marginBottom: 2 }}
            name={"Name"}
            value={nameFilter}
            label={"Song Name"}
            onChange={(event) => {
              setNameFilter(event.target.value);
            }}
          />
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 50, 100]}
            disableSelectionOnClick
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Exit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const RoundsChallengeForm = ({
  defaultValues,
  onSubmit,
  imagesRequired,
}) => {
  const {
    control,
    handleSubmit,
    register,
    errors,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });
  const history = useHistory();
  return (
    <form
      noValidate
      onSubmit={handleSubmit(async (values) => {
        return onSubmit(values);
      })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name={"name"}
            rules={{ required: true }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  required
                  fullWidth
                  name={"name"}
                  label={"Name"}
                  helperText={getErrorMessage("name", errors)}
                  error={!!errors.name}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"description"}
            control={control}
            render={(props) => {
              return (
                <TextField
                  fullWidth
                  name={"description"}
                  multiline
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                  label={"Description"}
                  rows={3}
                />
              );
            }}
          />
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={3}>
            <Controller
              name={"deviceType"}
              control={control}
              rules={{ required: true }}
              render={(props) => {
                return (
                  <TextField
                    required
                    fullWidth
                    select
                    name={"deviceType"}
                    label="Device Type"
                    helperText={getErrorMessage("deviceType", errors)}
                    error={!!errors.deviceType}
                    onChange={(value) => {
                      props.onChange(value.target.value);
                    }}
                    value={props.value}
                  >
                    <MenuItem value={"VR"}>VR</MenuItem>
                    <MenuItem value={"LB"}>LB</MenuItem>
                    <MenuItem value={"GO"}>GO</MenuItem>
                  </TextField>
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={3}>
            <Controller
              name={"startAt"}
              control={control}
              render={(props) => {
                return (
                  <DateTimePicker
                    label="Start At"
                    value={props.value}
                    onChange={(newValue) => {
                      props.onChange(newValue);
                    }}
                    id="start-date-picker"
                    renderInput={(inputProps) => (
                      <TextField fullWidth {...inputProps} />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={"endAt"}
              control={control}
              render={(props) => {
                return (
                  <DateTimePicker
                    label="End At"
                    value={props.value}
                    onChange={(newValue) => {
                      props.onChange(newValue);
                    }}
                    id="end-date-picker"
                    renderInput={(inputProps) => (
                      <TextField fullWidth {...inputProps} />
                    )}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <UploadButton
            name="overviewImage"
            label={"Overview Image"}
            value={getValues("overviewImage")}
            error={getErrorMessage("overviewImage", errors)}
            ref={register({ required: imagesRequired })}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadButton
            name="detailImage"
            label={"Detail Image"}
            value={getValues("detailImage")}
            error={getErrorMessage("detailImage", errors)}
            ref={register({ required: imagesRequired })}
          />
        </Grid>

        <Controller
          name={"content"}
          control={control}
          render={(props) => {
            return (
              <>
                <Grid item xs={12}>
                  <FindContent
                    onAdd={(added) => {
                      props.onChange([...props.value, added]);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <List
                    dense
                    sx={{
                      width: "100%",
                      maxWidth: 700,
                      bgcolor: "background.paper",
                    }}
                  >
                    {props.value.map((value, index) => {
                      const labelId = `checkbox-list-secondary-label-${value}`;
                      return (
                        <ListItem
                          key={index}
                          secondaryAction={
                            <>
                              {index !== 0 ? (
                                <IconButton
                                  aria-label="move up"
                                  color="primary"
                                  onClick={() => {
                                    const newContent = [...props.value];
                                    newContent.splice(
                                      index - 1,
                                      0,
                                      ...newContent.splice(index, 1)
                                    );
                                    props.onChange(newContent);
                                  }}
                                >
                                  <ArrowUpward />
                                </IconButton>
                              ) : null}
                              {index !== props.value.length - 1 ? (
                                <IconButton
                                  aria-label="move down"
                                  color="primary"
                                  onClick={() => {
                                    const newContent = [...props.value];
                                    newContent.splice(
                                      index + 1,
                                      0,
                                      ...newContent.splice(index, 1)
                                    );
                                    props.onChange(newContent);
                                  }}
                                >
                                  <ArrowDownward />
                                </IconButton>
                              ) : null}
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={() => {
                                  const newContent = [...props.value];
                                  newContent.splice(index, 1);
                                  props.onChange(newContent);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          }
                          disablePadding
                        >
                          <ListItemButton>
                            <ListItemAvatar>
                              <Avatar
                                alt={`${value.displayName}`}
                                src={`${value.imageUrl}?height=50`}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              secondary={
                                <span>
                                  <span>
                                    Status:{" "}
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      {value.status}{" "}
                                    </Typography>
                                  </span>{" "}
                                  <span>
                                    Premium:{" "}
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      {value.isPremium ? "Yes" : "No"}
                                    </Typography>
                                  </span>{" "}
                                  <span>
                                    Standard:{" "}
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      {value.isStandard ? "Yes" : "No"}
                                    </Typography>
                                  </span>{" "}
                                  <span>
                                    Genres:{" "}
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      {value.genres?.join(", ") ?? ""}
                                    </Typography>
                                  </span>
                                </span>
                              }
                              id={labelId}
                              primary={`${value.displayName}`}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          type={"submit"}
          sx={{ mr: 2 }}
          disabled={isSubmitting}
        >
          Submit
        </Button>
        <Button
          variant="text"
          disabled={isSubmitting}
          onClick={() => {
            history.push("/challenges");
          }}
        >
          Cancel
        </Button>
      </Grid>
    </form>
  );
};
