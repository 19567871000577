import * as React from "react";
import Container from "@mui/material/Container";
import { Grid, Typography } from "@mui/material";
import { ColorButton } from "../components";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import IntroVideoForm from "../components/intro-video-form";
import { DataGrid } from "@mui/x-data-grid";
import * as introContentApi from "../api/intro-content-api";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";

export const IntroIndex = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <IntroVideoCreate />
      </Route>
      <Route path={`${match.path}/:id`}>
        <IntroContentEdit />
      </Route>

      <Route path={match.path} component={IntroVidList} />
    </Switch>
  );
};
const IntroContentEdit = () => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const { id } = useParams();
  const { data = [], isLoading } = useQuery(["intro-content"], () => {
    return getAccessTokenSilently().then((token) => {
      return introContentApi.findAll({ token });
    });
  });
  if (isLoading) {
    return <div>loading...</div>;
  }
  const existing = data.find((d) => d.guid === id);

  return (
    <Container className={"mb-5"} maxWidth={"md"}>
      <Typography variant="h1">Update Intro Video</Typography>
      <img
        src={`${existing.coverArtUrl}?height=512`}
        style={{
          maxWidth: "70vw",
          maxHeight: "35vw",
          width: "1024px",
          height: "512px",
          marginBottom: "20px",
        }}
        alt=""
      />
      <IntroVideoForm
        defaultValues={{
          displayName: existing.displayName,
          description: existing.description,
          vrEnabled: existing.vrEnabled,
          lbEnabled: existing.lbEnabled,
          goEnabled: existing.goEnabled,
          length: `${existing.lengthInSeconds}s`,
          status: existing.status,
          publishedDate: new Date(existing.publishedDate)
            .toISOString()
            .substr(0, 10),
          videoUrl: existing.videoUrl,
        }}
        onCancel={() => {
          history.push("/intro");
        }}
        onSubmit={(data, setError) => {
          return getAccessTokenSilently()
            .then((token) => {
              const { coverArt, track, trainer, ...rest } = data;
              return introContentApi.updateIntroContent({
                ...rest,
                length: data.length,
                publishedDate: new Date(data.publishedDate).toISOString(),
                coverArt: coverArt[0],
                token,
                id: id,
              });
            })
            .then(() => {
              history.push("/intro");
            })
            .catch((e) => {
              if (e.response.data && e.response.data.error) {
                Object.keys(e.response.data.error).forEach((key) => {
                  setError(key, {
                    type: "manual",
                    message: e.response.data.error[key],
                  });
                });
              }
              console.error(e);
            });
        }}
      />
    </Container>
  );
};
const IntroVideoCreate = () => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  return (
    <Container className={"mb-5"} maxWidth={"md"}>
      <Typography variant="h1">Create Intro Video</Typography>
      <IntroVideoForm
        defaultValues={{
          displayName: "",
          vrEnabled: false,
          lbEnabled: false,
          goEnabled: false,
          description: "",
          length: "",
          status: "",
          publishedDate: "",
          videoUrl: "",
        }}
        onCancel={() => {
          history.push("/intro");
        }}
        onSubmit={(data, setError) => {
          return getAccessTokenSilently()
            .then((token) => {
              const { coverArt, track, trainer, ...rest } = data;
              return introContentApi.createIntroContent({
                ...rest,
                length: data.length,
                publishedDate: new Date(data.publishedDate).toISOString(),
                coverArt: coverArt[0],
                token,
              });
            })
            .then(() => {
              history.push("/intro");
            })
            .catch((e) => {
              if (e.response.data && e.response.data.error) {
                Object.keys(e.response.data.error).forEach((key) => {
                  setError(key, {
                    type: "manual",
                    message: e.response.data.error[key],
                  });
                });
              }
              console.error(e);
            });
        }}
      />
    </Container>
  );
};

const columns = [
  {
    field: "coverArtUrl",
    width: 50,
    headerName: " ",
    renderCell: ({ value }) => {
      return (
        <img
          key={value}
          src={`${value}?width=50`}
          height="36px"
          width="72px"
          alt=""
        />
      );
    },
  },
  {
    field: "displayName",
    headerName: "Display Name",
    flex: 1,
    renderCell: ({ value, row }) => {
      return <Link to={`/intro/${row.guid}`}>{value}</Link>;
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "publishedDate",
    headerName: "Published Date",
    width: 250,
  },
];

export const IntroVidList = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { data = [] } = useQuery(["intro-content"], () => {
    return getAccessTokenSilently().then((token) => {
      return introContentApi.findAll({ token });
    });
  });
  return (
    <Container className="mb-5">
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h1">Intro Videos</Typography>
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
          <ColorButton
            color="green"
            component={Link}
            to={"/intro/create"}
            variant="contained"
          >
            Add New{" "}
          </ColorButton>
        </Grid>
      </Grid>
      <div style={{ width: "100%", minHeight: 400 }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={20}
          autoHeight
          disableSelectionOnClick
        />
      </div>
    </Container>
  );
};
