import * as React from "react";
import { Container, Typography } from "@mui/material";
import LatestWorkoutList from "../components/latest-workout-list";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const WorkoutLog = () => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Typography variant="h2">Latest Plays</Typography>
      <LatestWorkoutList />
    </Container>
  );
};

export default WorkoutLog;
