import { baseUrl } from "../utils/http";
import { useMutation, usePaginatedQuery, useQueryCache } from "react-query";

export const findAllUsers = async ({ token }: { token: string }) => {
  const response = await fetch(`${baseUrl}/users`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

export const getCurrencyFormatter = () => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  status: string;
  isTrainer: boolean;
  displayName: string;
  role: string;
  uuid: string;
  grantStandardUntil?: string;
  oculusUserId?: string;
};

const getPageParams = (page: number, rowsPerPage: number) =>
  new URLSearchParams({
    page: page.toString(),
    rowsPerPage: rowsPerPage.toString(),
  }).toString();

export const findUserWorkouts = async ({
  token,
  profileId,
  page,
  rowsPerPage,
}: PagedResponse) => {
  const params = getPageParams(page, rowsPerPage);
  const response = await fetch(
    `${baseUrl}/users/${profileId}/workouts?${params}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return await response.json();
};

export const findUserWorkoutLog = async ({
  token,
  profileId,
  page,
  rowsPerPage,
}: PagedResponse) => {
  const params = getPageParams(page, rowsPerPage);
  const response = await fetch(
    `${baseUrl}/users/${profileId}/workout_log?${params}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return await response.json();
};

type FindUserPreferencesProps = {
  token: string;
  profileId: number;
};

export const findUserPreferences = async ({
  token,
  profileId,
}: FindUserPreferencesProps) => {
  const response = await fetch(`${baseUrl}/users/${profileId}/preferences`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  const preferences = await response.json();
  return {
    count: preferences.length,
    items: preferences,
  };
};

export const findUserMemberships = async ({
  token,
  profileId,
}: FindUserPreferencesProps) => {
  const response = await fetch(`${baseUrl}/users/${profileId}/memberships`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

// @TODO: Flesh out from the API
type AuditLogItem = {
  event_type: string;
};

export const findFlaggedUserPictures = async ({ token }: { token: string }) => {
  const response = await fetch(`${baseUrl}/users/pictures/flagged`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  const auditLogActions = await response.json();
  return auditLogActions.filter(
    (item: AuditLogItem) => item.event_type === "PROFILE_IMAGE_FLAGGED"
  );
};

type UnflagPictureProps = {
  token: string;
  profileId: number;
  imageUrl: string;
};
export const unflagPicture = async ({
  token,
  profileId,
  imageUrl,
}: UnflagPictureProps) => {
  return await fetch(
    `${baseUrl}/users/${profileId}/pictures/unflag?` +
      new URLSearchParams({
        imageUrl: imageUrl,
      }),
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export type TransactionsResponse = {
  transactions: Transaction[];
};

export type Transaction = {
  id: string;
  createdAt: string;
  status: string;
  amount: string;
  subscriptionId: string;
  billingPeriod: string;
  last4: string;
  cardType: string;
  refundId: string;
  refundedTransactionId: string | null;
  processorResponse: string;
  gatewayRejectionReason: string;
  paypalEmail: string;
  paymentMethodType: string;
  disputeAmount: number | null;
  disputeCaseNumber: string | null;
  disputeReason: string | null;
  disputeReceivedOn: Date | null;
  disputeStatus: string | null;
};

type PagedResponse = {
  token: string;
  profileId: number;
  page: number;
  rowsPerPage: number;
};

export const findUserTransactions = async ({
  token,
  profileId,
  page,
  rowsPerPage,
}: PagedResponse): Promise<TransactionsResponse> => {
  const response = await fetch(`${baseUrl}/users/${profileId}/transactions`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

export const refundTransaction = (
  token: string,
  profileId: number,
  transactionId: string
  //comment: string,
) => {
  return fetch(`${baseUrl}/users/${profileId}/transactions/${transactionId}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    //body: JSON.stringify({ comment }),
  });
};

export const requestProfileDelete = (
  token: string,
  uuid: string,
  iso3166Code: string
) => {
  return fetch(`${baseUrl}/profiles/${uuid}/${iso3166Code}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const setGrantAccess = (
  token: string,
  id: number,
  grantStandardUntil: Date
) => {
  return fetch(`${baseUrl}/users/${id}/standard_grant/${grantStandardUntil}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const removeGrantAccess = (token: string, id: number) => {
  return fetch(`${baseUrl}/users/${id}/standard_grant`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const useTransactions = (
  token: string,
  profileId: number,
  page: number,
  rowsPerPage: number
) => {
  return usePaginatedQuery<TransactionsResponse>(
    ["userTransactions", profileId, page, rowsPerPage],
    async () => {
      return await findUserTransactions({
        token,
        profileId,
        page,
        rowsPerPage,
      });
    }
  );
};

export const useRefundTransaction = () => {
  const queryCache = useQueryCache();
  return useMutation(
    ({
      token,
      profileId,
      transactionId,
    }: {
      token: string;
      profileId: number;
      transactionId: string;
    }) => {
      return refundTransaction(token, profileId, transactionId).then(
        (response) => {
          if (!response.ok) {
            throw new Error(
              "Could not complete refund. Contact systems team for support."
            );
          }
        }
      );
    },
    {
      onSettled: () => {
        queryCache.invalidateQueries(["transactions"]);
        queryCache.invalidateQueries(["userTransactions"]);
      },
    }
  );
};

export interface MembershipsResponse {
  memberships?: Array<MembershipResponse>;
}
export interface MembershipResponse {
  cardLast4?: string;
  cardExpireMonth?: string;
  cardExpireYear?: string;
  nextBillDate?: string;
  nextBillAmount?: number;
  status: MembershipStatus;
  cardImageUrl?: string;
  payPalAccountEmail?: string;
  deviceId?: string;
  membershipId: string;
  planName: string;
  planId?: string;
  paidThroughDate?: string;
  daysPastDue?: string;
  billingPeriodStartDate?: string;
  billingPeriodEndDate?: string;
  planType: string;
  goDeviceId?: string;
  vrDeviceId?: string;
  pastDueBalance?: string;
  id: string;
  planChangeCredit?: string;
  cost?: string;
  billingIntervalMonths?: number;
  braintreeSubscriptionID?: string;
}

export type MembershipStatus =
  | "TRIAL"
  | "ACTIVE"
  | "CANCELLED"
  | "EXPIRED"
  | "PAST_DUE";
