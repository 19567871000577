import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DraftsIcon from "@mui/icons-material/Drafts";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ArchiveIcon from "@mui/icons-material/Archive";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import Person from "@mui/icons-material/Person";
import MusicNote from "@mui/icons-material/MusicNote";
import {
  default as PublishIcon,
  default as Security,
} from "@mui/icons-material/Security";
import React from "react";
import { ColorChip } from ".";

const roleChip = (roleLabel) => {
  switch (roleLabel) {
    case "TRAINER":
      return (
        <ColorChip
          color="green"
          variant="outlined"
          size="small"
          label="Trainer"
          icon={<FitnessCenterIcon />}
        />
      );
    case "LITEBOXER_ADMIN":
      return (
        <ColorChip
          color="orange"
          variant="outlined"
          size="small"
          label="Admin"
          icon={<Security />}
        />
      );
    case "MOBILE_USER":
      return (
        <ColorChip
          color="primary"
          variant="outlined"
          size="small"
          label="User"
          icon={<Person />}
        />
      );
    case "LITEBOXER_CONTENT_AUTHOR":
      return (
        <ColorChip
          color="green"
          variant="outlined"
          size="small"
          label="Author"
          icon={<MusicNote />}
        />
      );
    default:
      return "";
  }
};

const statusChip = (statusLabel) => {
  switch (statusLabel) {
    case "DRAFT":
      return (
        <ColorChip
          color="orange"
          variant="outlined"
          size="small"
          label="Draft"
          icon={<DraftsIcon />}
        />
      );
    case "PUBLISHED":
      return (
        <ColorChip
          color="green"
          variant="outlined"
          size="small"
          label="Published"
          icon={<PublishIcon />}
        />
      );
    case "SCHEDULED":
      return (
        <ColorChip
          color="grey"
          variant="outlined"
          size="small"
          label="Scheduled"
          icon={<ScheduleIcon />}
        />
      );
    case "RETIRED":
      return (
        <ColorChip
          color="grey"
          variant="outlined"
          size="small"
          label="Retired"
          icon={<ArchiveIcon />}
        />
      );
    case "PREMIUM":
      return (
        <ColorChip
          color="green"
          variant="outlined"
          size="small"
          label="Premium"
          icon={<AttachMoneyIcon />}
        />
      );
    case "STANDARD":
      return (
        <ColorChip
          color="green"
          variant="outlined"
          size="small"
          label="Standard"
          icon={<AttachMoneyIcon />}
        />
      );
    default:
      return "";
  }
};

export { statusChip, roleChip };
