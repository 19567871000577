import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import { Alert } from "@mui/material";

export const MembershipResubscribeDialog = ({
  onClose,
  open,
  onConfirm,
  isInternal,
}) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="membership-confirm-dialog"
      open={open}
    >
      <DialogTitle id="membership-confirm-dialog-title">
        Are you sure you want to activate this membership?
      </DialogTitle>
      <DialogContent>
        {isInternal ? (
          <DialogContentText id="membership-confirm-dialog-description">
            "The user will have access to premium content again."
          </DialogContentText>
        ) : (
          <Alert severity="warning">
            Customer will be charged immediately! This will attempt to add a new
            recurring billing subscribe in our payment processor using the card
            on file. It is important to let the customer know they will be
            charged immediately
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onConfirm} color="secondary">
          Yes, resubscribe to membership
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          color="secondary"
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
