type videoPlayerSpec = {
  ScaleX: number;
  ScaleY: number;
  ScaleZ: number;
  PositionX: number;
  PositionY: number;
  PositionZ: number;
  FlipHorizontal: boolean;
  AlphaStackingMode: number;
  StereoPackingMode: number;
  ApplyGreenKeyShader: boolean;
};

type version = {
  id: string;
  name: string;
  filmedInVr: boolean;
  minAppVersion?: string;
  vrVideoPlayerSpec?: videoPlayerSpec;
};
export const v2024April: version = {
  id: "2024-April",
  name: "2024-April",
  filmedInVr: true,
  minAppVersion: "1.14.257",
  vrVideoPlayerSpec: {
    ScaleX: 8.5,
    ScaleY: 8.5,
    ScaleZ: 8.5,
    PositionX: 0,
    PositionY: -0.9,
    PositionZ: -5,
    FlipHorizontal: false,
    AlphaStackingMode: 2,
    StereoPackingMode: 0,
    ApplyGreenKeyShader: false,
  },
};
export const v2024AprilMittDrill: version = {
  id: "2024-April-Mitt",
  name: "2024-April-Mitt",
  filmedInVr: true,
  minAppVersion: "1.14.257",
  vrVideoPlayerSpec: {
    ScaleX: 5.5,
    ScaleY: 5.5,
    ScaleZ: 5.5,
    PositionX: 0,
    PositionY: -0.5,
    PositionZ: -1.4,
    FlipHorizontal: false,
    AlphaStackingMode: 2,
    StereoPackingMode: 0,
    ApplyGreenKeyShader: false,
  },
};
export const v2022March: version = {
  id: "2022-March",
  name: "2022-March",
  filmedInVr: true,
  minAppVersion: undefined,
  vrVideoPlayerSpec: undefined,
};
export const v2020July: version = {
  id: "2020-July",
  name: "2020-July",
  filmedInVr: false,
  minAppVersion: undefined,
  vrVideoPlayerSpec: undefined,
};
export const vCustom: version = {
  id: "Custom",
  name: "Custom",
  filmedInVr: true,
  minAppVersion: undefined,
  vrVideoPlayerSpec: undefined,
};

export const contentVersions: version[] = [
  v2024April,
  v2024AprilMittDrill,
  v2022March,
  v2020July,
  vCustom,
];

export const contentVersion = (
  filmedInVr: boolean,
  vrVideoPlayerSpec: videoPlayerSpec,
  minAppVersion: string
) => {
  var contentVersion: version;
  if (
    filmedInVr === true &&
    vrVideoPlayerSpec &&
    JSON.stringify(vrVideoPlayerSpec) ===
      '{"ScaleX":8.5,"ScaleY":8.5,"ScaleZ":8.5,"PositionX":0,"PositionY":-0.9,"PositionZ":-5,"FlipHorizontal":false,"AlphaStackingMode":2,"StereoPackingMode":0,"ApplyGreenKeyShader":false}' &&
    minAppVersion &&
    minAppVersion === "1.14.257"
  )
    contentVersion = v2024April;
  else if (
    filmedInVr === true &&
    vrVideoPlayerSpec &&
    JSON.stringify(vrVideoPlayerSpec) ===
      '{"ScaleX":5.5,"ScaleY":5.5,"ScaleZ":5.5,"PositionX":0,"PositionY":-0.5,"PositionZ":-1.4,"FlipHorizontal":false,"AlphaStackingMode":2,"StereoPackingMode":0,"ApplyGreenKeyShader":false}' &&
    minAppVersion &&
    minAppVersion === "1.14.257"
  )
    contentVersion = v2024AprilMittDrill;
  else if (
    (filmedInVr === true &&
      (vrVideoPlayerSpec === undefined || {}) &&
      minAppVersion === "") ||
    undefined
  )
    contentVersion = v2022March;
  else if (
    filmedInVr === false &&
    (vrVideoPlayerSpec === undefined || {}) &&
    minAppVersion === ""
  )
    contentVersion = v2020July;
  else contentVersion = vCustom;
  return contentVersion;
};
