import { Container, Typography } from "@mui/material";
import { PlaylistForm } from "./playlists-form";
import * as React from "react";
import * as playlistsApi from "../../api/playlists-api";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Playlist } from "../../api/playlists-api";

export const CreatePlaylist = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Create New Playlist
      </Typography>
      <PlaylistForm
        imagesRequired={true}
        onSubmit={async (playlist: Playlist) => {
          const token = await getAccessTokenSilently();
          await playlistsApi.createPlaylist({
            token,
            playlist,
          });
          history.push("/playlists");
        }}
        defaultValues={{
          name: "",
          description: "",
          isPublic: false,
          isFavorites: false,
          brandId: "",
          isProgram: false,
          weeksToComplete: "",
          recommendedClassesPerWeek: "",
          minutesPerDay: "",
          content: [],
          imageUrl: null,
          brand: "",
        }}
      />
    </Container>
  );
};
