import React, { useState } from "react";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
  Switch as MuiSwitch,
  Autocomplete,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useTrainerContent } from "../../api/trainer-track-api";
import { DataGrid } from "@mui/x-data-grid";
import AddBoxIcon from "@mui/icons-material/AddBox";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import { UploadButton } from "../../components/upload-button";
import { useHistory } from "react-router-dom";
import * as brandApi from "../../api/brand-api";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";

const getErrorMessage = (key: any, errors: any) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};

const FindContent = ({ onAdd }: any) => {
  const contentQuery = useTrainerContent();
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageText, setMessageText] = useState("");

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 30,
      renderCell: (params: any) => {
        return <div>{params.value}</div>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderCell: (params: any) => {
        return (
          <Button
            startIcon={<AddBoxIcon />}
            variant="text"
            onClick={() => {
              setMessageOpen(true);
              setMessageText(`Added ${params.row.displayName} to playlist`);
              onAdd(params.row);
            }}
          >
            Add
          </Button>
        );
      },
    },
    {
      field: "imageUrl",
      headerName: "",
      width: 100,
      renderCell: (params: any) => {
        return <img alt={"preview"} src={`${params.value}?height=50`} />;
      },
    },
    {
      field: "displayName",
      headerName: "Name",
      width: 300,
      renderCell: (params: any) => {
        return <div>{params.value}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params: any) => {
        return <div>{params.value}</div>;
      },
    },
    {
      field: "isPremium",
      headerName: "Premium",
      width: 100,
    },
    {
      field: "isStandard",
      headerName: "Standard",
      width: 100,
    },
    {
      field: "brand",
      headerName: "Brand",
      width: 200,
      renderCell: (params: any) => {
        return <div>{params.value?.name}</div>;
      },
    },
    {
      field: "publishedDate",
      headerName: "Published Date",
      width: 200,
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [nameFilter, setNameFilter] = React.useState("");
  const [idFilter, setIdFilter] = React.useState("");
  const lowerNameFilter = nameFilter.toLowerCase();
  const rows =
    contentQuery.data?.tracks.filter((item: any) => {
      if (nameFilter !== "") {
        return item.displayName.toLowerCase().includes(lowerNameFilter);
      } else if (idFilter !== "") {
        return item.id.toString() === idFilter;
      } else {
        return true;
      }
    }) ?? [];

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={messageOpen}
        autoHideDuration={1000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setMessageOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setMessageOpen(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {messageText}
        </Alert>
      </Snackbar>
      <Button variant="outlined" color={"secondary"} onClick={handleClickOpen}>
        Add Content
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <DialogTitle>Select Content</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: 1, marginBottom: 2 }}
            name={"Name"}
            value={nameFilter}
            label={"Content Name"}
            onChange={(event) => {
              setNameFilter(event.target.value);
            }}
          />
          <TextField
            sx={{ marginTop: 1, marginBottom: 2 }}
            name={"contentId"}
            value={idFilter}
            label={"Content ID"}
            onChange={(event) => {
              setIdFilter(event.target.value);
            }}
          />
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 50, 100]}
            disableSelectionOnClick
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Exit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const PlaylistForm = ({
  defaultValues,
  onSubmit,
  imagesRequired,
}: {
  defaultValues: any;
  onSubmit: any;
  imagesRequired: boolean;
}) => {
  const {
    control,
    handleSubmit,
    register,
    errors,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [isProgram, setIsProgram] = useState(defaultValues?.isProgram ?? false);
  const { data: brands = [] } = useQuery("brands", async () => {
    const token = await getAccessTokenSilently();
    return brandApi.findAllBrands(token);
  });
  return (
    <form
      noValidate
      onSubmit={handleSubmit(async (values) => {
        return onSubmit(values);
      })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name={"name"}
            rules={{ required: true }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  required
                  fullWidth
                  name={"name"}
                  label={"Name"}
                  helperText={getErrorMessage("name", errors)}
                  error={!!errors.name}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"description"}
            control={control}
            render={(props) => {
              return (
                <TextField
                  fullWidth
                  name={"description"}
                  multiline
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                  label={"Description"}
                  rows={3}
                />
              );
            }}
          />
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <Controller
              name={"brand"}
              render={(props) => (
                <Autocomplete
                  {...props}
                  options={
                    props.value === ""
                      ? [{ id: props.value, name: "" }, ...brands]
                      : brands
                  }
                  getOptionLabel={(option) => {
                    return option.name ? option.name : "";
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return option.id === value;
                    } else {
                      return option.id === value.id;
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Brand" variant="outlined" />
                  )}
                  onChange={(_, data) => props.onChange(data)}
                />
              )}
              control={control}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <UploadButton
            name="image"
            label={"Image"}
            value={getValues("image")}
            error={getErrorMessage("image", errors)}
            ref={register({ required: imagesRequired })}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"isPublic"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Is Public"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"isProgram"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                        setIsProgram(e.target.checked);
                      }}
                    />
                  }
                  label="Is Program"
                />
              );
            }}
          />
        </Grid>
        <Grid container item spacing={2}>
          {isProgram && (
            <>
              <Grid item xs={4}>
                {
                  <Controller
                    name={"weeksToComplete"}
                    rules={{ required: isProgram }}
                    control={control}
                    render={(props) => {
                      return (
                        <TextField
                          required={isProgram}
                          fullWidth
                          name={"weeksToComplete"}
                          label={"Weeks to Complete"}
                          helperText={getErrorMessage(
                            "weeksToComplete",
                            errors
                          )}
                          error={!!errors.weeksToComplete}
                          onChange={(value) => {
                            props.onChange(value.target.value);
                          }}
                          value={props.value}
                        />
                      );
                    }}
                  />
                }
              </Grid>
              <Grid container item spacing={2}></Grid>
              <Grid item xs={4}>
                <Controller
                  name={"recommendedClassesPerWeek"}
                  rules={{ required: isProgram }}
                  control={control}
                  render={(props) => {
                    return (
                      <TextField
                        required={isProgram}
                        fullWidth
                        name={"recommendedClassesPerWeek"}
                        label={"Recommended Classes Per Week"}
                        helperText={getErrorMessage(
                          "recommendedClassesPerWeek",
                          errors
                        )}
                        error={!!errors.weeksToComplete}
                        onChange={(value) => {
                          props.onChange(value.target.value);
                        }}
                        value={props.value}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid container item spacing={2}></Grid>
              <Grid item xs={4}>
                {
                  <Controller
                    name={"minutesPerDay"}
                    rules={{ required: isProgram }}
                    control={control}
                    render={(props) => {
                      return (
                        <TextField
                          required={isProgram}
                          fullWidth
                          name={"minutesPerDay"}
                          label={"Minutes Per Day"}
                          helperText={getErrorMessage("minutesPerDay", errors)}
                          error={!!errors.minutesPerDay}
                          onChange={(value) => {
                            props.onChange(value.target.value);
                          }}
                          value={props.value}
                        />
                      );
                    }}
                  />
                }
              </Grid>
            </>
          )}
          <Controller
            name={"content"}
            control={control}
            render={(props) => {
              return (
                <>
                  <Grid item xs={12}>
                    <FindContent
                      onAdd={(added: any) => {
                        props.onChange([...props.value, added]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <List
                      dense
                      sx={{
                        width: "100%",
                        maxWidth: 700,
                        bgcolor: "background.paper",
                      }}
                    >
                      {props.value.map((value: any, index: any) => {
                        const labelId = `checkbox-list-secondary-label-${value}`;
                        return (
                          <ListItem
                            key={index}
                            secondaryAction={
                              <>
                                {index !== 0 ? (
                                  <IconButton
                                    aria-label="move up"
                                    color="primary"
                                    onClick={() => {
                                      const newContent = [...props.value];
                                      newContent.splice(
                                        index - 1,
                                        0,
                                        ...newContent.splice(index, 1)
                                      );
                                      props.onChange(newContent);
                                    }}
                                  >
                                    <ArrowUpward />
                                  </IconButton>
                                ) : null}
                                {index !== props.value.length - 1 ? (
                                  <IconButton
                                    aria-label="move down"
                                    color="primary"
                                    onClick={() => {
                                      const newContent = [...props.value];
                                      newContent.splice(
                                        index + 1,
                                        0,
                                        ...newContent.splice(index, 1)
                                      );
                                      props.onChange(newContent);
                                    }}
                                  >
                                    <ArrowDownward />
                                  </IconButton>
                                ) : null}
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => {
                                    const newContent = [...props.value];
                                    newContent.splice(index, 1);
                                    props.onChange(newContent);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemAvatar>
                                <Avatar
                                  alt={`${value.displayName}`}
                                  src={`${value.imageUrl}?height=50`}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                secondary={
                                  <span>
                                    <span>
                                      Status:{" "}
                                      <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        {value.status}{" "}
                                      </Typography>
                                    </span>{" "}
                                    <span>
                                      Premium:{" "}
                                      <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        {value.isPremium ? "Yes" : "No"}
                                      </Typography>
                                    </span>{" "}
                                    <span>
                                      Standard:{" "}
                                      <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        {value.isStandard ? "Yes" : "No"}
                                      </Typography>
                                    </span>{" "}
                                    <span>
                                      Genres:{" "}
                                      <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        {value.genres?.join(", ") ?? ""}
                                      </Typography>
                                    </span>
                                  </span>
                                }
                                id={labelId}
                                primary={`${value.displayName}`}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                </>
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          type={"submit"}
          sx={{ mr: 2 }}
          disabled={isSubmitting}
        >
          Submit
        </Button>
        <Button
          variant="text"
          disabled={isSubmitting}
          onClick={() => {
            history.push("/playlists", { replace: true });
          }}
        >
          Cancel
        </Button>
      </Grid>
    </form>
  );
};
