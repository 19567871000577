import { Route, Switch, useRouteMatch } from "react-router-dom";
import * as React from "react";
import { ContentBundlesListPage } from "./content-bundles-list-page";
import { CreateContentBundle } from "./create-content-bundle";
import { EditContentBundle } from "./edit-content-bundle";

export const ContentBundlesIndex = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} exact>
        <ContentBundlesListPage />
      </Route>
      <Route path={`${match.path}/create`} exact>
        <CreateContentBundle />
      </Route>
      <Route path={`${match.path}/:id`}>
        <EditContentBundle />
      </Route>
    </Switch>
  );
};
