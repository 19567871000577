import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useToken } from "../token-provider";
import { useRefundTransaction, useTransactions } from "../api/user-api";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { TransactionRefundDialog } from "./transaction-refund-dialog";
import Button from "@mui/material/Button";
import { SnackbarMessage } from "../components";
import { format } from "date-fns";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  table: {
    minWidth: 650,
  },
}));

const UserTransactionList = ({ profileId }: { profileId: number }) => {
  const classes = useStyles();
  const token = useToken();
  const page = 0;
  const rowsPerPage = 10;
  const { data, isError } = useTransactions(
    token,
    profileId,
    page,
    rowsPerPage
  );
  const [confirmRefundId, setConfirmRefundId] = useState<undefined | string>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [mutate] = useRefundTransaction();
  const date = new Date();
  date.setDate(date.getDate() - 60);
  const sixtyDaysAgo = date.toISOString().split("T")[0];
  if (!data || isError) return <></>;

  return (
    <>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                ID<br></br>Created At
              </TableCell>
              <TableCell>
                Amount<br></br>Status
              </TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Billing Period</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell>Refund</TableCell>
              <TableCell>Dispute</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.transactions &&
              data.transactions.map((row) => (
                <TableRow key={row.id}>
                  <TableCell sx={{ minWidth: 210 }} component="th" scope="row">
                    <div>{row.id}</div>
                    <div>
                      {format(new Date(row.createdAt), "MMM dd, yyyy p")}
                    </div>
                  </TableCell>
                  <TableCell>
                    {row.amount}
                    <br></br>
                    {row.refundId && row.refundId ? (
                      <div>REFUNDED</div>
                    ) : (
                      row.status
                    )}
                    {row.gatewayRejectionReason &&
                    row.gatewayRejectionReason ? (
                      <div>{row.gatewayRejectionReason}</div>
                    ) : (
                      <div>{row.processorResponse}</div>
                    )}
                  </TableCell>
                  <TableCell>{row.subscriptionId}</TableCell>
                  <TableCell>{row.billingPeriod}</TableCell>
                  <TableCell sx={{ minWidth: 125, maxWidth: 150 }}>
                    <div>{row.paymentMethodType}</div>
                    <div>
                      {row.last4} {row.cardType}
                    </div>
                    <Tooltip title={row.paypalEmail}>
                      <div
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        {row.paypalEmail}
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {row.refundId && row.refundId ? (
                      <div>
                        Refunded By:<br></br>
                        {row.refundId}
                      </div>
                    ) : (
                      ""
                    )}
                    {row.refundedTransactionId && row.refundedTransactionId ? (
                      <div>
                        Refund For: <br></br>
                        {row.refundedTransactionId}
                      </div>
                    ) : (
                      ""
                    )}
                    {!row.refundId &&
                    !row.refundedTransactionId &&
                    row.createdAt &&
                    (row.status === "settled" ||
                      row.status === "submitted_for_settlement") &&
                    Date.parse(row.createdAt) >= Date.parse(sixtyDaysAgo) ? (
                      <>
                        <Button
                          color="secondary"
                          variant="outlined"
                          size={"small"}
                          onClick={() => {
                            setConfirmRefundId(row.id);
                          }}
                        >
                          Refund
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell>
                    {row.disputeCaseNumber && row.disputeCaseNumber ? (
                      <div>
                        Case #:{row.disputeCaseNumber} <br></br>
                      </div>
                    ) : (
                      ""
                    )}
                    {row.disputeStatus && row.disputeStatus ? (
                      <div>
                        Status:{row.disputeStatus} <br></br>
                      </div>
                    ) : (
                      ""
                    )}
                    {row.disputeAmount && row.disputeAmount ? (
                      <div>
                        Amount:{row.disputeAmount} <br></br>
                      </div>
                    ) : (
                      ""
                    )}
                    {row.disputeReceivedOn && row.disputeReceivedOn ? (
                      <div>
                        Received:{row.disputeReceivedOn} <br></br>
                      </div>
                    ) : (
                      ""
                    )}
                    {row.disputeReason && row.disputeReason ? (
                      <div>
                        Reason:{row.disputeReason} <br></br>
                      </div>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TransactionRefundDialog
        onClose={() => setConfirmRefundId(undefined)}
        open={confirmRefundId !== undefined}
        onConfirm={() => {
          mutate(
            { token, profileId, transactionId: confirmRefundId!! },
            {
              onError: (error: any) => {
                setErrorMessage(error.message);
              },
              onSettled: () => {
                setConfirmRefundId(undefined);
              },
            }
          );
        }}
      />
      {errorMessage ? (
        <SnackbarMessage severity={"error"} onClose={() => setErrorMessage("")}>
          {" "}
          {errorMessage}
        </SnackbarMessage>
      ) : null}
    </>
  );
};

export default UserTransactionList;
