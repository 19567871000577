import * as React from "react";
import { useQuery } from "react-query";
import * as profileApi from "./api/profile-api";
import { Loading } from "./components";
import Typography from "@mui/material/Typography";
import { useToken } from "./token-provider";

const AuthContext = React.createContext(undefined);

export const AuthProvider = (props) => {
  const token = useToken();
  const { data, isLoading, isError } = useQuery(
    "profile",
    async () => {
      return await profileApi.findMyProfile(token);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const value = React.useMemo(() => {
    return {
      ...data,
    };
  }, [data]);
  if (isLoading) {
    return <Loading />;
  } else if (isError) {
    return (
      <Typography variant="h2" gutterBottom>
        Something went wrong. Contact dev team for assistance.
      </Typography>
    );
  } else {
    return <AuthContext.Provider value={value} {...props} />;
  }
};

export function useProfile() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useProfile must be used within a AuthProvider`);
  }
  return context;
}
