import { baseUrl } from "../utils/http";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";

type FindCommunityHubContentProps = {
  token: string;
};

export const findCommunityHubContent = async ({
  token,
}: FindCommunityHubContentProps) => {
  const response = await fetch(`${baseUrl}/community_hub/content`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  if (!(response.status === 200)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

export const useHubContent = () => {
  const auth0 = useAuth0();
  return useQuery(["hub-content", "all"], async () => {
    const token = (await auth0.getAccessTokenSilently()) as string;
    return findCommunityHubContent({ token });
  });
};

type CreateHubContentProps = {
  token: string;
  title: string;
  content: string;
  buttonLabel: string;
  buttonTarget: string;
  activeFrom: Date;
  activeTo: Date;
  lbEnabled: boolean;
  goEnabled: boolean;
  vrEnabled: boolean;
  basicEnabled: boolean;
  premiumEnabled: boolean;
  backgroundImage: FileList;
};
export const createHubContent = async ({
  token,
  title,
  content,
  buttonLabel,
  buttonTarget,
  activeFrom,
  activeTo,
  lbEnabled,
  goEnabled,
  vrEnabled,
  basicEnabled,
  premiumEnabled,
  backgroundImage,
}: CreateHubContentProps) => {
  const formData = new FormData();
  if (title) {
    formData.append("title", title);
  }
  if (content) {
    formData.append("content", content);
  }
  if (buttonLabel && buttonLabel !== "") {
    formData.append("buttonLabel", buttonLabel);
  }
  if (buttonTarget && buttonTarget !== "") {
    formData.append("buttonTarget", buttonTarget);
  }
  if (activeFrom && activeFrom !== null) {
    formData.append("activeFrom", activeFrom.toISOString());
  }
  if (activeTo && activeTo !== null) {
    formData.append("activeTo", activeTo.toISOString());
  }
  if (lbEnabled) {
    formData.append("lbEnabled", "true");
  } else {
    formData.append("lbEnabled", "false");
  }
  if (goEnabled) {
    formData.append("goEnabled", "true");
  } else {
    formData.append("goEnabled", "false");
  }
  if (vrEnabled) {
    formData.append("vrEnabled", "true");
  } else {
    formData.append("vrEnabled", "false");
  }
  if (basicEnabled) {
    formData.append("basicEnabled", "true");
  } else {
    formData.append("basicEnabled", "false");
  }
  if (premiumEnabled) {
    formData.append("premiumEnabled", "true");
  } else {
    formData.append("premiumEnabled", "false");
  }
  if (backgroundImage.length > 0) {
    formData.append("backgroundImage", backgroundImage[0]);
  }

  const response = await fetch(`${baseUrl}/community_hub/content`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

type UpdateHubContentProps = CreateHubContentProps & {
  id: number;
};
export const updateHubContent = async ({
  token,
  title,
  content,
  buttonLabel,
  buttonTarget,
  activeFrom,
  activeTo,
  lbEnabled,
  goEnabled,
  vrEnabled,
  basicEnabled,
  premiumEnabled,
  backgroundImage,
  id,
}: UpdateHubContentProps) => {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("content", content);
  formData.append("buttonLabel", buttonLabel);
  formData.append("buttonTarget", buttonTarget);
  if (activeFrom) {
    formData.append("activeFrom", activeFrom.toISOString());
  }
  if (activeTo) {
    formData.append("activeTo", activeTo.toISOString());
  }
  if (lbEnabled) {
    formData.append("lbEnabled", "true");
  } else {
    formData.append("lbEnabled", "false");
  }
  if (goEnabled) {
    formData.append("goEnabled", "true");
  } else {
    formData.append("goEnabled", "false");
  }
  if (vrEnabled) {
    formData.append("vrEnabled", "true");
  } else {
    formData.append("vrEnabled", "false");
  }
  if (basicEnabled) {
    formData.append("basicEnabled", "true");
  } else {
    formData.append("basicEnabled", "false");
  }
  if (premiumEnabled) {
    formData.append("premiumEnabled", "true");
  } else {
    formData.append("premiumEnabled", "false");
  }
  if (backgroundImage.length > 0) {
    formData.append("backgroundImage", backgroundImage[0]);
  }
  const response = await fetch(`${baseUrl}/community_hub/content/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};
