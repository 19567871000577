import { Container, Typography } from "@mui/material";
import * as React from "react";
import * as communityHubApi from "../../api/community-hub-api";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { HubContentForm } from "./hub-content-form";

export const CreateHubContent = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Create New Hub Content
      </Typography>
      <HubContentForm
        imagesRequired={true}
        onSubmit={async (values) => {
          const token = await getAccessTokenSilently();
          await communityHubApi.createHubContent({
            token,
            ...values,
          });
          history.push("/community_hub/content");
        }}
        defaultValues={{
          title: "",
          content: "",
          buttonLabel: null,
          buttonTarget: null,
          activeFrom: null,
          activeTo: null,
          lbEnabled: true,
          goEnabled: true,
          vrEnabled: true,
          basicEnabled: true,
          premiumEnabled: true,
          backgroundImage: "",
        }}
      />
    </Container>
  );
};
