import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Typography } from "@mui/material";
import { useToken } from "../token-provider";
import { useQuery } from "react-query";
import * as userApi from "../api/user-api";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

const UserPreferences = ({ profileId }) => {
  return <UserPreferencesList profileId={profileId} />;
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 650,
    },
  };
});

const UserPreferencesList = ({ profileId }) => {
  const classes = useStyles();
  const token = useToken();

  const { data = { count: 0, items: [] } } = useQuery(
    ["userpreferences", profileId],
    async () => {
      return await userApi.findUserPreferences({
        token,
        profileId,
      });
    }
  );
  return (
    <Container className={classes.container}>
      {data.items.map(
        (row) =>
          row.preferences &&
          Object.keys(row.preferences).length > 0 && (
            <div key={row.deviceType}>
              <Typography className={classes.subtitle} variant="h5">
                Device Type `{row.deviceType}` Preferences
              </Typography>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Key</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(row.preferences).map(([key, val]) => (
                    <TableRow key={key}>
                      <TableCell>
                        <pre>{key}</pre>
                      </TableCell>
                      <TableCell>
                        <pre>{JSON.stringify(val)}</pre>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )
      )}
    </Container>
  );
};

export default UserPreferences;
