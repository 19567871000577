import { useAuth0, withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import { Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import React, { useState } from "react";
import { useQuery, useQueryCache } from "react-query";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import {
  TrainerTrack,
  updateTrainerTrackById,
  createTrainerTrack,
  findAllTrainerTracks,
  CreateTrainerTrackError,
  TrainerTrackResponse,
} from "../api/trainer-track-api";
import {
  ColorButton,
  Loading,
  SearchTable,
  SnackbarMessage,
  statusChip,
} from "../components";
import TrainerWorkoutForm from "../components/trainer-workout-form";
import { useFetch } from "../utils";
import { secondsToMMSS } from "../utils/formatters";
import TrainerUmgAssociations from "./trainer-umg-associations";
import TrainerPlaysPerDayOnApp from "../components/trainer-plays-per-day-on-app";
import { Brand } from "../app";
import { getErrorMessage } from "../utils/error-handling";
import { RouteComponentProps } from "react-router";
import { ErrorOption, FieldName } from "react-hook-form";
import { contentVersion, v2024April } from "../components/content-versions";

const tableHeadings = [
  {
    id: "imageUrl",
    numeric: false,
    disablePadding: true,
    label: "",
    format: (value: string) => {
      return (
        <img
          key={value}
          src={`${value}?height=36`}
          height="36px"
          width="72px"
          alt=""
        />
      );
    },
  },
  {
    id: "displayName",
    numeric: false,
    disablePadding: true,
    label: "Track Name",
    widescreenOnly: true,
  },
  { id: "trainerName", numeric: false, disablePadding: true, label: "Trainer" },
  { id: "brandName", numeric: false, disablePadding: true, label: "Brand" },
  {
    id: "contentDisplayName",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "lengthInSeconds",
    numeric: true,
    disablePadding: false,
    label: "Length",
    format: (value: string) => {
      return secondsToMMSS(value);
    },
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    format: (value: string) => {
      return statusChip(value);
    },
  },
];

const WorkoutsList = withAuth0<RouteComponentProps & WithAuth0Props>(
  (props) => {
    const { data, error } =
      useFetch<TrainerTrackResponse>(`/content/video/all`);

    if (!data && !error) {
      return <Loading />;
    }

    return (
      <Container className="mb-5">
        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h1">
              {Brand === "litesport" ? <>Video Workouts</> : <>Content</>}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
            <ColorButton
              color="green"
              component={Link}
              to={"/workouts/create"}
              variant="contained"
            >
              Add New{" "}
            </ColorButton>
          </Grid>
        </Grid>
        {data && (
          <SearchTable
            rows={data.tracks}
            headings={tableHeadings}
            history={props.history}
            umgAssociations={Brand === "litesport" ? true : false}
          />
        )}
        <TrainerPlaysPerDayOnApp />
        {error && <SnackbarMessage severity="error">{error}</SnackbarMessage>}
      </Container>
    );
  }
);

const WorkoutsEdit = withAuth0<
  RouteComponentProps<{ id: string }> & WithAuth0Props
>((props) => {
  const [message, setMessage] = useState<{
    result: string;
    message: string;
  } | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const queryCache = useQueryCache();
  const id = parseInt(props.match.params.id, 10);
  const onSubmit = async (formData: TrainerTrack) => {
    if (!formData.chapters) {
      formData.chapters = [];
    }
    formData.chapters.forEach((item) => {
      const parsed = parseInt(item.playTimeSeconds, 10);
      if (isNaN(parsed)) {
        setMessage({
          result: "error",
          message: "Playtime is not a number",
        });
        return;
      }
    });
    if (
      formData.cdnProvider === "mux" &&
      formData.cdnAssetId &&
      formData.cdnAssetId !== ""
    ) {
      formData.url = formData.cdnAssetId;
    } else if (
      formData.cdnProvider === "brightcove" &&
      formData.cdnAssetId &&
      formData.cdnAssetId !== ""
    ) {
      formData.url = "brightcove:" + formData.cdnAssetId;
    }
    const token = await getAccessTokenSilently({
      audience: `https://api.liteboxer.com`,
    });
    try {
      const { coverArt, track, trainer, ...rest } = formData;
      await updateTrainerTrackById({
        token,
        trainerTrackId: id,
        hideItemId: true,
        hideFileFields: true,
        contentType: formData.contentType.id,
        data: {
          ...rest,
          length: formData.length,
          trainerId: parseInt(trainer!.id),
          isPremium: formData.isPremium,
          isStandard: formData.isStandard,
          includedInOtbApp: formData.includedInOtbApp,
          coverArt: coverArt[0],
          track: track[0],
        },
      });
      setMessage({
        result: "success",
        message: "Saved!",
      });
      queryCache.invalidateQueries("trainertracks");
      window.location.reload();
    } catch (e) {
      setMessage({
        result: "error",
        message: getErrorMessage(e),
      });
    }
  };

  const {
    data = { tracks: [] },
    isLoading,
    error,
  } = useQuery(
    "trainertracks",
    async () => {
      const token = await getAccessTokenSilently({
        audience: `https://api.liteboxer.com`,
      });
      return findAllTrainerTracks({ token });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <Loading />;
  }

  const result = data.tracks.find((val) => val.id === id);
  if (!result) {
    return (
      <Typography variant="h4">Something went wrong fetching {id}</Typography>
    );
  }
  const displayName = result.displayName;
  const contentDisplayName = result.contentDisplayName;
  const cdnAssetId = () => {
    if (
      result.url !== null &&
      result.url !== undefined &&
      result.url !== "undefined" &&
      result.url !== ""
    ) {
      const cdnURL = new URL(result.url);
      if (cdnURL.protocol === "brightcove:") {
        return cdnURL.pathname;
      } else if (cdnURL.protocol === "http:" || cdnURL.protocol === "https:") {
        return result.url;
      } else return "";
    } else return "";
  };

  return (
    <Container className="mb-5" style={{ marginBottom: 50 }}>
      <Typography variant="h1">
        {contentDisplayName}: {displayName}
      </Typography>
      {result.imageUrl && (
        <img
          src={`${result.imageUrl}?height=512`}
          style={{
            maxWidth: "70vw",
            maxHeight: "35vw",
            width: "1024px",
            height: "512px",
            marginBottom: "30px",
          }}
          alt=""
        />
      )}
      <TrainerWorkoutForm
        onSubmit={onSubmit}
        hideFileFields={true}
        id={id}
        defaultValues={{
          contentVersion: contentVersion(
            result.filmedInVr,
            result.vrVideoPlayerSpec,
            result.minAppVersion
          ),
          isPremium: result.isPremium,
          isStandard: result.isStandard,
          includedInOtbApp: result.includedInOtbApp,
          displayName: result.displayName,
          description: result.description,
          additionalData: result.additionalData
            ? JSON.stringify(result.additionalData)
            : undefined,
          vrVideoPlayerSpec: result.vrVideoPlayerSpec
            ? JSON.stringify(result.vrVideoPlayerSpec)
            : undefined,
          length: `${result.lengthInSeconds}s`,
          status: result.status,
          publishedDate:
            result.publishedDate !== null
              ? new Date(result.publishedDate).toISOString().substr(0, 10)
              : "",
          retiredDate:
            result.retiredDate !== null
              ? new Date(result.retiredDate).toISOString().substr(0, 10)
              : "",
          releaseAt:
            result.releaseAt !== null ? new Date(result.releaseAt) : null,
          retireAt: result.retireAt !== null ? new Date(result.retireAt) : null,
          contentFeaturedOn:
            result.contentFeaturedOn !== null
              ? new Date(result.contentFeaturedOn)
              : null,
          url: result.url,
          trainer: { id: result.trainerId, name: result.trainerName },
          brand: result.brand ? result.brand : "",
          genres: result.genres ? result.genres : [],
          requiredEquipment: result.requiredEquipment
            ? result.requiredEquipment
            : [],
          equipment: result.equipment,
          music: result.music,
          difficulty: result.difficulty,
          vrEnabled: result.vrEnabled,
          lbEnabled: result.lbEnabled,
          filmedInVr: result.filmedInVr,
          inBundle: result.inBundle,
          hwOverrideUrl: result.hwOverrideUrl,
          hwSouthpawOverrideUrl: result.hwSouthpawOverrideUrl,
          cdnProvider:
            result.url.substr(0, 10) === "brightcove" ? "brightcove" : "mux",
          cdnAssetId: cdnAssetId(),
          contentType: {
            id: result.contentType,
            name: result.contentDisplayName,
          },
          maxHealth: result.maxHealth,
          minAppVersion: result.minAppVersion,
        }}
      />

      {error && <SnackbarMessage severity="error">{error}</SnackbarMessage>}
      {message && (
        <SnackbarMessage
          severity={message.result}
          onClose={() => setMessage(null)}
        >
          {message.message}
        </SnackbarMessage>
      )}
    </Container>
  );
});

const WorkoutCreate = () => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  return (
    <Container className={"mb-5"}>
      <Typography variant="h1">Create Trainer Workout</Typography>
      <TrainerWorkoutForm
        hideFileFields={false}
        defaultValues={{
          contentVersion: Brand === "litesport" && v2024April ? v2024April : "",
          isPremium: false,
          isStandard: false,
          includedInOtbApp: false,
          itemId: "",
          artist: "",
          displayName: "",
          description: "",
          length: "",
          status: "DRAFT",
          difficulty: "",
          publishedDate: "",
          retiredDate: "",
          retireAt: null,
          releaseAt: null,
          contentFeaturedOn: null,
          url: "",
          trainer: "",
          brand: "",
          equipment: "",
          requiredEquipment: [],
          genres: [],
          music: "",
          vrEnabled: true,
          lbEnabled: false,
          filmedInVr: true,
          hwOverrideUrl: "",
          hwSouthpawOverrideUrl: "",
          contentType: "",
          cdnProvider: Brand === "xponential" ? "brightcove" : "mux",
          cdnAssetId: "",
          maxHealth: 0,
          vrVideoPlayerSpec:
            Brand === "litesport" && v2024April.vrVideoPlayerSpec
              ? JSON.stringify(v2024April.vrVideoPlayerSpec)
              : "",
          minAppVersion:
            Brand === "litesport" && v2024April.minAppVersion
              ? v2024April.minAppVersion
              : "",
        }}
        onSubmit={async (
          data: TrainerTrack,
          setError: (name: FieldName<TrainerTrack>, error: ErrorOption) => void
        ) => {
          const token = await getAccessTokenSilently();
          const { coverArt, track, trainer, ...rest } = data;
          const cdnUrl = () => {
            if (data.cdnProvider === "brightcove" && data.cdnAssetId !== "") {
              return `brightcove:${data.cdnAssetId}`;
            } else if (data.cdnProvider === "mux" && data.cdnAssetId !== "") {
              return data.cdnAssetId;
            } else return "";
          };

          try {
            await createTrainerTrack({
              ...rest,
              length: data.length,
              trainerId: parseInt(data.trainer!.id),
              coverArt: coverArt[0],
              track: track[0],
              token,
              url: cdnUrl(),
            });
            history.push("/workouts");
          } catch (e: any) {
            if (e instanceof CreateTrainerTrackError) {
              Object.keys(e.errors).forEach((key) => {
                setError(key, {
                  type: "manual",
                  message: e.errors[key],
                });
              });
            } else {
            }
          }
        }}
      />
    </Container>
  );
};

export function Workouts() {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/create`} component={WorkoutCreate} />
      <Route path={`${match.path}/:id/umg`}>
        <TrainerUmgAssociations />
      </Route>
      <Route path={`${match.path}/:id`} component={WorkoutsEdit} />
      <Route path={match.path} component={WorkoutsList} />
    </Switch>
  );
}

export default Workouts;
