import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";

interface RenderJSONProps {
  input: object | string;
  setStateAction?: React.Dispatch<React.SetStateAction<boolean>>; // If provided, set to true when invalid JSON is detected
}

const RenderJSON: React.FC<RenderJSONProps> = ({ input, setStateAction }) => {
  const [jsonObject, setJsonObject] = useState<object>({});
  const [isValidJson, setIsValidJson] = useState(true);

  useEffect(() => {
    if (!input) {
      setJsonObject({});
      setIsValidJson(true);
      return;
    } else if (typeof input === "string" && input !== "") {
      try {
        setJsonObject(JSON.parse(input));
        setIsValidJson(true);
      } catch (e) {
        setIsValidJson(false);
      }
    } else if (typeof input === "object")
      try {
        setJsonObject(input);
        setIsValidJson(true);
      } catch (e) {
        setIsValidJson(false);
      }
  }, [input]);

  useEffect(() => {
    if (setStateAction) {
      setStateAction(!isValidJson);
    }
  }, [isValidJson, setStateAction]);

  if (!jsonObject) {
    return null;
  }

  if (!isValidJson) {
    return null;
  }

  return React.createElement(ReactJson, {
    src: jsonObject,
    name: false,
    collapsed: 1,
  });
};

export default RenderJSON;
