import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { usePrograms } from "../../api/programs-api";
import { useHistory } from "react-router-dom";

export const ProgramsList = () => {
  const history = useHistory();
  const columns = [
    {
      field: "imageUrl",
      headerName: "Image",
      renderCell: (params: any) => {
        return (
          <img
            key={params.value}
            src={`${params.value}?height=36`}
            height="36px"
            width="36px"
            alt=""
          />
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      renderCell: (params: any) => {
        return <div>{params.value}</div>;
      },
      flex: 0,
    },
    {
      field: "name",
      headerName: "Name",
      renderCell: (params: any) => {
        return (
          <div
            onClick={() => {
              history.push(`/programs/${params.id}`);
            }}
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            {params.value}
          </div>
        );
      },
      flex: 2,
    },
    {
      field: "groupName",
      headerName: "Group",
      renderCell: (params: any) => {
        return (
          <div
            onClick={() => {
              history.push(`/program-groups/${params.row.groupId}`);
            }}
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            {params.value}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: "isActive",
      headerName: "Active",
      renderCell: (params: any) => {
        return <div>{params.value?.toString() === "true" ? "YES" : "NO"}</div>;
      },
      flex: 0,
    },
    {
      field: "isVisibleToStandard",
      headerName: "Standard",
      renderCell: (params: any) => {
        return <div>{params.value?.toString() === "true" ? "YES" : "NO"}</div>;
      },
      flex: 0,
    },
    {
      field: "isVisibleToPremium",
      headerName: "Premium",
      renderCell: (params: any) => {
        return <div>{params.value?.toString() === "true" ? "YES" : "NO"}</div>;
      },
      flex: 0,
    },
  ];
  const { data } = usePrograms();
  return (
    <div style={{ height: 600, width: "100%" }}>
      {data ? (
        <DataGrid rows={data} columns={columns} disableSelectionOnClick />
      ) : (
        <div>loading...</div>
      )}
    </div>
  );
};
