import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useContentBundles } from "../../api/content-bundles-api";
import { useHistory } from "react-router-dom";

export const ContentBundlesList = () => {
  const history = useHistory();
  const columns = [
    {
      field: "imageUrl",
      headerName: "Image",
      renderCell: (params) => {
        return (
          <img
            key={params.value}
            src={`${params.value}?height=64`}
            height="64px"
            width="64px"
            alt=""
          />
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              history.push(`/content_bundles/${params.id}`);
            }}
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            {params.row.title}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: "sku",
      headerName: "SKU",
      renderCell: (params) => {
        return <div>{params.row.bundleSku}</div>;
      },
      flex: 1,
    },
    {
      field: "contentTypes",
      headerName: "Content Types",
      renderCell: (params) => {
        return formatContentTypes(params.row.contentTypes);
      },
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        return <div>{params.row.status}</div>;
      },
      flex: 1,
    },
  ];
  const { data } = useContentBundles();
  return (
    <div style={{ height: 600, width: "100%" }}>
      {data ? (
        <DataGrid rows={data} columns={columns} disableSelectionOnClick />
      ) : (
        <div>loading...</div>
      )}
    </div>
  );
};

function formatContentTypes(contentTypes) {
  var displayString;
  if (contentTypes != null) {
    const formattedTypes = contentTypes.map(
      (type) => `${type.type} (${type.count})`
    );
    displayString = formattedTypes.join(", ");
  } else {
    displayString = "no content in bundle";
  }
  return <div>{displayString}</div>;
}
