import React from "react";
import { Checkbox, Grid, TextField, FormControlLabel } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { UploadButton } from "../../components/upload-button";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { DateTimePicker } from "@mui/lab";

const getErrorMessage = (key, errors) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};

const CheckboxField = ({ control, name, required, label }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={(props) => (
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              onChange={(e) => props.onChange(e.target.checked)}
              checked={props.value}
            />
          }
        />
      )}
    />
  );
};

export const HubContentForm = ({ defaultValues, onSubmit, imagesRequired }) => {
  const {
    control,
    handleSubmit,
    register,
    errors,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });
  const history = useHistory();
  return (
    <form
      noValidate
      onSubmit={handleSubmit(async (values) => {
        return onSubmit(values);
      })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name={"title"}
            rules={{ required: true }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  required
                  fullWidth
                  name={"title"}
                  label={"Title"}
                  helperText={getErrorMessage("title", errors)}
                  error={!!errors.title}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"content"}
            rules={{ required: true }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  fullWidth
                  name={"content"}
                  multiline
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                  label={"Content"}
                  rows={10}
                />
              );
            }}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={4}>
            <Controller
              name={"buttonLabel"}
              control={control}
              render={(props) => {
                return (
                  <TextField
                    fullWidth
                    name={"buttonLabel"}
                    onChange={(value) => {
                      props.onChange(value.target.value);
                    }}
                    value={props.value}
                    label={"Button Label"}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={4}>
            <Controller
              name={"buttonTarget"}
              control={control}
              render={(props) => {
                return (
                  <TextField
                    fullWidth
                    name={"buttonTarget"}
                    multiline
                    onChange={(value) => {
                      props.onChange(value.target.value);
                    }}
                    value={props.value}
                    label={"Button Target"}
                    rows={1}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={4}>
            <Controller
              name={"activeFrom"}
              control={control}
              render={(props) => {
                return (
                  <DateTimePicker
                    label="Active From"
                    value={props.value}
                    onChange={(newValue) => {
                      props.onChange(newValue);
                    }}
                    id="from-date-picker"
                    renderInput={(inputProps) => (
                      <TextField fullWidth {...inputProps} />
                    )}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={4}>
            <Controller
              name={"activeTo"}
              control={control}
              render={(props) => {
                return (
                  <DateTimePicker
                    label="Active To"
                    value={props.value}
                    onChange={(newValue) => {
                      props.onChange(newValue);
                    }}
                    id="to-date-picker"
                    renderInput={(inputProps) => (
                      <TextField fullWidth {...inputProps} />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        <Grid item container spacing={2}></Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <CheckboxField
              label={"LB Enabled"}
              name={"lbEnabled"}
              control={control}
              required={true}
            />
          </Grid>
          <Grid item xs={3}>
            <CheckboxField
              label={"GO Enabled"}
              name={"goEnabled"}
              control={control}
              required={true}
            />
          </Grid>
          <Grid item xs={3}>
            <CheckboxField
              label={"VR Enabled"}
              name={"vrEnabled"}
              control={control}
              required={true}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <CheckboxField
              label={"Basic Members Enabled"}
              name={"basicEnabled"}
              control={control}
              required={true}
            />
          </Grid>
          <Grid item xs={3}>
            <CheckboxField
              label={"Premium Members Enabled"}
              name={"premiumEnabled"}
              control={control}
              required={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <UploadButton
            name="backgroundImage"
            label={"Background Image"}
            value={getValues("backgroundImage")}
            error={getErrorMessage("backgroundImage", errors)}
            ref={register({ required: imagesRequired })}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          type={"submit"}
          sx={{ mr: 2 }}
          disabled={isSubmitting}
        >
          Submit
        </Button>
        <Button
          variant="text"
          disabled={isSubmitting}
          onClick={() => {
            history.push("/community_hub/content");
          }}
        >
          Cancel
        </Button>
      </Grid>
    </form>
  );
};
