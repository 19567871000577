import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";

export const TransactionRefundDialog = ({ onClose, open, onConfirm }) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="refund-confirm-dialog"
      open={open}
    >
      <DialogTitle id="refund-confirm-dialog-title">
        Are you sure you want to refund this transaction?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="refund-confirm-dialog-description">
          NOTE: This refund will not change the membership status or prevent
          future charges. Please be sure to cancel the membership separately if
          appropriate.{" "}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onConfirm} color="secondary">
          Yes, refund the transaction
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          color="secondary"
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
