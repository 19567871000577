import { baseUrl } from "../utils/http";

const createStretchStrengthenTrack = async ({
  displayName,
  description,
  status,
  length,
  itemId,
  releaseAt,
  retireAt,
  token,
  coverArt,
  track,
  trainer,
  url,
  isPremium,
  isStandard,
  genres,
  difficulty,
  equipment,
  music,
  vrEnabled,
  lbEnabled,
}) => {
  const formData = new FormData();
  formData.append("displayName", displayName);
  formData.append("description", description);
  formData.append("status", status);
  formData.append("length", length);
  formData.append("itemId", itemId);
  formData.append("url", url);
  if (releaseAt) {
    formData.append("releaseAt", releaseAt.toISOString());
  }
  if (retireAt) {
    formData.append("retireAt", retireAt.toISOString());
  }
  formData.append("coverArt", coverArt);
  formData.append("track", track);
  formData.append("trainer", trainer ?? "");
  formData.append("isPremium", isPremium);
  formData.append("isStandard", isStandard);
  formData.append("genres", JSON.stringify(genres));
  formData.append("difficulty", difficulty);
  formData.append("equipment", equipment);
  formData.append("music", music);
  formData.append("vrEnabled", vrEnabled);
  formData.append("lbEnabled", lbEnabled);
  const response = await fetch(`${baseUrl}/content/build_and_restore`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    const body = await response.json();
    const error = new Error();
    error.response = {
      code: response.status,
      data: body,
    };
    throw error;
  }
  return await response.json();
};

const findPlaysPerDay = async ({ token }, includeLB, includeGO, includeVR) => {
  const response = await fetch(
    `${baseUrl}/content/build_and_restore/plays_per_day?includeLB=${includeLB}&includeGO=${includeGO}&includeVR=${includeVR}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return await response.json();
};

const findAllBuildAndRestoreTracks = async ({ token }) => {
  const response = await fetch(`${baseUrl}/content/build_and_restore`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

const updateBuildAndRestoreTrackById = async ({
  token,
  trainerTrackId,
  data,
}) => {
  const formData = new FormData();
  formData.append("displayName", data.displayName);
  formData.append("description", data.description);
  formData.append("status", data.status);
  formData.append("length", data.length);
  formData.append("url", data.url);
  if (data.releaseAt) {
    formData.append("releaseAt", data.releaseAt.toISOString());
  }
  if (data.retireAt) {
    formData.append("retireAt", data.retireAt.toISOString());
  }
  formData.append("punchTrack", data.track);
  formData.append("trainer", data.trainerId ?? "");
  formData.append("isPremium", data.isPremium);
  formData.append("isStandard", data.isStandard);
  formData.append("genres", JSON.stringify(data.genres));
  formData.append("music", data.music);
  formData.append("equipment", data.equipment);
  formData.append("difficulty", data.difficulty);
  formData.append("vrEnabled", data.vrEnabled);
  formData.append("lbEnabled", data.lbEnabled);

  const response = await fetch(
    `${baseUrl}/content/build_and_restore/${trainerTrackId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  );
  const responseData = await response.json();
  if (!response.ok) {
    throw new Error(responseData.status);
  }
  return responseData;
};
export {
  createStretchStrengthenTrack,
  findPlaysPerDay,
  findAllBuildAndRestoreTracks,
  updateBuildAndRestoreTrackById,
};
