import * as React from "react";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { format } from "date-fns";
import makeStyles from "@mui/styles/makeStyles";
import { useCancellationSummary } from "../api/membership-api";

const useStylesTable = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    marginBottom: theme.spacing(2),
  },
}));
export const CancelledMemberships = () => {
  const { data = [] } = useCancellationSummary();
  const classes = useStylesTable();
  return (
    <Container>
      <div>
        <div>
          <Typography variant={"h3"}>Cancelled Memberships</Typography>
        </div>
      </div>
      <div style={{ width: "100%", minHeight: 400 }}>
        <TableContainer>
          <Table className={classes.table} aria-label="cancelled-table">
            <TableHead>
              <TableRow>
                <TableCell>Reason</TableCell>
                <TableCell>Additional</TableCell>
                <TableCell>Cancelled On</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Braintree Subscription Id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((cancellation) => {
                return (
                  <TableRow hover id={cancellation.id}>
                    <TableCell component="th" scope="row">
                      {cancellation.reason}
                    </TableCell>
                    <TableCell>{cancellation.additionalReason}</TableCell>
                    <TableCell>
                      {format(
                        new Date(cancellation.cancelledOn),
                        "MMM dd, yyyy p"
                      )}
                    </TableCell>
                    <TableCell>{cancellation.email}</TableCell>
                    <TableCell>
                      {cancellation.braintreeSubscriptionId}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
};
