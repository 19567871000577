import TrainerWorkoutUmgForm from "../components/trainer-workout-umg-form";
import * as trainerTrackApi from "../api/trainer-track-api";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { SnackbarMessage } from "../components";
import { useQuery, useQueryCache } from "react-query";

const TrainerUmgAssociations = () => {
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [message, setMessage] = useState(null);
  const queryCache = useQueryCache();
  const { data = [], isLoading } = useQuery(
    ["trainer-umg-associations", id],
    async () => {
      const token = await getAccessTokenSilently();
      return trainerTrackApi.findAllTrainerTrackUmgAssociations(token, id);
    }
  );
  const { data: allTracks = { tracks: [] } } = useQuery(
    "trainertracks",
    async () => {
      const token = await getAccessTokenSilently({
        audience: `https://api.liteboxer.com`,
      });
      return trainerTrackApi.findAllTrainerTracks({ token });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const track = allTracks.tracks.find((val) => val.id.toString() === id);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <TrainerWorkoutUmgForm
        displayName={track?.displayName}
        onSubmit={async (data, setError) => {
          const token = await getAccessTokenSilently();
          try {
            await trainerTrackApi.putTrainerTrackUmgAssociations(
              token,
              {
                associations: data.umgAssociation
                  ? data.umgAssociation.map((a) => {
                      return {
                        umgSongId: a.umgSong.id,
                        start: a.start,
                        end: a.end,
                      };
                    })
                  : [],
              },
              id
            );
            setMessage({
              result: "success",
              message: "UMG associations saved!",
            });
            queryCache.invalidateQueries(["trainer-umg-associations", id]);
          } catch (e) {
            if (e.response.data && e.response.data.error) {
              Object.keys(e.response.data.error).forEach((key) => {
                setError(key, {
                  type: "manual",
                  message: e.response.data.error[key],
                });
              });
            }
          }
        }}
        defaultValues={{ umgAssociation: data }}
      />
      {message && (
        <SnackbarMessage
          severity={message.result}
          onClose={() => setMessage(null)}
        >
          {message.message}
        </SnackbarMessage>
      )}
    </>
  );
};

export default TrainerUmgAssociations;
