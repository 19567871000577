import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  FormControlLabel,
  MenuItem,
  Switch as MuiSwitch,
  TextField,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import * as trainerApi from "../api/trainer-api";
import { splitAndEncodeHardwareVideos } from "../api/trainer-track-api";
import * as contentTypeApi from "../api/content-type-api";
import CreateTrainerDialog from "./create-trainer-dialog";
import CreateContentTypeDialog from "./create-content-type-dialog";
import { ReactHookFormSelect } from "./index";
import * as genreApi from "../api/genre-api";
import * as brandApi from "../api/brand-api";
import * as equipmentApi from "../api/equipment-api";
import CreateGenreDialog from "./create-genre-dialog";
import { DateTimePicker } from "@mui/lab";
import { DatePicker } from "@mui/lab";
import { Brand } from "../app";
import {
  contentVersions,
  v2024AprilMittDrill,
} from "../components/content-versions";
import RenderJSON from "../components/render-json";
import TextFieldWithCopy from "../components/text-field-with-copy";

const getErrorMessage = (key, errors) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};

const UploadButton = React.forwardRef(
  ({ label, accept = "image/*", value, name }, ref) => {
    const classes = useStyles();
    let selectedFile = " No file selected";
    if (value && value.length > 0) {
      selectedFile = " " + value[0].name;
    }
    return (
      <React.Fragment>
        <div>
          <input
            accept={accept}
            className={classes.input}
            id={name}
            type="file"
            name={name}
            ref={ref}
          />
          <label htmlFor={name}>
            <Button variant="contained" color="primary" component="span">
              {label}
            </Button>
            <span>{selectedFile}</span>
          </label>
        </div>
      </React.Fragment>
    );
  }
);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
  })
);

const extractMuxPlaybackIdFromUrl = (url) => {
  const match = url.match(
    new RegExp("^https://stream.mux.com/(?<muxPlaybackId>[a-zA-Z0-9]+).m3u8")
  );
  if (match && match.groups) {
    return match?.groups.muxPlaybackId;
  } else {
    return undefined;
  }
};
const TrainerWorkoutForm = ({
  onSubmit,
  defaultValues,
  hideFileFields = false,
  id = undefined,
}) => {
  const [processButtonPressed, setProcessButtonPressed] = React.useState(false);
  const [invalidAdditionalData, setInvalidAdditionalData] =
    React.useState(false);
  const [invalidVrVideoPlayerSpec, setInvalidVrVideoPlayerSpec] =
    React.useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const { data: trainers = [] } = useQuery("trainers", async () => {
    const token = await getAccessTokenSilently();
    return trainerApi.findAllTrainers(token);
  });
  const { data: contentTypes = [] } = useQuery("contentTypes", async () => {
    const token = await getAccessTokenSilently();
    return contentTypeApi.findAllContentTypes(token);
  });
  const { data: genres = [] } = useQuery("genres", async () => {
    const token = await getAccessTokenSilently();
    return genreApi.findAllGenres(token);
  });
  const { data: brands = [] } = useQuery("brands", async () => {
    const token = await getAccessTokenSilently();
    return brandApi.findAllBrands(token);
  });
  const { data: equipment = [] } = useQuery("equipment", async () => {
    const token = await getAccessTokenSilently();
    return equipmentApi.findAllEquipment(token);
  });
  const {
    register,
    handleSubmit,
    control,
    errors,
    getValues,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });

  const urlWatch = useWatch({
    control,
    name: "url",
    defaultValue: defaultValues.url,
  });
  const lbEnabledWatch = useWatch({
    control,
    name: "lbEnabled",
    defaultValue: Brand === "litesport",
  });
  const filmedInVrWatch = useWatch({
    control,
    name: "filmedInVr",
    defaultValue: Brand === "litesport",
  });
  const hwOverrideUrlWatch = useWatch({
    control,
    name: "hwOverrideUrl",
    defaultValue: "",
  });
  const contentTypeWatch = useWatch({
    control,
    name: "contentType",
    defaultValue: defaultValues.contentType,
  });
  const vrVideoPlayerSpecWatch = useWatch({
    control,
    name: "vrVideoPlayerSpec",
    defaultValue: defaultValues.vrVideoPlayerSpec,
  });

  const additionalDataWatch = useWatch({
    control,
    name: "additionalData",
    defaultValue: defaultValues.additionalData,
  });

  const canProcessVideo =
    typeof id !== "undefined" &&
    lbEnabledWatch &&
    filmedInVrWatch &&
    process.env.REACT_APP_IS_PROD !== "true";
  const onProcessSubmit = async () => {
    setProcessButtonPressed(true);
    if (hwOverrideUrlWatch) {
      if (
        !window.confirm(
          "There appear to be existing hardware overrides; do you want to split and encode the video again?"
        )
      ) {
        setProcessButtonPressed(false);
        return;
      }
    }
    const token = await getAccessTokenSilently();
    var hasVideoSpec = false;
    if (vrVideoPlayerSpecWatch && vrVideoPlayerSpecWatch !== "") {
      hasVideoSpec = true;
    }
    splitAndEncodeHardwareVideos(
      token,
      id,
      extractMuxPlaybackIdFromUrl(urlWatch),
      contentTypeWatch?.id ? contentTypeWatch.id : "",
      hasVideoSpec
    );
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        return onSubmit(data, setError);
      })}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            variant="outlined"
            name="displayName"
            label="Display Name"
            helperText={getErrorMessage("displayName", errors)}
            error={!!errors.displayName}
            color="secondary"
            inputRef={register({ required: true })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            name="description"
            label="Description"
            multiline
            helperText={getErrorMessage("description", errors)}
            color="secondary"
            inputRef={register}
          />
        </Grid>
        {Brand === "litesport" && (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                name="equipment"
                label="equipment"
                helperText={getErrorMessage("equipment", errors)}
                error={!!errors.equipment}
                color="secondary"
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                name="music"
                label="music"
                helperText={getErrorMessage("music", errors)}
                error={!!errors.music}
                color="secondary"
                inputRef={register}
              />
            </Grid>
          </>
        )}
        <Grid item xs={8}>
          <TextField
            fullWidth
            variant="outlined"
            name="additionalData"
            label="Additional Data"
            multiline
            helperText={getErrorMessage("additionalData", errors)}
            color="secondary"
            inputRef={register}
          />
        </Grid>
        <Grid item xs={4}>
          <RenderJSON
            input={additionalDataWatch}
            setStateAction={setInvalidAdditionalData}
          />
          {invalidAdditionalData && (
            <div style={{ color: "red" }}>
              Invalid JSON Detected. Check Additional Data.
            </div>
          )}
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <ReactHookFormSelect
              required
              fullWidth
              name="difficulty"
              label="Difficulty"
              control={control}
              variant="outlined"
              controlProps={{ rules: { required: true } }}
            >
              <MenuItem value={0}>Beginner</MenuItem>
              <MenuItem value={1}>Intermediate</MenuItem>
              <MenuItem value={2}>Advanced</MenuItem>
            </ReactHookFormSelect>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item spacing={2} marginTop={1}>
        <Grid item xs={2}>
          <ReactHookFormSelect
            required
            fullWidth
            name="cdnProvider"
            label="Video CDN Provider"
            control={control}
            variant="outlined"
            controlProps={{ rules: { required: true } }}
          >
            <MenuItem value={"mux"}>Mux</MenuItem>
            <MenuItem value={"brightcove"}>Brightcove</MenuItem>
          </ReactHookFormSelect>
        </Grid>
        <Grid item xs={10}>
          <TextFieldWithCopy
            name="cdnAssetId"
            label="Video CDN Asset ID"
            register={register({ required: true })}
            variant="outlined"
            helperText={getErrorMessage("cdnAssetId", errors)}
            color="secondary"
            fullWidth
          />
        </Grid>
        {!hideFileFields ? (
          <Grid container item spacing={2}>
            <Grid item>
              <UploadButton
                name="coverArt"
                label={"Cover Art"}
                helperText={getErrorMessage("coverArt", errors)}
                value={getValues("coverArt")}
                ref={register}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container item spacing={2}>
            <Grid item>
              <UploadButton
                name="coverArt"
                label={"Replace Cover Art"}
                helperText={getErrorMessage("coverArt", errors)}
                value={getValues("coverArt")}
                ref={register}
              />
            </Grid>
          </Grid>
        )}
        {!hideFileFields ? (
          <Grid container item spacing={2}>
            <Grid item>
              <UploadButton
                name="track"
                accept={Brand === "litesport" ? ".mid,.tsv" : ".tsv"}
                label={Brand === "litesport" ? "Mid file" : "TSV file"}
                helperText={getErrorMessage("track", errors)}
                value={getValues("track")}
                ref={register}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container item spacing={2}>
            <Grid item>
              <UploadButton
                name="track"
                accept={".mid,.tsv"}
                label={"Replace MID/TSV file"}
                helperText={getErrorMessage("track", errors)}
                value={getValues("track")}
                ref={register}
              />
            </Grid>
          </Grid>
        )}
        <Grid container item spacing={2}>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="length"
              label="Length"
              color="secondary"
              helperText={
                !!errors.length
                  ? getErrorMessage("length", errors)
                  : "Must be in format XmXs"
              }
              error={!!errors.length}
              inputRef={register({ required: true })}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              type="number"
              variant="outlined"
              name="maxHealth"
              label="Max Health"
              helperText={getErrorMessage("maxHealth", errors)}
              color="secondary"
              inputRef={register}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <Controller
              render={(props) => (
                <Autocomplete
                  {...props}
                  options={
                    props.value === ""
                      ? [{ id: props.value, name: "" }, ...contentTypes]
                      : contentTypes
                  }
                  getOptionLabel={(option) => {
                    return option.name ? option.name : "";
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return option.id === value;
                    } else {
                      return option.id === value.id;
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Content Type"
                      variant="outlined"
                    />
                  )}
                  onChange={(_, data) => {
                    props.onChange(data);
                    if (data.id === "mitt") {
                      control.setValue("contentVersion", v2024AprilMittDrill);
                      control.setValue(
                        "filmedInVr",
                        v2024AprilMittDrill.filmedInVr
                      );
                      control.setValue(
                        "vrVideoPlayerSpec",
                        JSON.stringify(v2024AprilMittDrill.vrVideoPlayerSpec)
                      );
                      control.setValue(
                        "minAppVersion",
                        v2024AprilMittDrill.minAppVersion
                      );
                    }
                  }}
                />
              )}
              name="contentType"
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <CreateContentTypeDialog />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item spacing={2} marginTop={1}>
        <Grid item xs={6}>
          {defaultValues.inBundle && (
            <div style={{ marginBottom: "1em", color: "red" }}>
              This content is in a bundle, and cannot be retired
            </div>
          )}
          <ReactHookFormSelect
            required
            fullWidth
            name="status"
            label="Status"
            control={control}
            variant="outlined"
            color="secondary"
            helperText={getErrorMessage("status", errors)}
            controlProps={{ rules: { required: true } }}
            disabled={defaultValues.inBundle}
          >
            <MenuItem value={"DRAFT"}>Draft</MenuItem>
            <MenuItem value={"SCHEDULED"}>Scheduled</MenuItem>
            <MenuItem value={"PUBLISHED"}>Published</MenuItem>
            <MenuItem value={"RETIRED"}>Retired</MenuItem>
          </ReactHookFormSelect>
        </Grid>
      </Grid>
      <Grid item container spacing={2} marginTop={1}>
        <Grid item xs={3}>
          <Controller
            name={"publishedDate"}
            control={control}
            render={(props) => {
              return (
                <TextField
                  label="Published Date"
                  value={props.value}
                  disabled
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name={"releaseAt"}
            control={control}
            render={(props) => {
              return (
                <DateTimePicker
                  label="Release At"
                  value={props.value}
                  onChange={(newValue) => {
                    props.onChange(newValue);
                  }}
                  id="release-date-picker"
                  renderInput={(inputProps) => (
                    <TextField fullWidth {...inputProps} />
                  )}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2} marginTop={1}>
        <Grid item xs={3}>
          <Controller
            name={"retiredDate"}
            control={control}
            render={(props) => {
              return (
                <TextField label="Retired Date" value={props.value} disabled />
              );
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name={"retireAt"}
            control={control}
            render={(props) => {
              return (
                <DateTimePicker
                  disabled={defaultValues.inBundle}
                  label="Retire At"
                  value={props.value}
                  onChange={(newValue) => {
                    props.onChange(newValue);
                  }}
                  id="retire-date-picker"
                  renderInput={(inputProps) => (
                    <TextField fullWidth {...inputProps} />
                  )}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name={"contentFeaturedOn"}
            control={control}
            render={(props) => {
              return (
                <DatePicker
                  disabled={defaultValues.inBundle}
                  label="Content Featured On"
                  value={props.value}
                  onChange={(newValue) => {
                    props.onChange(newValue);
                  }}
                  id="content-featured-date-picker"
                  renderInput={(inputProps) => (
                    <TextField fullWidth {...inputProps} />
                  )}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2} marginTop={1}>
        <Grid item xs={6}>
          <Controller
            render={(props) => (
              <Autocomplete
                {...props}
                options={
                  props.value === ""
                    ? [{ id: props.value, name: "" }, ...trainers]
                    : trainers
                }
                getOptionLabel={(option) => {
                  return option.name ? option.name : "";
                }}
                isOptionEqualToValue={(option, value) => {
                  if (value === "") {
                    return option.id === value;
                  } else {
                    return option.id === value.id;
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Trainer" variant="outlined" />
                )}
                onChange={(_, data) => props.onChange(data)}
              />
            )}
            name="trainer"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <CreateTrainerDialog />
        </Grid>
      </Grid>
      <Grid container item spacing={2} marginTop={1}>
        {Brand === "litesport" && (
          <>
            <Grid item xs={6}>
              <ReactHookFormSelect
                required={Brand === "litesport" ? true : false}
                fullWidth
                name="genres"
                label="Genres"
                control={control}
                variant="outlined"
                selectProps={{ multiple: true }}
                defaultValue={[]}
                helperText={getErrorMessage("genres", errors)}
              >
                {genres.map((genre) => {
                  return (
                    <MenuItem value={genre.name} key={genre.name}>
                      {genre.name}
                    </MenuItem>
                  );
                })}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={4}>
              <CreateGenreDialog />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <Controller
            render={(props) => (
              <Autocomplete
                multiple={true}
                {...props}
                options={
                  props.value === ""
                    ? [{ id: props.value, name: "" }, ...equipment]
                    : equipment
                }
                getOptionLabel={(option) => {
                  return option.name ? option.name : "";
                }}
                isOptionEqualToValue={(option, value) => {
                  if (value === "") {
                    return option.id === value;
                  } else {
                    return option.id === value.id;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Required Equipment"
                    variant="outlined"
                  />
                )}
                onChange={(_, data) => props.onChange(data)}
              />
            )}
            name="requiredEquipment"
            control={control}
          />
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <Controller
              render={(props) => (
                <Autocomplete
                  {...props}
                  options={
                    props.value === ""
                      ? [{ id: props.value, name: "" }, ...brands]
                      : brands
                  }
                  getOptionLabel={(option) => {
                    return option.name ? option.name : "";
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return option.id === value;
                    } else {
                      return option.id === value.id;
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Brand" variant="outlined" />
                  )}
                  onChange={(_, data) => props.onChange(data)}
                />
              )}
              name="brand"
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item spacing={2} marginTop={1} marginBottom={1}>
        <Grid item xs={2}>
          <Controller
            name={"isPremium"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                        if (e.target.checked) {
                          control.setValue("isStandard", false);
                        }
                      }}
                    />
                  }
                  label="Is Premium"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name={"isStandard"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                        if (e.target.checked) {
                          control.setValue("isPremium", false);
                        }
                      }}
                    />
                  }
                  label="Is Standard"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name={"includedInOtbApp"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="In OTB App"
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2} marginTop={1}>
        <Grid item xs={2}>
          <Controller
            name={"vrEnabled"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="VR Enabled"
                />
              );
            }}
          />
        </Grid>
        {Brand === "litesport" && (
          <>
            <Grid item xs={2}>
              <Controller
                name={"lbEnabled"}
                control={control}
                render={(props) => {
                  return (
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          name={"lbEnabled"}
                          checked={props.value}
                          onChange={(e) => {
                            props.onChange(e.target.checked);
                          }}
                        />
                      }
                      label="LB Enabled"
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <Controller
                name={"filmedInVr"}
                control={control}
                render={(props) => {
                  return (
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          name={"filmedInVr"}
                          checked={props.value}
                          onChange={(e) => {
                            props.onChange(e.target.checked);
                          }}
                        />
                      }
                      label="Filmed In VR"
                    />
                  );
                }}
              />
            </Grid>
            <Grid container item spacing={2} marginTop={1}>
              <Grid item xs={6}>
                <TextFieldWithCopy
                  name="hwOverrideUrl"
                  label={
                    processButtonPressed
                      ? "<Hardware Override Video URL Processing>"
                      : "Hardware Override Video URL"
                  }
                  register={register}
                  variant="outlined"
                  helperText={getErrorMessage("hwOverrideUrl", errors)}
                  color="secondary"
                  disabled={processButtonPressed}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldWithCopy
                  name="hwSouthpawOverrideUrl"
                  label={
                    processButtonPressed
                      ? "<Hardware Southpaw Override Video URL Processing>"
                      : "Hardware Southpaw Override Video URL"
                  }
                  register={register}
                  variant="outlined"
                  helperText={getErrorMessage("hwSouthpawOverrideUrl", errors)}
                  color="secondary"
                  disabled={processButtonPressed}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2} marginTop={1}>
              {canProcessVideo && (
                <>
                  <Button
                    variant="contained"
                    disabled={processButtonPressed}
                    onClick={() => onProcessSubmit()}
                  >
                    Create Hardware Versions
                  </Button>
                  {processButtonPressed && (
                    <p>
                      The video has been queued up for splitting and encoding
                      and these URLs will be filled by an asynchronous job in
                      approximately 15 minutes.
                      <br />
                      (You do not have to wait in the browser for this to
                      happen.)
                    </p>
                  )}
                </>
              )}
            </Grid>
            <Grid container item spacing={2} marginTop={1}>
              <Grid item xs={3}>
                <Controller
                  render={(props) => (
                    <Autocomplete
                      {...props}
                      options={
                        props.value === ""
                          ? [{ id: props.value, name: "" }, ...contentVersions]
                          : contentVersions
                      }
                      getOptionLabel={(option) => {
                        return option.name ? option.name : "";
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (value === "") {
                          return option.id === value;
                        } else {
                          return option.id === value.id;
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Content Version"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, data) => {
                        props.onChange(data);
                        control.setValue("filmedInVr", data.filmedInVr);
                        if (data.vrVideoPlayerSpec) {
                          control.setValue(
                            "vrVideoPlayerSpec",
                            JSON.stringify(data.vrVideoPlayerSpec)
                          );
                        } else {
                          control.setValue("vrVideoPlayerSpec", "");
                        }
                        if (data.minAppVersion) {
                          control.setValue("minAppVersion", data.minAppVersion);
                        } else {
                          control.setValue("minAppVersion", "");
                        }
                      }}
                    />
                  )}
                  name="contentVersion"
                  control={control}
                />
              </Grid>
              <Grid
                item
                xs={4}
                visibility={Brand === "litesport" ? "visible" : "hidden"}
              >
                <TextField
                  type="string"
                  variant="outlined"
                  name="minAppVersion"
                  label="Minimum VR App Version"
                  helperText={getErrorMessage("minAppVersion", errors)}
                  color="secondary"
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="vrVideoPlayerSpec"
                  label="VR Video Player Spec"
                  multiline
                  helperText={getErrorMessage("vrVideoPlayerSpec", errors)}
                  color="secondary"
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={4}>
                <RenderJSON
                  input={vrVideoPlayerSpecWatch}
                  setStateAction={setInvalidVrVideoPlayerSpec}
                />
                {invalidVrVideoPlayerSpec && (
                  <div style={{ color: "red" }}>
                    Invalid JSON Detected. Check VR Video Spec.
                  </div>
                )}
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={
              isSubmitting || invalidAdditionalData || invalidVrVideoPlayerSpec
            }
          >
            {isSubmitting ? "Submitting..." : "Save"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default TrainerWorkoutForm;
