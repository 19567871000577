import { baseUrl } from "../utils/http";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";

type FindPlaylistsProps = {
  token: string;
};

type FindPlaylistProps = {
  token: string;
  id: string;
};

export type Brand = {
  id: string;
  name: string;
};

export const findPlaylists = async ({ token }: FindPlaylistsProps) => {
  const response = await fetch(`${baseUrl}/playlists`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  if (!(response.status === 200)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

export const usePlaylists = () => {
  const auth0 = useAuth0();
  return useQuery(["playlists"], async () => {
    const token = (await auth0.getAccessTokenSilently()) as string;
    return findPlaylists({ token });
  });
};

export const findPlaylist = async ({ token, id }: FindPlaylistProps) => {
  const response = await fetch(`${baseUrl}/playlists/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  if (!(response.status === 200)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

export const usePlaylist = (id: string) => {
  const auth0 = useAuth0();
  return useQuery(["playlist", id], async () => {
    const token = (await auth0.getAccessTokenSilently()) as string;
    return findPlaylist({ token, id });
  });
};

// // @TODO: Replace with a real type
// type Content = any;

export type Playlist = {
  id: number | null;
  name: string;
  isPublic: boolean | false;
  isFavorites: boolean | false;
  brandId?: number;
  isProgram: boolean | false;
  weeksToComplete?: number;
  recommendedClassesPerWeek?: number;
  minutesPerDay?: string;
  description?: string;
  content?: Content[];
  image?: FileList;
  imageUrl?: string;
  brand?: Brand;
};

type Content = {
  id: number;
  displayName?: string;
  description?: string;
  status?: string;
  publishedDate?: string;
  itemId?: string;
  imageUrl?: string;
  sha1Image?: string;
  lengthInSeconds?: number;
  type?: string;
  isPremium?: boolean;
  isStandard?: boolean;
  genres?: (string[] | null | string)[];
  artist?: Artist;
  sha1Lava?: string;
  sha1Midi?: string;
  equipment?: string;
  url?: string;
  music?: string;
  difficulty?: number;
  maxDifficulty?: number;
  userHasPlayed?: boolean;
  userLastPlayed?: any;
  whyRecommended?: string;
  vrEnabled?: boolean;
  lbEnabled?: boolean;
  lessIsMore?: boolean;
};

interface Artist {
  id: number;
  name: string;
}

type CreatePlaylistProps = {
  token: string;
  playlist: Playlist;
};
export const createPlaylist = async ({
  token,
  playlist,
}: CreatePlaylistProps) => {
  const formData = new FormData();
  if (playlist.image && playlist.image.length > 0) {
    formData.append("image", playlist.image[0]);
  }
  formData.append("name", playlist.name.toString());
  formData.append("isPublic", playlist.isPublic.toString() || "false");
  formData.append("isFavorites", "false");
  formData.append("isProgram", playlist.isProgram?.toString() || "false");
  formData.append("description", playlist.description?.toString() || "");
  if (playlist.brand && playlist.brand.id) {
    formData.append("brandId", playlist.brand.id.toString());
  }
  formData.append(
    "weeksToComplete",
    playlist.weeksToComplete?.toString() || ""
  );
  formData.append(
    "recommendedClassesPerWeek",
    playlist.recommendedClassesPerWeek?.toString() || ""
  );
  formData.append("minutesPerDay", playlist.minutesPerDay?.toString() || "");
  formData.append(
    "content",
    JSON.stringify(
      playlist.content?.map((c: Content) => {
        return { id: c.id };
      })
    )
  );
  const response = await fetch(`${baseUrl}/playlists`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

type UpdatePlaylistProps = {
  token: string;
  id: string;
  playlist: Playlist;
};

export const updatePlaylist = async ({
  token,
  id,
  playlist,
}: UpdatePlaylistProps) => {
  const formData = new FormData();
  if (playlist.image && playlist.image.length > 0) {
    formData.append("image", playlist.image[0]);
  }
  formData.append("name", playlist.name.toString());
  formData.append("isPublic", playlist.isPublic.toString() || "false");
  formData.append("isFavorites", "false");
  formData.append("isProgram", playlist.isProgram?.toString() || "false");
  formData.append("description", playlist.description?.toString() || "");
  if (playlist.brand && playlist.brand.id) {
    formData.append("brandId", playlist.brand.id.toString());
  }
  formData.append(
    "weeksToComplete",
    playlist.weeksToComplete?.toString() || ""
  );
  formData.append(
    "recommendedClassesPerWeek",
    playlist.recommendedClassesPerWeek?.toString() || ""
  );
  formData.append("minutesPerDay", playlist.minutesPerDay?.toString() || "");
  formData.append(
    "content",
    JSON.stringify(
      playlist.content?.map((c: Content) => {
        return { id: c.id };
      })
    )
  );
  const response = await fetch(`${baseUrl}/playlists/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};
