import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import { useForm, Controller } from "react-hook-form";

export const MembershipCancelDialog = ({
  onClose,
  open,
  onConfirm,
  isInternal,
}) => {
  const { errors, control, handleSubmit } = useForm({
    defaultValues: { cancelReason: "", additionalReason: "" },
  });
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="membership-confirm-dialog"
      open={open}
    >
      <form
        noValidate={true}
        onSubmit={handleSubmit((data) => {
          onConfirm(data);
        })}
      >
        <DialogTitle id="membership-confirm-dialog-title">
          Are you sure you want to cancel this membership?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="membership-confirm-dialog-description">
            {isInternal
              ? "This will cancel the membership for the user. The membership can be reactivated from the admin dashboard"
              : "This will cancel the membership for the customer. You will might not be able to restart the membership for the customer. They will be required to go through the members portal to regain premium access"}
          </DialogContentText>
          <Controller
            name={"cancelReason"}
            control={control}
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <TextField
                id="cancel-reason"
                select
                required
                label="Membership Cancellation Reason"
                value={value}
                onChange={onChange}
                SelectProps={{
                  native: true,
                }}
                name={"cancelReason"}
                fullWidth={true}
                margin="normal"
                variant="outlined"
                error={!!errors.cancelReason}
                helperText={
                  errors.cancelReason && errors.cancelReason.type === "required"
                    ? "This Field is required"
                    : undefined
                }
              >
                <option value="" />
                <option value="Cost Related">Cost Related</option>
                <option value="Loss of Interest / Not Enough Time">
                  Loss of Interest / Not Enough Time
                </option>
                <option value="Injury / Illness">Injury / Illness</option>
                <option value={"Traveling / Re-Location"}>
                  Traveling / Re-Location
                </option>
                <option value={"Hardware Issues"}>Hardware Issues</option>
                <option value={"Other"}>Other</option>
              </TextField>
            )}
          />
          <Controller
            name={"additionalReason"}
            control={control}
            render={({ onChange, value }) => (
              <TextField
                id="additional"
                label=" Anything else you'd like to share?"
                multiline
                onChange={onChange}
                fullWidth={true}
                rows={4}
                value={value}
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" type={"submit"}>
            Yes, cancel membership
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
