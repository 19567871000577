import { Container, Typography } from "@mui/material";
import { RoundsChallengeForm } from "./rounds-challenge-form";
import * as React from "react";
import * as challengesApi from "../../api/challenges-api";
import { useHistory, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useCommunityChallenges } from "../../api/challenges-api";

export const EditRoundChallenge = () => {
  const { id } = useParams();
  const communityChallengeQuery = useCommunityChallenges();
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  if (communityChallengeQuery.isLoading) {
    return <div>loading...</div>;
  }

  const challenge = communityChallengeQuery.data.find((d) => {
    return d.id.toString() === id;
  });
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Edit Round Challenge
      </Typography>
      <div>
        <Typography variant="h6" gutterBottom component="div">
          Detail Image
        </Typography>
        <img src={challenge.detailImageUrl} alt={""} />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Typography variant="h6" gutterBottom component="div">
          Overview Image
        </Typography>
        <img src={challenge.overviewImageUrl} alt={""} />
      </div>
      <RoundsChallengeForm
        imagesRequired={false}
        onSubmit={async (values) => {
          const token = await getAccessTokenSilently();
          await challengesApi.updateRoundChallenge({
            token,
            id,
            ...values,
          });
          history.push("/challenges");
        }}
        defaultValues={{
          name: challenge.name,
          description: challenge.description,
          startAt:
            challenge.startAt !== null ? new Date(challenge.startAt) : null,
          endAt: challenge.endAt !== null ? new Date(challenge.endAt) : null,
          content: challenge.content !== null ? challenge.content : [],
          deviceType: challenge.deviceType,
        }}
      />
    </Container>
  );
};
