import React from "react";
import { useForm } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryCache } from "react-query";
import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as trainerApi from "../api/trainer-api";

const getErrorMessage = (key, errors) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};
export default function CreateTrainerDialog() {
  const [open, setOpen] = React.useState(false);
  const { register, errors, trigger, getValues, setError } = useForm();
  const { getAccessTokenSilently } = useAuth0();
  const queryCache = useQueryCache();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Trainer
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Trainer</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Trainer name"
            type="text"
            fullWidth
            variant="outlined"
            color="secondary"
            name={"name"}
            required
            inputRef={register({ required: true })}
            helperText={getErrorMessage("name", errors)}
            error={!!errors.name}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              const result = await trigger();
              if (result) {
                const token = await getAccessTokenSilently();
                try {
                  await trainerApi.createTrainer(token, getValues()["name"]);
                  queryCache.invalidateQueries("trainers");
                  handleClose();
                } catch (e) {
                  Object.keys(e.response.data.error).forEach((key) => {
                    setError(key, {
                      type: "manual",
                      message: e.response.data.error[key],
                    });
                  });
                }
              }
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
