import { Button, IconButton, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import UmgSongSelectControl from "../components/umg-song-select-dialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const getErrorMessage = (type) => {
  return type === "required" ? "This field is required" : "";
};

const TrainerWorkoutUmgForm = ({ onSubmit, defaultValues, displayName }) => {
  const { register, handleSubmit, control, errors, setError } = useForm({
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "umgAssociation",
  });

  return (
    <div>
      <Typography variant="h2">UMG Associations - {displayName}</Typography>

      <form
        onSubmit={handleSubmit((data) => {
          onSubmit(data, setError);
        })}
        noValidate
      >
        <Grid container spacing={2}>
          {fields.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <Grid item xs={6}>
                  <Controller
                    required={true}
                    rules={{ required: true }}
                    defaultValue={item.umgSong}
                    render={({ onChange, value }) => {
                      return (
                        <>
                          <UmgSongSelectControl
                            onChange={onChange}
                            value={value}
                          />
                          {errors.umgAssociation &&
                          errors.umgAssociation[index] &&
                          errors.umgAssociation[index].umgSong ? (
                            <div style={{ color: "red" }}>
                              This field is required
                            </div>
                          ) : null}
                        </>
                      );
                    }}
                    name={`umgAssociation[${index}].umgSong`}
                    control={control}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    required
                    id={`umgAssociation[${index}].start`}
                    fullWidth
                    variant="outlined"
                    defaultValue={
                      item.secondsStart ? `${item.secondsStart}s` : ""
                    }
                    name={`umgAssociation[${index}].start`}
                    label="Start"
                    helperText={getErrorMessage(
                      errors.umgAssociation &&
                        errors.umgAssociation[index]?.start?.type
                    )}
                    error={
                      !!(
                        errors.umgAssociation &&
                        errors.umgAssociation[index] &&
                        errors.umgAssociation[index].start
                      )
                    }
                    color="secondary"
                    inputRef={register({ required: true })}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    required
                    id={`umgAssociation[${index}].end`}
                    fullWidth
                    variant="outlined"
                    name={`umgAssociation[${index}].end`}
                    label="End"
                    defaultValue={item.secondsEnd ? `${item.secondsEnd}s` : ""}
                    helperText={getErrorMessage(
                      errors.umgAssociation &&
                        errors.umgAssociation[index]?.end?.type
                    )}
                    error={
                      !!(
                        errors.umgAssociation &&
                        errors.umgAssociation[index] &&
                        errors.umgAssociation[index].end
                      )
                    }
                    color="secondary"
                    inputRef={register({ required: true })}
                  />
                </Grid>
                <Grid item xs={1} style={{ textAlign: "center" }}>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      remove(index);
                    }}
                    size="large"
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            );
          })}
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <IconButton
              color="secondary"
              onClick={() => {
                append({ umgSong: "", startSeconds: 0, endSeconds: 0 });
              }}
              size="large"
            >
              <AddCircleIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
export default TrainerWorkoutUmgForm;
