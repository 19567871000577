import { Container, Typography } from "@mui/material";
import { ProgramGroupForm } from "./program-group-form";
import * as React from "react";
import * as programsApi from "../../api/programs-api";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ProgramGroup } from "../../api/programs-api";

export const CreateProgramGroup = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Create New Program
      </Typography>
      <ProgramGroupForm
        onSubmit={async (programGroup: ProgramGroup) => {
          const token = await getAccessTokenSilently();
          await programsApi.upsertProgramGroup({
            token,
            programGroup,
          });
          history.push("/program-groups");
        }}
        defaultValues={{
          id: 0,
          name: "",
          isActive: false,
          isVisibleToStandard: false,
          isVisibleToPremium: false,
          sortOrder: null,
        }}
      />
    </Container>
  );
};
