import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import { useState, useRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import { TextField, TextFieldProps } from "@mui/material";

interface CopyButtonProps {
  text: string;
}

const CopyButton = ({ text }: CopyButtonProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setOpen(true);
        setTimeout(() => setOpen(false), 3000); // Close the snackbar after 3000 ms
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  return (
    <>
      <IconButton onClick={copyToClipboard} aria-label="copy">
        <ContentCopyIcon />
      </IconButton>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Copied to clipboard!"
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />
    </>
  );
};

interface TextFieldWithCopyProps
  extends Omit<TextFieldProps, "variant" | "inputRef"> {
  name: string;
  label: string;
  register: any;
}

const TextFieldWithCopy = ({
  name,
  label,
  register,
  ...textFieldProps // Collecting all other props that are passed down
}: TextFieldWithCopyProps) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleBlur = () => {
    const newValue = inputRef.current ? inputRef.current.value : "";
    if (newValue !== inputValue) setInputValue(newValue);
  };

  const setRef = (inputElement: HTMLInputElement) => {
    inputRef.current = inputElement; // Assign the ref here
    register(inputElement); // Call register from your form library if needed
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        name={name}
        label={label}
        inputRef={setRef}
        onBlur={handleBlur}
        {...textFieldProps}
      />
      <CopyButton text={inputRef.current ? inputRef.current.value : ""} />
    </div>
  );
};

export default TextFieldWithCopy;
