import { baseUrl } from "../utils/http";

export const findAll = async ({ token }) => {
  const response = await fetch(`${baseUrl}/content/intro`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const createIntroContent = async ({
  displayName,
  description,
  status,
  length,
  publishedDate,
  token,
  videoUrl,
  coverArt,
  vrEnabled,
  lbEnabled,
  goEnabled,
}) => {
  const formData = new FormData();
  formData.append("displayName", displayName);
  formData.append("description", description);
  formData.append("status", status);
  formData.append("length", length);
  formData.append("publishedDate", publishedDate);
  formData.append("videoUrl", videoUrl);
  formData.append("coverArt", coverArt);
  formData.append("vrEnabled", vrEnabled);
  formData.append("lbEnabled", lbEnabled);
  formData.append("goEnabled", goEnabled);
  const response = await fetch(`${baseUrl}/content/intro`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    const body = await response.json();
    const error = new Error();
    error.response = {
      code: response.status,
      data: body,
    };
    throw error;
  }
  return await response.json();
};

export const updateIntroContent = async ({
  displayName,
  description,
  status,
  length,
  publishedDate,
  token,
  videoUrl,
  coverArt,
  vrEnabled,
  lbEnabled,
  goEnabled,
  id,
}) => {
  const formData = new FormData();
  formData.append("displayName", displayName);
  formData.append("description", description);
  formData.append("status", status);
  formData.append("length", length);
  formData.append("publishedDate", publishedDate);
  formData.append("videoUrl", videoUrl);
  formData.append("coverArt", coverArt);
  formData.append("vrEnabled", vrEnabled);
  formData.append("lbEnabled", lbEnabled);
  formData.append("goEnabled", goEnabled);
  const response = await fetch(`${baseUrl}/content/intro/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    const body = await response.json();
    const error = new Error();
    error.response = {
      code: response.status,
      data: body,
    };
    throw error;
  }
  return await response.json();
};
