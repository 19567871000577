export function padZero(num, size) {
  var s = String(num);
  while (s.length < (size || 2)) {
    s = "0" + s;
  }
  return s;
}

export function secondsToMMSS(val) {
  var minutes = Math.floor(val / 60);
  var seconds = val % 60;

  return `${minutes}:${padZero(seconds, 2)}`;
}
