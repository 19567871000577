import { Container, Typography } from "@mui/material";
import { RoundsChallengeForm } from "./rounds-challenge-form";
import * as React from "react";
import * as challengesApi from "../../api/challenges-api";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export const CreateRoundChallenge = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Create New Round Challenge
      </Typography>
      <RoundsChallengeForm
        imagesRequired={true}
        onSubmit={async (values) => {
          const token = await getAccessTokenSilently();
          await challengesApi.createRoundChallenge({
            token,
            ...values,
          });
          history.push("/challenges");
        }}
        defaultValues={{
          name: "",
          description: "",
          startAt: null,
          endAt: null,
          content: [],
          deviceType: "",
          overviewImage: "",
        }}
      />
    </Container>
  );
};
