import { Container, Typography } from "@mui/material";
import { ProgramGroupForm } from "./program-group-form";
import * as React from "react";
import * as programsApi from "../../api/programs-api";
import { useParams, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ProgramGroup } from "../../api/programs-api";

export const EditProgramGroup = () => {
  const { id } = useParams<{ id: string }>();
  const getAccessTokenSilently = useAuth0().getAccessTokenSilently;
  const programGroupQuery = programsApi.useProgramGroup(id);
  const programGroupsQuery = programsApi.useProgramGroups();
  const history = useHistory();
  if (programGroupQuery.isLoading) {
    return <div>loading...</div>;
  }
  const programGroup = programGroupQuery.data;
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Edit Program Group
      </Typography>
      <ProgramGroupForm
        onSubmit={async (programGroup: ProgramGroup) => {
          const token = await getAccessTokenSilently();
          await programsApi.upsertProgramGroup({
            token,
            programGroup,
            id,
          });
          programGroupQuery.refetch();
          programGroupsQuery.refetch();
          history.push("/program-groups");
        }}
        defaultValues={{
          name: programGroup.name ? programGroup.name : "",
          isActive: programGroup.isActive ? programGroup.isActive : false,
          isVisibleToStandard: programGroup.isVisibleToStandard
            ? programGroup.isVisibleToStandard
            : false,
          isVisibleToPremium: programGroup.isVisibleToPremium
            ? programGroup.isVisibleToPremium
            : false,
          sortOrder: programGroup.sortOrder ? programGroup.sortOrder : 100,
          programs: programGroup.programs ? programGroup.programs : [],
        }}
      />
    </Container>
  );
};
