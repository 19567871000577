import { baseUrl } from "../utils/http";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";

const findPlaysPerDay = async ({ token }, includeLB, includeGO, includeVR) => {
  const response = await fetch(
    `${baseUrl}/content/song/plays_per_day?includeLB=${includeLB}&includeGO=${includeGO}&includeVR=${includeVR}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return await response.json();
};

const createQuickplay = async ({
  displayName,
  description,
  status,
  length,
  isPremium,
  isStandard,
  releaseAt,
  retireAt,
  token,
  coverArt,
  track,
  artist,
  genres,
  umgSongId,
  vrEnabled,
  lbEnabled,
  maxHealth,
}) => {
  const formData = new FormData();
  formData.append("displayName", displayName);
  formData.append("description", description);
  formData.append("status", status);
  formData.append("length", length);
  formData.append("isPremium", isPremium);
  formData.append("isStandard", isStandard);
  if (releaseAt) {
    formData.append("releaseAt", releaseAt.toISOString());
  }
  if (retireAt) {
    formData.append("retireAt", retireAt.toISOString());
  }
  formData.append("coverArt", coverArt);
  formData.append("track", track);
  formData.append("artist", artist ?? "");
  formData.append("vrEnabled", vrEnabled);
  formData.append("lbEnabled", lbEnabled);
  formData.append("genres", JSON.stringify(genres));
  if (umgSongId) {
    formData.append("umgSongId", umgSongId);
  }
  formData.append("maxHealth", maxHealth);
  const response = await fetch(`${baseUrl}/content/song`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    const body = await response.json();
    const error = new Error();
    error.response = {
      code: response.status,
      data: body,
    };
    throw error;
  }
  return await response.json();
};

const upsertQuickplay = async ({ token, id, data }) => {
  const formData = new FormData();
  formData.append("displayName", data.displayName);
  formData.append("description", data.description);
  formData.append("status", data.status);
  formData.append("length", data.length);
  formData.append("imageUrl", data.imageUrl);
  formData.append("isPremium", data.isPremium);
  formData.append("isStandard", data.isStandard);
  if (data.releaseAt) {
    formData.append("releaseAt", data.releaseAt.toISOString());
  }
  if (data.retireAt) {
    formData.append("retireAt", data.retireAt.toISOString());
  }
  if (data.coverArt) {
    formData.append("coverArt", data.coverArt);
  }
  if (data.track) {
    formData.append("track", data.track);
  }
  formData.append("vrEnabled", data.vrEnabled);
  formData.append("lbEnabled", data.lbEnabled);
  formData.append("genres", JSON.stringify(data.genres));
  if (data.umgSongId) {
    formData.append("umgSongId", data.umgSongId);
  }
  formData.append("maxHealth", data.maxHealth);
  var urlToUse = `${baseUrl}/content/song`;
  var methodToUse = "POST";
  if (id) {
    urlToUse = `${baseUrl}/content/song/${id}`;
    methodToUse = "PUT";
  }

  const response = await fetch(urlToUse, {
    method: methodToUse,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    const body = await response.json();
    const error = new Error();
    error.response = {
      code: response.status,
      data: body,
    };
    throw error;
  }
  return await response.json();
};

const findById = async ({ token, id }) => {
  const response = await fetch(`${baseUrl}/content/song/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return await response.json();
};

const updateQuickplay = async ({ token, data, id }) => {
  let cleanedPostData = data;
  cleanedPostData.difficulty = parseInt(data.difficulty);

  const response = await fetch(`${baseUrl}/content/song/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(cleanedPostData),
  });
  const responseData = await response.json();
  if (!response.ok) {
    throw new Error(
      "Error " +
        response.status +
        " " +
        response.statusText +
        " " +
        responseData.status
    );
  } else {
    return responseData;
  }
};

export const findAll = async (token) => {
  const response = await fetch(`${baseUrl}/content/song`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  const responseData = await response.json();
  if (!response.ok) {
    throw new Error(
      "Error " +
        response.status +
        " " +
        response.statusText +
        " " +
        responseData.status
    );
  } else {
    return responseData;
  }
};

export const useQuickplayContent = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["content", "quickplay", "all"], async () => {
    const token = await getAccessTokenSilently();
    return findAll(token);
  });
};

export {
  createQuickplay,
  findById,
  updateQuickplay,
  findPlaysPerDay,
  upsertQuickplay,
};
