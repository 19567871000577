import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";

export const MembershipUnlinkDialog = ({ onClose, open, onConfirm }) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="membership-confirm-dialog"
      open={open}
    >
      <DialogTitle id="membership-confirm-dialog-title">
        Are you sure you want to unlink this device?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="membership-confirm-dialog-description">
          This membership will still be billed. The next time the primary user
          connects to an unlinked device, the device will be associated with
          this membership{" "}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onConfirm} color="secondary">
          Yes, unlink the device
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          color="secondary"
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const MembershipUnlinkGoDialog = ({ onClose, open, onConfirm }) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="membership-confirm-go-dialog"
      open={open}
    >
      <DialogTitle id="membership-confirm-go-dialog-title">
        Are you sure you want to unlink this Go device?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="membership-confirm-go-dialog-description">
          This membership will still be billed. The next time the primary user
          connects to an unlinked Go device, the device will be associated with
          this membership{" "}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onConfirm} color="secondary">
          Yes, unlink the Go device
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          color="secondary"
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const MembershipUnlinkVrDialog = ({ onClose, open, onConfirm }) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="membership-confirm-vr-dialog"
      open={open}
    >
      <DialogTitle id="membership-confirm-vr-dialog-title">
        Are you sure you want to unlink this VR device?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="membership-confirm-vr-dialog-description">
          This membership will still be billed. The next time the primary user
          connects to an unlinked VR device, the device will be associated with
          this membership{" "}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onConfirm} color="secondary">
          Yes, unlink the VR device
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          color="secondary"
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
