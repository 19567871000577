import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  Container,
  TableFooter,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useToken } from "../token-provider";
import * as React from "react";
import { usePaginatedQuery } from "react-query";
import * as deviceApi from "../api/device-api";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { format } from "date-fns";
import TablePagination from "@mui/material/TablePagination";
import { useHistory, useRouteMatch } from "react-router-dom";
import Grid from "@mui/material/Grid";

const useStylesTable = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    marginBottom: theme.spacing(2),
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions({ count, page, rowsPerPage, onChangePage }) {
  const classes = useStyles1();
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="large"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const DeviceList = () => {
  const classes = useStylesTable();
  const token = useToken();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useHistory();
  const match = useRouteMatch();
  const [email, setEmail] = React.useState("");
  const [serialNumber, setSerialNumber] = React.useState("");
  const [orderNumber, setOrderNumber] = React.useState("");
  const { resolvedData = { count: 0, items: [] } } = usePaginatedQuery(
    ["devices", page, rowsPerPage, email, orderNumber, serialNumber],
    async () => {
      return await deviceApi.findDevices({
        token,
        page,
        rowsPerPage,
        email: email,
        orderNumber: orderNumber,
        serialNumber,
      });
    }
  );

  return (
    <Container className={classes.container}>
      <Typography variant="h2">Devices</Typography>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          const { email, orderNumber, serialNumber } = event.target.elements;
          setEmail(email.value);
          setOrderNumber(orderNumber.value);
          setSerialNumber(serialNumber.value);
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField
              id="standard-basic"
              label="Email"
              name={"email"}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              id="standard-basic1"
              label="Order number"
              name={"orderNumber"}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              id="serialNumber"
              label="Serial number"
              name={"serialNumber"}
            />
          </Grid>
          <Grid item xs={3}>
            <Button type={"submit"} color="primary" variant="contained">
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Order Number</TableCell>
              <TableCell>Serial Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resolvedData.items.map((row) => (
              <TableRow key={row.id} hover>
                <TableCell component="th" scope="row">
                  {row.orderNumber}
                </TableCell>
                <TableCell>{row.serialNumber}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  {format(new Date(row.createdOn), "MMM dd, yyyy p s")}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) => {
                      history.push(`${match.path}/${row.serialNumber}`);
                    }}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={3}
                count={resolvedData.count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={(event, page) => {
                  setPage(page);
                }}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(+e.target.value);
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default DeviceList;
