import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm, Controller } from "react-hook-form";
import { useToken } from "../token-provider";
import { useDiscounts } from "../api/membership-api";

export const MembershipDiscountDialog = ({
  onClose,
  open,
  onConfirm,
  planId,
}) => {
  const token = useToken();
  const { data = [] } = useDiscounts({ token, planId });
  const { handleSubmit, control, errors } = useForm();
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="membership-confirm-dialog"
      open={open}
      maxWidth={"lg"}
      fullWidth
    >
      <DialogTitle id="membership-confirm-dialog-title">
        Add Discount to Membership
      </DialogTitle>
      <form
        onSubmit={handleSubmit((data) => {
          onConfirm(data);
        })}
        noValidate
      >
        <DialogContent>
          <DialogContentText id="membership-confirm-dialog-description">
            Discounts may be used to apply free month(s) to an existing
            membership premium
          </DialogContentText>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="discount"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={(props) => {
                  return (
                    <TextField
                      id="discount-option"
                      label={"Discount"}
                      required
                      inputRef={props.ref}
                      fullWidth
                      variant="outlined"
                      select
                      name={"discount"}
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      error={!!errors.discount}
                      helperText={
                        errors.discount ? "This field is required" : null
                      }
                    >
                      {data.map((item) => {
                        return (
                          <MenuItem value={item.id} key={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="months"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={(props) => {
                  return (
                    <TextField
                      id="months-option"
                      label={"Months"}
                      required
                      inputRef={props.ref}
                      fullWidth
                      variant="outlined"
                      select
                      name={"months"}
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      error={!!errors.months}
                      helperText={
                        errors.months ? "This field is required" : null
                      }
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                    </TextField>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"comment"}
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                render={(props) => {
                  return (
                    <TextField
                      required
                      id="comment"
                      label="Comment/Details"
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined"
                      name={props.name}
                      inputRef={props.ref}
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      error={!!errors.comment}
                      helperText={
                        errors.comment ? "This field is required" : null
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type={"submit"} variant="outlined" color="secondary">
            Apply Discount
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
