import { Route, Switch, useRouteMatch } from "react-router-dom";
import * as React from "react";
import { HubContentListPage } from "./hub-content-list-page";
import { CreateHubContent } from "./create-hub-content";
import { EditHubContent } from "./edit-hub-content";

export const HubContentIndex = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} exact>
        <HubContentListPage />
      </Route>
      <Route path={`${match.path}/create`} exact>
        <CreateHubContent />
      </Route>
      <Route path={`${match.path}/:id`}>
        <EditHubContent />
      </Route>
    </Switch>
  );
};
