import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useToken } from "../token-provider";
import {
  findUserMemberships,
  MembershipResponse,
  getCurrencyFormatter,
} from "../api/user-api";
import { SnackbarMessage } from "../components";
import { format } from "date-fns";
import { useQuery } from "react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
const formatter = getCurrencyFormatter();

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  table: {
    minWidth: 650,
  },
}));

const UserMembershipsList = ({ profileId }: { profileId: number }) => {
  const classes = useStyles();
  const token = useToken();

  // const { data = [] } = useQuery(
  const { data } = useQuery(["usermemberships", { token, profileId }], () => {
    return findUserMemberships({ token, profileId });
  });

  const [errorMessage, setErrorMessage] = useState("");
  // if (!data) return <></>;

  return (
    <>
      {data && (
        <>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Membership ID<br></br>
                    Braintree Subscription ID<br></br>
                  </TableCell>
                  <TableCell>
                    Plan<br></br>
                    Status<br></br>
                    Current Billing Period<br></br>
                  </TableCell>
                  <TableCell>
                    Payment Method<br></br>
                    Paid Through<br></br>
                    Next Bill<br></br>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.memberships.map((i: MembershipResponse) => {
                  return (
                    <TableRow key={i.id}>
                      <TableCell
                        sx={{ minWidth: 210 }}
                        component="th"
                        scope="row"
                      >
                        {i.id}
                        <br></br>
                        {i.braintreeSubscriptionID
                          ? i.braintreeSubscriptionID
                          : "-"}
                        <br></br>
                      </TableCell>
                      <TableCell>
                        {i.planType} - {i.planName}
                        <br></br>
                        {i.status}
                        <br></br>
                        {i.billingPeriodStartDate
                          ? format(
                              new Date(i.billingPeriodStartDate),
                              "MM/dd/y"
                            ) + " to "
                          : "-"}
                        {i.billingPeriodEndDate
                          ? format(new Date(i.billingPeriodEndDate), "MM/dd/y")
                          : " "}
                      </TableCell>
                      <TableCell>
                        {i.payPalAccountEmail && i.payPalAccountEmail !== ""
                          ? `Paypal: ${i.payPalAccountEmail}`
                          : ""}
                        {i.planType === "Liteboxer VR (Oculus)" ? (
                          "Paid via Oculus "
                        ) : (
                          <img
                            alt=""
                            className="tiny"
                            src={i.cardImageUrl}
                            style={{ width: "30px" }}
                          />
                        )}
                        {i.cardLast4 && i.cardLast4 !== ""
                          ? ` ending in ${i?.cardLast4}`
                          : ""}
                        <br></br>
                        {i.paidThroughDate
                          ? format(new Date(i.paidThroughDate), "MM/dd/y")
                          : ""}
                        <br></br>
                        {i.planType === "Liteboxer VR (Oculus)"
                          ? "See Oculus for details"
                          : i.nextBillAmount &&
                            formatter.format(i.nextBillAmount!) &&
                            i.nextBillDate &&
                            " on " &&
                            i.nextBillDate &&
                            format(new Date(i.nextBillDate), "MM/dd/y")}
                        <br></br>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {errorMessage ? (
        <SnackbarMessage severity={"error"} onClose={() => setErrorMessage("")}>
          {" "}
          {errorMessage}
        </SnackbarMessage>
      ) : null}
    </>
  );
};

export default UserMembershipsList;
