import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import SearchIcon from "@mui/icons-material/Search";
import { useToken } from "../token-provider";
import { useDebounce } from "../utils/useDebounce";
import { useQuery } from "react-query";
import * as umgApi from "../api/umg-api";
import { DialogContent, Grid, TextField } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import artistsString from "../utils/umg-artist-string";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
    },
    text: {
      marginRight: theme.spacing(3),
    },
    inputBox: {
      color: theme.color,
    },
    selectColor: {
      color: "rgba(0, 0, 0, 0.87)",
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
  })
);

function UmgSongSelectDialog({ onClose, open, onSelect }) {
  const token = useToken();
  const [displayName, setDisplayName] = React.useState("");
  const [artistName, setArtistName] = React.useState("");
  const debounceDisplayName = useDebounce(displayName, 500);
  const debounceArtistName = useDebounce(artistName, 500);
  const { data: umgSongs = { total: 0, content: [] } } = useQuery(
    [
      "umg-songs",
      {
        displayNameSearch: debounceDisplayName,
        artistNameSearch: debounceArtistName,
      },
    ],
    async () => {
      return umgApi.findAllUmgSongs({
        token,
        displayName: debounceDisplayName,
        artistName: debounceArtistName,
        page: 0,
        rowsPerPage: 100,
      });
    },
    { enabled: debounceDisplayName.length > 2 || debounceArtistName.length > 2 }
  );

  const handleClose = () => {
    onClose();
    setDisplayName("");
    setArtistName("");
  };

  const handleListItemClick = (value) => {
    onSelect(value);
    setDisplayName("");
    setArtistName("");
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={"xl"} fullWidth={true}>
      <DialogTitle id="simple-dialog-title">Search UMG Songs</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="displayName"
              label="Display Name"
              fullWidth
              autoComplete={"off"}
              value={displayName}
              onChange={(event) => {
                setDisplayName(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="artistName"
              label="Artist Name"
              fullWidth
              autoComplete={"off"}
              value={artistName}
              onChange={(event) => {
                setArtistName(event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <List>
          {umgSongs.total === 0 ? (
            <ListItem>
              <ListItemText primary={"Search for song by name or artist"} />
            </ListItem>
          ) : null}
          {umgSongs.content.map((song) => (
            <ListItem
              button
              onClick={() => handleListItemClick(song)}
              key={song.id}
            >
              <ListItemText
                primary={`${song.displayTitle} (${song.subtitle})`}
                secondary={artistsString(song.artists)}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

const UmgSongSelectControl = ({ onChange, value }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <TextField
            onClick={() => {
              setOpen(true);
            }}
            fullWidth
            variant="outlined"
            name="description"
            label="UMG Song"
            color="secondary"
            className={classes.selectColor}
            disabled={true}
            value={
              value
                ? `${value.displayTitle} (${value.subtitle}) - ${artistsString(
                    value.artists
                  )}`
                : "Select UMG Song"
            }
          />
        </Grid>
        <Grid item xs={6}>
          <IconButton
            aria-label="search"
            className={classes.margin}
            onClick={() => {
              setOpen(true);
            }}
            size="large"
          >
            <SearchIcon fontSize="small" />
          </IconButton>
          {value ? (
            <IconButton
              aria-label="delete"
              className={classes.margin}
              onClick={() => {
                onChange(undefined);
              }}
              size="large"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
      <UmgSongSelectDialog
        open={open}
        onSelect={(value) => {
          onChange(value);
          setOpen(false);
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default UmgSongSelectControl;
