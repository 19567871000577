import React, { useState } from "react";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
  Switch as MuiSwitch,
  Autocomplete,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useTrainerContent } from "../../api/trainer-track-api";
import { DataGrid } from "@mui/x-data-grid";
import AddBoxIcon from "@mui/icons-material/AddBox";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import { UploadButton } from "../../components/upload-button";
import { useHistory } from "react-router-dom";
import * as programsApi from "../../api/programs-api";

const getErrorMessage = (key: any, errors: any) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};

const FindContent = ({ onAdd }: any) => {
  const contentQuery = useTrainerContent();
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageText, setMessageText] = useState("");

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 30,
      renderCell: (params: any) => {
        return <div>{params.value}</div>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderCell: (params: any) => {
        return (
          <Button
            startIcon={<AddBoxIcon />}
            variant="text"
            onClick={() => {
              setMessageOpen(true);
              setMessageText(`Added ${params.row.displayName} to playlist`);
              onAdd(params.row);
            }}
          >
            Add
          </Button>
        );
      },
    },
    {
      field: "imageUrl",
      headerName: "",
      width: 100,
      renderCell: (params: any) => {
        return <img alt={"preview"} src={`${params.value}?height=50`} />;
      },
    },
    {
      field: "displayName",
      headerName: "Name",
      width: 300,
      renderCell: (params: any) => {
        return <div>{params.value}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params: any) => {
        return <div>{params.value}</div>;
      },
    },
    {
      field: "isPremium",
      headerName: "Premium",
      width: 100,
    },
    {
      field: "isStandard",
      headerName: "Standard",
      width: 100,
    },
    {
      field: "brand",
      headerName: "Brand",
      width: 200,
      renderCell: (params: any) => {
        return <div>{params.value?.name}</div>;
      },
    },
    {
      field: "publishedDate",
      headerName: "Published Date",
      width: 200,
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [nameFilter, setNameFilter] = React.useState("");
  const [idFilter, setIdFilter] = React.useState("");
  const lowerNameFilter = nameFilter.toLowerCase();
  const rows =
    contentQuery.data?.tracks.filter((item: any) => {
      if (nameFilter !== "") {
        return item.displayName.toLowerCase().includes(lowerNameFilter);
      } else if (idFilter !== "") {
        return item.id.toString() === idFilter;
      } else {
        return true;
      }
    }) ?? [];

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={messageOpen}
        autoHideDuration={1000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setMessageOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setMessageOpen(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {messageText}
        </Alert>
      </Snackbar>
      <Button variant="outlined" color={"secondary"} onClick={handleClickOpen}>
        Add Content
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <DialogTitle>Select Content</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: 1, marginBottom: 2 }}
            name={"Name"}
            value={nameFilter}
            label={"Content Name"}
            onChange={(event) => {
              setNameFilter(event.target.value);
            }}
          />
          <TextField
            sx={{ marginTop: 1, marginBottom: 2 }}
            name={"contentId"}
            value={idFilter}
            label={"Content ID"}
            onChange={(event) => {
              setIdFilter(event.target.value);
            }}
          />
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 50, 100]}
            disableSelectionOnClick
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Exit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ProgramForm = ({
  defaultValues,
  onSubmit,
  imagesRequired,
}: {
  defaultValues: any;
  onSubmit: any;
  imagesRequired: boolean;
}) => {
  const {
    control,
    handleSubmit,
    register,
    errors,
    getValues,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });
  const history = useHistory();

  const { data: groups = [] } = programsApi.useProgramGroups();

  const findHighestWeek = (content: any) => {
    if (!content.length) return 1;
    return (
      Math.max(...content.map((item: any) => parseInt(item.week) || 0)) || 1
    );
  };

  const handleAddContent = (contentItem: any) => {
    const currentContent = getValues("content");
    const isDuplicate = currentContent.some(
      (item: any) => item.id === contentItem.id
    );

    if (isDuplicate) {
      alert("Content may only be added to a program once");
      return;
    }

    const highestWeek = findHighestWeek(currentContent);
    contentItem.week = highestWeek;
    setValue("content", [...currentContent, contentItem]);
  };

  const handleMoveUp = (index: number) => {
    const currentContent = getValues("content");
    if (index === 0) return;
    const itemToMove = currentContent[index];
    const itemAbove = currentContent[index - 1];
    if (itemToMove.week > itemAbove.week) {
      itemToMove.week = itemAbove.week;
    }
    currentContent.splice(index - 1, 0, ...currentContent.splice(index, 1));
    setValue("content", [...currentContent]);
  };

  const handleMoveDown = (index: number) => {
    const currentContent = getValues("content");
    if (index === currentContent.length - 1) return;
    const itemToMove = currentContent[index];
    const itemBelow = currentContent[index + 1];
    if (itemToMove.week < itemBelow.week) {
      itemToMove.week = itemBelow.week;
    }
    currentContent.splice(index + 1, 0, ...currentContent.splice(index, 1));
    setValue("content", [...currentContent]);
  };

  const validateContentWeeks = (content: any) => {
    const weeks = content
      .map((item: any) => Number(item.week))
      .filter((week: any) => week > 0);
    const uniqueWeeks = [...new Set(weeks)].sort((a: any, b: any) => a - b);

    if (uniqueWeeks.length === 0) return true;

    for (let i = 0; i < uniqueWeeks.length; i++) {
      if (uniqueWeeks[i] !== i + 1) {
        return false;
      }
    }
    return true;
  };

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        if (!validateContentWeeks(values.content)) {
          alert(
            "Validation Error: All content must have a positive number specified for weeks. No weeks may be skipped."
          );
          return;
        }
        return onSubmit(values);
      })}
    >
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12}>
          <Controller
            name={"name"}
            rules={{ required: true }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  required
                  fullWidth
                  name={"name"}
                  label={"Name"}
                  helperText={getErrorMessage("name", errors)}
                  error={!!errors.name}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"subheader"}
            control={control}
            render={(props) => {
              return (
                <TextField
                  fullWidth
                  name={"subheader"}
                  multiline
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                  label={"Subheader"}
                  rows={3}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"description"}
            control={control}
            render={(props) => {
              return (
                <TextField
                  fullWidth
                  name={"description"}
                  multiline
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                  label={"Description"}
                  rows={3}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"introVideoUrl"}
            rules={{ required: true }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  required
                  fullWidth
                  name={"introVideoUrl"}
                  label={"Intro Video URL"}
                  helperText={getErrorMessage("introVideoUrl", errors)}
                  error={!!errors.introVideoUrl}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"introPlayerSpec"}
            rules={{ required: false }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  fullWidth
                  name={"introPlayerSpec"}
                  label={"Intro Video VR Player Spec"}
                  helperText={getErrorMessage("introPlayerSpec", errors)}
                  error={!!errors.introPlayerSpec}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"outroVideoUrl"}
            rules={{ required: false }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  fullWidth
                  name={"outroVideoUrl"}
                  label={"Outro Video URL"}
                  helperText={getErrorMessage("outroVideoUrl", errors)}
                  error={!!errors.outroVideoUrl}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"outroPlayerSpec"}
            rules={{ required: false }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  fullWidth
                  name={"outroPlayerSpec"}
                  label={"Outro Video VR Player Spec"}
                  helperText={getErrorMessage("outroPlayerSpec", errors)}
                  error={!!errors.outroPlayerSpec}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <Controller
              name={"group"}
              rules={{ required: true }}
              render={(props) => (
                <Autocomplete
                  {...props}
                  options={
                    props.value === ""
                      ? [{ id: props.value, name: props.name }, ...groups]
                      : groups
                  }
                  getOptionLabel={(option) => {
                    return option.name ? option.name : "";
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return option.id === value;
                    } else {
                      return option.id === value.id;
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Group"
                      variant="outlined"
                    />
                  )}
                  onChange={(_, data) => props.onChange(data)}
                />
              )}
              control={control}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              onClick={() =>
                window.open(
                  "/program-groups/create",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Add Group
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <UploadButton
            name="image"
            label={"Image"}
            value={getValues("image")}
            error={getErrorMessage("image", errors)}
            ref={register({ required: imagesRequired })}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"isActive"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Is Active"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"isVisibleToStandard"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Is Visible To Standard"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"isVisibleToPremium"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Is Visible To Premium"
                />
              );
            }}
          />
        </Grid>
        <Grid container item spacing={2}>
          <Controller
            name={"content"}
            control={control}
            render={(props) => {
              return (
                <>
                  <Grid item xs={12}>
                    <FindContent onAdd={handleAddContent} />
                  </Grid>
                  <Grid item xs={12}>
                    <List
                      dense
                      sx={{
                        width: "100%",
                        maxWidth: 700,
                        bgcolor: "background.paper",
                      }}
                    >
                      {props.value.map((value: any, index: any) => {
                        const labelId = `checkbox-list-secondary-label-${value}`;
                        return (
                          value &&
                          value.id && (
                            <ListItem
                              key={index}
                              secondaryAction={
                                <>
                                  {index !== 0 ? (
                                    <IconButton
                                      aria-label="move up"
                                      color="primary"
                                      onClick={() => handleMoveUp(index)}
                                    >
                                      <ArrowUpward />
                                    </IconButton>
                                  ) : null}
                                  {index !== props.value.length - 1 ? (
                                    <IconButton
                                      aria-label="move down"
                                      color="primary"
                                      onClick={() => handleMoveDown(index)}
                                    >
                                      <ArrowDownward />
                                    </IconButton>
                                  ) : null}
                                  <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    onClick={() => {
                                      const newContent = [...props.value];
                                      newContent.splice(index, 1);
                                      props.onChange(newContent);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </>
                              }
                              disablePadding
                            >
                              <ListItemButton>
                                <ListItemAvatar>
                                  <Avatar
                                    alt={`${value.displayName}`}
                                    src={`${value.imageUrl}?height=50`}
                                  />
                                </ListItemAvatar>
                                <Grid container item spacing={2}>
                                  <Grid item xs={2}>
                                    <TextField
                                      required
                                      name={"week"}
                                      label={"Week"}
                                      value={value.week}
                                      onChange={(event) => {
                                        const newContent = [...props.value];
                                        newContent[index].week =
                                          event.target.value;
                                        props.onChange(newContent);
                                      }}
                                      sx={{ width: "60px" }} // Adjust the width and margin as needed
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <ListItemText
                                      secondary={
                                        <span>
                                          <span>
                                            Status:{" "}
                                            <Typography
                                              sx={{ display: "inline" }}
                                              component="span"
                                              variant="body2"
                                              color="text.primary"
                                            >
                                              {value.status}{" "}
                                            </Typography>
                                          </span>{" "}
                                          <span>
                                            Premium:{" "}
                                            <Typography
                                              sx={{ display: "inline" }}
                                              component="span"
                                              variant="body2"
                                              color="text.primary"
                                            >
                                              {value.isPremium ? "Yes" : "No"}
                                            </Typography>
                                          </span>{" "}
                                          <span>
                                            Standard:{" "}
                                            <Typography
                                              sx={{ display: "inline" }}
                                              component="span"
                                              variant="body2"
                                              color="text.primary"
                                            >
                                              {value.isStandard ? "Yes" : "No"}
                                            </Typography>
                                          </span>{" "}
                                        </span>
                                      }
                                      id={labelId}
                                      primary={`${value.displayName}`}
                                      sx={{ width: "450px", marginRight: 2 }} // Adjust the width and margin as needed
                                    />
                                  </Grid>
                                </Grid>
                              </ListItemButton>
                            </ListItem>
                          )
                        );
                      })}
                    </List>
                  </Grid>
                </>
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          type={"submit"}
          sx={{ mr: 2 }}
          disabled={isSubmitting}
        >
          Submit
        </Button>
        <Button
          variant="text"
          disabled={isSubmitting}
          onClick={() => {
            history.push("/programs", { replace: true });
          }}
        >
          Cancel
        </Button>
      </Grid>
    </form>
  );
};
