import * as React from "react";
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import Container from "@mui/material/Container";
import { ButtonGroup, Grid, ListItemText, Typography } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { LBCard } from "../components/lb-card";
import { useToken } from "../token-provider";
import * as membershipApi from "../api/membership-api";
import { useQuery, useQueryCache } from "react-query";
import styles from "./memberships.module.css";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import { MembershipUnlinkDialog } from "./membership-unlink-dialog";
import { MembershipUnlinkGoDialog } from "./membership-unlink-dialog";
import { MembershipUnlinkVrDialog } from "./membership-unlink-dialog";
import { SnackbarMessage } from "../components";
import ColorButton from "../components/color-button";
import { CreateMembership } from "./create-membership";
import makeStyles from "@mui/styles/makeStyles";
import { MembershipCancelDialog } from "../features/membership/confirm-cancel-dialog";
import { MembershipResubscribeDialog } from "../features/membership/confirm-resubscribe-dialog";
import { MembershipDiscountDialog } from "./membership-discount";
import { useDiscounts } from "../api/membership-api";
import List from "@mui/material/List";
import { CancelledMemberships } from "./cancelled-membership";

export const MembershipIndex = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/add`} exact>
        <CreateMembership />
      </Route>
      <Route path={`${match.path}/cancelled`}>
        <CancelledMemberships />
      </Route>
      <Route path={`${match.path}/:membershipId`}>
        <MembershipDetails />
      </Route>
      <Route path={match.path}>
        <MembershipTable />
      </Route>
    </Switch>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  cancelBtn: {
    color: "red",
    borderColor: "red",
  },
}));

const MembershipDetails = () => {
  const classes = useStyles();
  const { membershipId } = useParams();
  const token = useToken();
  const { data, isLoading } = useQuery(
    ["memberships", "details", membershipId],
    () => {
      return membershipApi.findDetails({ token, membershipId });
    }
  );
  const { data: discounts = [] } = useDiscounts({
    token,
    planId: data?.planId,
  });
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const client = useQueryCache();
  const [confirmUnlink, setConfirmUnlink] = React.useState(false);
  const [confirmGoUnlink, setConfirmGoUnlink] = React.useState(false);
  const [confirmVrUnlink, setConfirmVrUnlink] = React.useState(false);
  const [confirmCancel, setConfirmCancel] = React.useState(false);
  const [addDiscountOpen, setAddDiscountOpen] = React.useState(false);
  const [confirmResubscribe, setConfirmResubscribe] = React.useState(false);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Container className="mb-5">
        <div className={classes.header}>
          <div>
            <Typography variant={"h3"}>Membership Details</Typography>
          </div>
          <ButtonGroup
            variant="outlined"
            aria-label="text primary button group"
          >
            {data.status !== "CANCELLED" && discounts.length > 0 && (
              <Button
                variant="outlined"
                onClick={() => {
                  setAddDiscountOpen(true);
                }}
              >
                Apply Discount
              </Button>
            )}

            {data.status !== "CANCELLED" && (
              <Button
                variant="outlined"
                onClick={() => {
                  setConfirmCancel(true);
                }}
                className={classes.cancelBtn}
              >
                Cancel Membership
              </Button>
            )}
            {data.status === "CANCELLED" && (
              <Button
                variant="outlined"
                onClick={() => {
                  setConfirmResubscribe(true);
                }}
              >
                Resubscribe to membership
              </Button>
            )}
          </ButtonGroup>
        </div>
        <Typography variant="caption" display="block" gutterBottom>
          Last modified by {data.lastModifiedBy} on{" "}
          {format(new Date(data.lastModifiedOn), "MMM dd, yyyy p")}
        </Typography>
        <div>
          <dl>
            <DataLabel
              title={"Billing Owner"}
              data={
                <>
                  {data.firstName} {data.lastName} ({data.email})
                </>
              }
            />
            <DataLabel title={"Status"} data={data.status} />
            <DataLabel title={"Type"} data={data.planType} />
            <DataLabel
              title={"Plan Name"}
              data={
                <>
                  <div>
                    {data.planName}{" "}
                    {data.isInternal
                      ? "(Internal)"
                      : `(${data.braintreePlanId})`}
                  </div>
                </>
              }
            />
            {data.trialPeriodDays && (
              <DataLabel
                title={"Trial Period Days"}
                data={data.trialPeriodDays}
              />
            )}
            {data.cost && (
              <DataLabel title={"Cost"} data={"$" + data.cost + " USD"} />
            )}
          </dl>
        </div>
        <div>
          <Typography variant={"h5"}>Device Details</Typography>
        </div>
        <div>
          <dl>
            <DataLabel
              title={"Device Id"}
              data={
                data.deviceId ? (
                  <>
                    {data.deviceId}{" "}
                    <Button
                      color="secondary"
                      variant="outlined"
                      size={"small"}
                      onClick={() => setConfirmUnlink(true)}
                    >
                      Unlink Liteboxer Shield
                    </Button>
                  </>
                ) : (
                  "Not linked to a device"
                )
              }
            />
            <DataLabel
              title={"Go Device Id"}
              data={
                data.goDeviceId ? (
                  <>
                    {data.goDeviceId}{" "}
                    <Button
                      color="secondary"
                      variant="outlined"
                      size={"small"}
                      onClick={() => setConfirmGoUnlink(true)}
                    >
                      Unlink Go Sensors
                    </Button>
                  </>
                ) : (
                  "Not linked to a device"
                )
              }
            />
            <DataLabel
              title={"VR Device Id"}
              data={
                data.vrDeviceId ? (
                  <>
                    {data.vrDeviceId}{" "}
                    <Button
                      color="secondary"
                      variant="outlined"
                      size={"small"}
                      onClick={() => setConfirmVrUnlink(true)}
                    >
                      Unlink VR Headset
                    </Button>
                  </>
                ) : (
                  "Not linked to a device"
                )
              }
            />
          </dl>
        </div>

        <div>
          {!data.isInternal && (
            <Typography variant={"h5"}>Payment Details</Typography>
          )}
        </div>
        <div>
          {!data.isInternal && (
            <dl>
              {data.billingPeriodStartDate && data.billingPeriodEndDate && (
                <DataLabel
                  title={"Current Biling Period"}
                  data={
                    format(
                      new Date(data.billingPeriodStartDate),
                      "yyyy-MM-dd"
                    ) +
                    " to " +
                    format(new Date(data.billingPeriodEndDate), "yyyy-MM-dd")
                  }
                />
              )}
              {data.paidThroughDate && (
                <DataLabel
                  title={"Paid Through Date"}
                  data={format(new Date(data.paidThroughDate), "yyyy-MM-dd")}
                />
              )}
              {data.nextBillDate && (
                <DataLabel
                  title={"Next Bill Date"}
                  data={format(new Date(data.nextBillDate), "yyyy-MM-dd")}
                />
              )}
              {data.nextBillAmount && (
                <DataLabel
                  title={"Next Bill Amount"}
                  data={"$" + data.nextBillAmount + " USD"}
                />
              )}
              <div className={styles.data}>
                <dt className={styles.dataLabel}>Payment Method</dt>
                <dd className={styles.dataItem}>
                  <img
                    alt="Credit Card On File"
                    className="small"
                    src={data?.cardImageUrl}
                    style={{ width: "40px" }}
                  />
                  {data.cardLast4 ? "  ending in " + data.cardLast4 : ""}
                </dd>
              </div>
              {data.braintreeCustomerId && (
                <DataLabel
                  title={"Braintree Customer ID"}
                  data={data.braintreeCustomerId}
                />
              )}
              {data.braintreeSubscriptionID && (
                <DataLabel
                  title={"Braintree Subscription ID"}
                  data={data.braintreeSubscriptionID}
                />
              )}
            </dl>
          )}
        </div>

        {data.discounts && (
          <>
            <div>
              <Typography variant={"h5"}>Discounts</Typography>
            </div>
            <List>
              {data.discounts.map((discount) => {
                return (
                  <ListItemText
                    key={discount.id}
                    primary={`${discount.months} Month(s)`}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {discount.comment}
                          <br /> {discount.createdBy} on{" "}
                          {format(
                            new Date(discount.createdOn),
                            "MMM dd, yyyy p"
                          )}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                );
              })}
            </List>
          </>
        )}
      </Container>
      <MembershipDiscountDialog
        onClose={() => setAddDiscountOpen(false)}
        open={addDiscountOpen}
        planId={data.planId}
        membershipId={data.membershipId}
        onConfirm={(formData) => {
          membershipApi
            .applyDiscount({
              token,
              membershipId: data.membershipId,
              comment: formData.comment,
              discountId: formData.discount,
              months: formData.months,
            })
            .then(() => {
              setAddDiscountOpen(false);
              client.invalidateQueries(["memberships"]);
            });
        }}
      />
      <MembershipUnlinkDialog
        onClose={() => setConfirmUnlink(false)}
        open={confirmUnlink}
        onConfirm={() => {
          membershipApi
            .unlinkDevice({ token, membershipId })
            .then((response) => {
              if (response.ok) {
                client.invalidateQueries(["memberships"]);
                setConfirmUnlink(false);
              } else {
                setErrorMessage(
                  "Could not unlink device. Contact dev team for support."
                );
              }
            });
        }}
      />
      <MembershipUnlinkGoDialog
        onClose={() => setConfirmUnlink(false)}
        open={confirmGoUnlink}
        onConfirm={() => {
          membershipApi
            .unlinkGoDevice({ token, membershipId })
            .then((response) => {
              if (response.ok) {
                client.invalidateQueries(["memberships"]);
                setConfirmGoUnlink(false);
              } else {
                setErrorMessage(
                  "Could not unlink device. Contact dev team for support."
                );
              }
            });
        }}
      />
      <MembershipUnlinkVrDialog
        onClose={() => setConfirmUnlink(false)}
        open={confirmVrUnlink}
        onConfirm={() => {
          membershipApi
            .unlinkVrDevice({ token, membershipId })
            .then((response) => {
              if (response.ok) {
                client.invalidateQueries(["memberships"]);
                setConfirmGoUnlink(false);
              } else {
                setErrorMessage(
                  "Could not unlink device. Contact dev team for support."
                );
              }
            });
        }}
      />
      <MembershipCancelDialog
        open={confirmCancel}
        onConfirm={({ cancelReason, additionalReason }) => {
          membershipApi
            .cancelMembership({
              token,
              membershipId: data.membershipId,
              additionalReason: additionalReason,
              cancelReason: cancelReason,
            })
            .then((response) => {
              if (response.ok) {
                client.invalidateQueries(["memberships"]);
                setConfirmCancel(false);
              } else {
                setErrorMessage(
                  "Could not unlink device. Contact dev team for support."
                );
              }
            });
        }}
        onClose={() => setConfirmCancel(false)}
        isInternal={data.isInternal}
      />
      <MembershipResubscribeDialog
        open={confirmResubscribe}
        onConfirm={() => {
          membershipApi
            .resubscribeMembership({ token, membershipId: data.membershipId })
            .then((response) => {
              if (response.ok) {
                client.invalidateQueries(["memberships"]);
                setConfirmResubscribe(false);
              } else {
                setErrorMessage(
                  "Could not resubscribe to membership. Contact dev team for support."
                );
              }
            });
        }}
        onClose={() => setConfirmResubscribe(false)}
        isInternal={data.isInternal}
      />

      {errorMessage && (
        <SnackbarMessage
          severity={"error"}
          onClose={() => setErrorMessage(undefined)}
        >
          {errorMessage}
        </SnackbarMessage>
      )}
    </>
  );
};

const DataLabel = ({ title, data }) => {
  return (
    <div className={styles.data}>
      <dt className={styles.dataLabel}>{title}</dt>
      <dd className={styles.dataItem}>{data}</dd>
    </div>
  );
};
const MembershipTable = () => {
  const token = useToken();
  const {
    data: stats = { trialCount: 0, activeCount: 0, last30DayCancelCount: 0 },
  } = useQuery(["memberships", "stats"], () => {
    return membershipApi.findStats({ token });
  });
  const { data: summaries = [] } = useQuery(
    ["memberships", "summaries"],
    () => {
      return membershipApi.findSummaries({ token });
    }
  );
  return (
    <Container className="mb-5">
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={8} sm={8}>
          <Typography variant="h1">Memberships</Typography>
        </Grid>

        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
          <ColorButton
            color="green"
            component={Link}
            to={"/memberships/add"}
            variant="contained"
          >
            Add Internal Membership{" "}
          </ColorButton>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid container spacing={3}>
          <LBCard title={"Active Memberships"} value={stats.activeCount} />
          <LBCard title={"Memberships on Trial"} value={stats.trialCount} />
          <LBCard
            title={
              <>
                Cancelled in Last 30 days{" "}
                <Link
                  to={"/memberships/cancelled"}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  View All
                </Link>
              </>
            }
            value={<>{stats.last30DayCancelCount}</>}
          />
        </Grid>
      </Grid>
      <div style={{ width: "100%", minHeight: 400 }}>
        <DataGrid
          getRowId={(row) => {
            return row.membershipId;
          }}
          rows={summaries}
          columns={columns}
          pageSize={20}
          autoHeight
          disableSelectionOnClick
        />
      </div>
    </Container>
  );
};

const columns = [
  {
    field: "email",
    headerName: "Primary Billing",
    flex: 1,
    renderCell: ({ value }) => {
      return (
        <div
          title={value}
          className={"MuiDataGrid-cell MuiDataGrid-cell--textLeft"}
        >
          {" "}
          {value}
        </div>
      );
    },
  },
  {
    field: "planName",
    headerName: "Plan",
    flex: 1,
    renderCell: ({ value, row }) => {
      return (
        <div
          title={value}
          className={"MuiDataGrid-cell MuiDataGrid-cell--textLeft"}
        >
          {" "}
          {value} {row.isInternal && "(Internal)"}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
  },
  {
    field: "deviceId",
    headerName: "Device",
    width: 250,
    renderCell: ({ value, row }) => {
      return (
        <div
          title={value}
          className={"MuiDataGrid-cell MuiDataGrid-cell--textLeft"}
        >
          {!!value ? value : "Unlinked"}
        </div>
      );
    },
  },
  {
    field: "goDeviceId",
    headerName: "GO Device",
    width: 250,
    renderCell: ({ value, row }) => {
      return (
        <div
          title={value}
          className={"MuiDataGrid-cell MuiDataGrid-cell--textLeft"}
        >
          {!!value ? value : "Unlinked"}
        </div>
      );
    },
  },
  {
    width: 50,
    field: "actions",
    headerName: " ",
    renderCell: (value) => {
      return <Link to={`/memberships/${value.id}`}>View</Link>;
    },
  },
];
