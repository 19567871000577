import { baseUrl } from "../utils/http";

export const findDevices = async ({
  token,
  page,
  rowsPerPage,
  email,
  orderNumber,
  serialNumber,
}) => {
  const params = new URLSearchParams({
    page: page,
    rowsPerPage,
  });
  if (email !== "") {
    params.append("email", email);
  }
  if (orderNumber !== "") {
    params.append("orderNumber", orderNumber);
  }
  if (serialNumber !== "") {
    params.append("serialNumber", serialNumber);
  }
  const response = await fetch(`${baseUrl}/devices?${params}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

export const findDeviceWorkouts = async ({
  token,
  page,
  rowsPerPage,
  serialNumber,
}) => {
  const params = new URLSearchParams({
    page: page,
    rowsPerPage,
  }).toString();
  const response = await fetch(`${baseUrl}/devices/${serialNumber}?${params}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};
