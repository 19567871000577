import React from "react";
import { Grid, TextField, MenuItem } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { UploadButton } from "../../components/upload-button";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { ReactHookFormSelect } from "../../components";

const getErrorMessage = (key, errors) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};

export const ContentBundleForm = ({
  defaultValues,
  onSubmit,
  imagesRequired,
}) => {
  const {
    control,
    handleSubmit,
    register,
    errors,
    getValues,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });
  const history = useHistory();
  return (
    <form
      noValidate
      onSubmit={handleSubmit(async (values) => {
        return onSubmit(values, setError);
      })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name={"title"}
            rules={{ required: true }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  required
                  fullWidth
                  name={"title"}
                  label={"Title"}
                  helperText={getErrorMessage("title", errors)}
                  error={!!errors.title}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"bundleSku"}
            rules={{ required: true }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  required
                  fullWidth
                  name={"bundleSku"}
                  label={"SKU"}
                  helperText={getErrorMessage("bundleSku", errors)}
                  error={!!errors.title}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"contentIds"}
            rules={{ required: true }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  required
                  fullWidth
                  name={"contentIds"}
                  label={"Content IDs"}
                  helperText={getErrorMessage("contentIds", errors)}
                  error={!!errors.title}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"description"}
            rules={{ required: false }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  fullWidth
                  name={"description"}
                  multiline
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                  label={"Description"}
                  rows={10}
                />
              );
            }}
          />
        </Grid>
        <Grid item container spacing={2}></Grid>
        <Grid item xs={6}>
          <ReactHookFormSelect
            required
            fullWidth
            name="status"
            label="Status"
            control={control}
            variant="outlined"
            color="secondary"
          >
            <MenuItem value={"DRAFT"}>Draft</MenuItem>
            <MenuItem value={"PUBLISHED"}>Published</MenuItem>
            <MenuItem value={"RETIRED"}>Retired</MenuItem>
          </ReactHookFormSelect>
        </Grid>
        <Grid item xs={6}>
          <ReactHookFormSelect
            required
            fullWidth
            name="difficulty"
            label="Difficulty"
            control={control}
            variant="outlined"
            color="secondary"
          >
            <MenuItem value={"0"}>Easy</MenuItem>
            <MenuItem value={"1"}>Medium</MenuItem>
            <MenuItem value={"2"}>Hard</MenuItem>
            <MenuItem value={"3"}>Expert</MenuItem>
          </ReactHookFormSelect>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <UploadButton
            name="image"
            label={"Image"}
            value={getValues("image")}
            error={getErrorMessage("image", errors)}
            ref={register({ required: imagesRequired })}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          type={"submit"}
          sx={{ mr: 2 }}
          disabled={isSubmitting}
        >
          Submit
        </Button>
        <Button
          variant="text"
          disabled={isSubmitting}
          onClick={() => {
            history.push("/content_bundles");
          }}
        >
          Cancel
        </Button>
      </Grid>
    </form>
  );
};
