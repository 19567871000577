import {
  Button,
  FormControlLabel,
  MenuItem,
  Switch as MuiSwitch,
  TextField,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { ReactHookFormSelect } from "./index";

const getErrorMessage = (key, errors) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};

const UploadButton = React.forwardRef(
  ({ label, accept = "image/*", value, name }, ref) => {
    const classes = useStyles();
    let selectedFile = "No file selected";
    if (value && value.length > 0) {
      selectedFile = value[0].name;
    }
    return (
      <React.Fragment>
        <div>
          <input
            accept={accept}
            className={classes.input}
            id={name}
            type="file"
            name={name}
            ref={ref}
          />
          <label htmlFor={name}>
            <Button variant="contained" color="primary" component="span">
              {label}
            </Button>
            <span>{selectedFile}</span>
          </label>
        </div>
      </React.Fragment>
    );
  }
);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
  })
);

const IntroVideoForm = ({ onSubmit, defaultValues, onCancel }) => {
  const {
    register,
    handleSubmit,
    control,
    errors,
    getValues,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        return onSubmit(data, setError);
      })}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            variant="outlined"
            name="displayName"
            label="Display Name"
            helperText={getErrorMessage("displayName", errors)}
            error={!!errors.displayName}
            color="secondary"
            inputRef={register({ required: true })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            name="description"
            multiline
            label="Description"
            helperText={getErrorMessage("description", errors)}
            color="secondary"
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            name="videoUrl"
            label="Video Url"
            helperText={getErrorMessage("videoUrl", errors)}
            color="secondary"
            inputRef={register({ required: true })}
          />
        </Grid>

        <Grid container item spacing={2}>
          <Grid item>
            <UploadButton
              name="coverArt"
              label={"Cover Art"}
              helperText={getErrorMessage("coverArt", errors)}
              value={getValues("coverArt")}
              ref={register}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={6} style={{ textAlign: "center" }}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="length"
              label="Length"
              color="secondary"
              helperText={
                !!errors.length
                  ? getErrorMessage("length", errors)
                  : "Must be in format XmXs"
              }
              error={!!errors.length}
              inputRef={register({ required: true })}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="publishedDate"
              name={"publishedDate"}
              label="Published Date"
              type="date"
              helperText={
                errors.publishedDate && errors.publishedDate.type === "required"
                  ? "This field is required"
                  : ""
              }
              error={!!errors.publishedDate}
              inputRef={register}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <ReactHookFormSelect
              required
              fullWidth
              name="status"
              label="Status"
              control={control}
              variant="outlined"
              color="secondary"
              helperText={getErrorMessage("status", errors)}
              controlProps={{ rules: { required: true } }}
            >
              <MenuItem value={"DRAFT"}>Draft</MenuItem>
              <MenuItem value={"PUBLISHED"}>Published</MenuItem>
            </ReactHookFormSelect>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"vrEnabled"}
              control={control}
              render={(props) => {
                return (
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        checked={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.checked);
                        }}
                      />
                    }
                    label="VR Enabled"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"lbEnabled"}
              control={control}
              render={(props) => {
                return (
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        name={"lbEnabled"}
                        checked={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.checked);
                        }}
                      />
                    }
                    label="LB Enabled"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"goEnabled"}
              control={control}
              render={(props) => {
                return (
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        name={"goEnabled"}
                        checked={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.checked);
                        }}
                      />
                    }
                    label="GO Enabled"
                  />
                );
              }}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting" : "Save"}
            </Button>
            <Button variant="contained" type="button" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
export default IntroVideoForm;
