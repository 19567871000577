import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import { Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import React, { useState } from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import * as stretchStrengthenApi from "../api/stretch-strength-api";
import {
  ColorButton,
  Loading,
  SearchTable,
  SnackbarMessage,
  statusChip,
} from "../components";
import BuildAndRestoreForm from "../components/build-and-restore-form";
import { useFetch } from "../utils";
import { secondsToMMSS } from "../utils/formatters";
import BuildAndRestorePlaysPerDayOnApp from "../components/build-and-restore-plays-per-day-on-app";
import { useQuery, useQueryCache } from "react-query";

const tableHeadings = [
  {
    id: "imageUrl",
    numeric: false,
    disablePadding: true,
    label: "",
    format: (value) => {
      return (
        <img
          key={value}
          src={`${value}?height=36`}
          height="36px"
          width="72px"
          alt=""
        />
      );
    },
  },
  {
    id: "displayName",
    numeric: false,
    disablePadding: true,
    label: "Track Name",
    widescreenOnly: true,
  },
  { id: "trainerName", numeric: false, disablePadding: true, label: "Trainer" },
  {
    id: "lengthInSeconds",
    numeric: true,
    disablePadding: false,
    label: "Length",
    format: (value) => {
      return secondsToMMSS(value);
    },
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    format: (value) => {
      return statusChip(value);
    },
  },
];

const StretchAndStrengthenList = withAuth0((props) => {
  const { data, error } = useFetch(`/content/build_and_restore`);

  if (!data && !error) {
    return <Loading />;
  }

  return (
    <Container className="mb-5">
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h1">Build &amp; Restore</Typography>
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
          <ColorButton
            color="green"
            component={Link}
            to={"/buildandrestore/create"}
            variant="contained"
          >
            Add New{" "}
          </ColorButton>
        </Grid>
      </Grid>
      {data && (
        <SearchTable
          rows={data.content}
          headings={tableHeadings}
          history={props.history}
          umgAssociations={false}
        />
      )}
      <BuildAndRestorePlaysPerDayOnApp />
      {error && (
        <SnackbarMessage severity="error">{this.state.error}</SnackbarMessage>
      )}
    </Container>
  );
});

const BuildAndRestoreEdit = withAuth0((props) => {
  const [message, setMessage] = useState(null);

  const { getAccessTokenSilently } = useAuth0();
  const queryCache = useQueryCache();

  const id = parseInt(props.match.params.id, 10);
  const onSubmit = async (formData) => {
    const token = await getAccessTokenSilently({
      audience: `https://api.liteboxer.com`,
    });
    try {
      const { coverArt, track, trainer, ...rest } = formData;
      await stretchStrengthenApi.updateBuildAndRestoreTrackById({
        token,
        trainerTrackId: id,
        hideItemId: true,
        hideFileFields: true,
        data: {
          ...rest,
          length: formData.length,
          trainer: parseInt(trainer.id),
          isPremium: formData.isPremium,
          isStandard: formData.isStandard,
        },
      });
      setMessage({
        result: "success",
        message: "Saved!",
      });
      queryCache.invalidateQueries("buildandrestoretracks");
      window.location.reload(false);
    } catch (e) {
      setMessage({
        result: "error",
        message: e.message,
      });
    }
  };

  const {
    data = { tracks: [] },
    isLoading,
    error,
  } = useQuery(
    "buildandrestoretracks",
    async () => {
      const token = await getAccessTokenSilently({
        audience: `https://api.liteboxer.com`,
      });
      return stretchStrengthenApi.findAllBuildAndRestoreTracks({ token });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <Loading />;
  }

  const result = data.content.find((val) => val.id === id);
  if (!result) {
    return (
      <Typography variant="h4">Something went wrong fetching {id}</Typography>
    );
  }
  const displayName = result && result.displayName;
  return (
    <Container className="mb-5" style={{ marginBottom: 50 }}>
      <Typography variant="h1">
        Build &amp; Restore Track - {displayName}
      </Typography>
      {result.imageUrl && (
        <img
          src={`${result.imageUrl}?height=512`}
          style={{
            maxWidth: "70vw",
            maxHeight: "35vw",
            width: "1024px",
            height: "512px",
            marginBottom: "30px",
          }}
          alt=""
        />
      )}
      <BuildAndRestoreForm
        onSubmit={onSubmit}
        hideItemId={true}
        hideFileFields={true}
        defaultValues={{
          isPremium: result.isPremium,
          isStandard: result.isStandard,
          displayName: result.displayName,
          description: result.description,
          length: `${result.lengthInSeconds}s`,
          status: result.status,
          publishedDate:
            result.publishedDate !== null
              ? new Date(result.publishedDate).toISOString().substr(0, 10)
              : "",
          retiredDate:
            result.retiredDate !== null
              ? new Date(result.retiredDate).toISOString().substr(0, 10)
              : "",
          releaseAt:
            result.releaseAt !== null ? new Date(result.releaseAt) : null,
          retireAt: result.retireAt !== null ? new Date(result.retireAt) : null,
          url: result.url,
          trainer: { id: result.trainerId, name: result.trainerName },
          genres: result.genres,
          music: result.music,
          equipment: result.equipment,
          difficulty: result.difficulty,
          vrEnabled: result.vrEnabled,
          lbEnabled: result.lbEnabled,
        }}
      />

      {error && <SnackbarMessage severity="error">{error}</SnackbarMessage>}
      {message && (
        <SnackbarMessage
          severity={message.result}
          onClose={() => setMessage(null)}
        >
          {message.message}
        </SnackbarMessage>
      )}
    </Container>
  );
});

const BuildAndRestoreCreate = () => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  return (
    <Container className={"mb-5"} maxWidth={"md"}>
      <Typography variant="h1">Create Build &amp; Restore</Typography>
      <BuildAndRestoreForm
        defaultValues={{
          itemId: "",
          imageUrl: "",
          publishedDate: "",
          retiredDate: "",
          retireAt: null,
          releaseAt: null,
          url: "",
          trainer: "",
          length: "",
          status: "DRAFT",
          displayName: "",
          difficulty: "",
          isPremium: false,
          isStandard: false,
          genres: [],
          description: "",
          vrEnabled: false,
          lbEnabled: false,
        }}
        onSubmit={async (values, setError) => {
          const token = await getAccessTokenSilently();
          const { coverArt, track, trainer, ...rest } = values;
          try {
            await stretchStrengthenApi.createStretchStrengthenTrack({
              ...rest,
              length: values.length,
              trainer: parseInt(values.trainer.id),
              releaseAt:
                values.releaseAt !== null ? new Date(values.releaseAt) : null,
              retireAt:
                values.retireAt !== null ? new Date(values.retireAt) : null,
              coverArt: coverArt[0],
              track: track[0],
              token,
            });
            history.push("/buildandrestore");
          } catch (e) {
            if (e.response.data && e.response.data.error) {
              Object.keys(e.response.data.error).forEach((key) => {
                setError(key, {
                  type: "manual",
                  message: e.response.data.error[key],
                });
              });
            }
          }
        }}
      />
    </Container>
  );
};

export function BuildAndRestore() {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/create`} component={BuildAndRestoreCreate} />
      <Route path={`${match.path}/:id`} component={BuildAndRestoreEdit} />
      <Route path={match.path} component={StretchAndStrengthenList} />
    </Switch>
  );
}

export default BuildAndRestore;
