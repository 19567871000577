import React from "react";
import { Switch, Route } from "react-router-dom";
import Container from "@mui/material/Container";

import { Footer } from "./components";
import Home from "./views/home";
import { Users } from "./views/users";
import Logs from "./views/logs";
import { System } from "./views/system";
import Quickplay from "./views/quickplay";
import { Workouts } from "./views/workouts";
import { Volcano } from "./views/volcano";
import { Link as RouterLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import Box from "@mui/material/Box";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import BuildAndRestore from "./views/build-and-restore";
import { Chip, Collapse, ListItemIcon } from "@mui/material";
import UMGIndex from "./views/umg";
import { useProfile } from "./auth-provider";
import WorkoutLog from "./views/workout-log";
import DeviceIndex from "./views/device";
import LogoutButton from "./components/logout-button";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { IntroIndex } from "./views/intro";
import { MembershipIndex } from "./views/memberships";
import { CommunityChallengeIndex } from "./views/community-challenges/community-challenge-index";
import { HubContentIndex } from "./views/community-hub/hub-content-index";
import { UserProfileImages } from "./views/user-profile-images";
import { ContentBundlesIndex } from "./views/content-bundles/content-bundles-index";
import { PlaylistsIndex } from "./views/playlists/playlists-index";
import { ProgramsIndex } from "./views/programs/programs-index";
import { ProgramGroupsIndex } from "./views/program_groups/program-groups-index";

const is_prod = process.env.REACT_APP_IS_PROD === "true";
export const Brand = process.env.REACT_APP_BRAND;

const adminRoutes = {
  "/": { title: "Home", component: Home, exact: true },
  "/system": { title: "System", component: System },
  "/users": { title: "Users", component: Users },
  "/logs": { title: "Logs", component: Logs },
  "/umg": {
    title: "UMG",
    component: UMGIndex,
  },
  "/volcano": {
    title: "Volcano",
    component: Volcano,
  },
  "/workoutlog": { title: "Workout History", component: WorkoutLog },
  "/device": { title: "Devices", component: DeviceIndex },
  "/playlists": { title: "Playlists", component: PlaylistsIndex },
  "/programs": { title: "Programs", component: ProgramsIndex },
  "/program-groups": { title: "Program Groups", component: ProgramGroupsIndex },
};

const contentAuthorRoutes = {
  "/": {
    title: "UMG",
    component: UMGIndex,
  },
};

const xpoAdminRoutes = {
  "/": { title: "Home", component: Home, exact: true },
  "/workouts": { title: "Content", component: Workouts, exact: true },
  "/system": { title: "System", component: System },
  "/users": { title: "Users", component: Users },
  "/playlists": { title: "Playlists", component: PlaylistsIndex },
};

const xpoContentAuthorRoutes = {
  "/": { title: "Home", component: Home, exact: true },
  "/workouts": { title: "Content", component: Workouts, exact: true },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const App = () => {
  const [navOpen, setNavOpen] = React.useState(false);
  const profile = useProfile();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  const routes = React.useMemo(() => {
    if (Brand === "xponential") {
      return profile.role === "LITEBOXER_CONTENT_AUTHOR"
        ? xpoContentAuthorRoutes
        : xpoAdminRoutes;
    } else {
      return profile.role === "LITEBOXER_CONTENT_AUTHOR"
        ? contentAuthorRoutes
        : adminRoutes;
    }
  }, [profile]);
  const classes = useStyles();
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {/* on smaller screens show page title and a menu for nav */}
          <Box display={{ xs: "inherit" }} style={{ alignItems: "center" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => {
                setNavOpen(true);
              }}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <img
              src={
                process.env.PUBLIC_URL + "/Liteboxer.App.RGB.White-Black.png"
              }
              style={{ height: "36px" }}
              alt=""
            />
            &nbsp; &nbsp;
            <Typography variant="h6"></Typography>
            <Drawer
              anchor="left"
              open={navOpen}
              onClose={() => {
                setNavOpen(false);
              }}
            >
              <List>
                {Object.entries(routes).map(([path, val]) => {
                  return (
                    <ListItem
                      button
                      key={path}
                      component={RouterLink}
                      to={path}
                      onClick={() => {
                        setNavOpen(false);
                      }}
                    >
                      <ListItemText primary={val.title} />
                    </ListItem>
                  );
                })}
                {Brand === "litesport" &&
                  profile.role !== "LITEBOXER_CONTENT_AUTHOR" && (
                    <>
                      <ListItem
                        component={RouterLink}
                        to={"/memberships"}
                        onClick={() => {
                          setNavOpen(false);
                        }}
                        button
                      >
                        <ListItemText>Memberships</ListItemText>
                      </ListItem>
                      <ListItem
                        component={RouterLink}
                        to={"/challenges"}
                        onClick={() => {
                          setNavOpen(false);
                        }}
                        button
                      >
                        <ListItemText>Community Challenges</ListItemText>
                      </ListItem>
                      <ListItem
                        component={RouterLink}
                        to={"/community_hub/content"}
                        onClick={() => {
                          setNavOpen(false);
                        }}
                        button
                      >
                        <ListItemText>Community Hub Content</ListItemText>
                      </ListItem>
                      <ListItem
                        component={RouterLink}
                        to={"/content_bundles"}
                        onClick={() => {
                          setNavOpen(false);
                        }}
                        button
                      >
                        <ListItemText>Content Bundles</ListItemText>
                      </ListItem>

                      <ListItem
                        component={RouterLink}
                        to={"/flaggedimages"}
                        onClick={() => {
                          setNavOpen(false);
                        }}
                        button
                      >
                        <ListItemText>Flagged User Profile Images</ListItemText>
                      </ListItem>
                    </>
                  )}
                {Brand !== "litesport" ? (
                  <>
                    <ListItem
                      component={RouterLink}
                      to={"/flaggedimages"}
                      onClick={() => {
                        setNavOpen(false);
                      }}
                      button
                    >
                      <ListItemText>Flagged User Profile Images</ListItemText>
                    </ListItem>
                  </>
                ) : (
                  <>
                    <ListItem button onClick={handleClick}>
                      <ListItemIcon>
                        <ListItemText primary={"Content"} />
                      </ListItemIcon>
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto">
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          component={RouterLink}
                          to={"/quickplay"}
                          onClick={() => {
                            setNavOpen(false);
                          }}
                        >
                          <ListItemText primary={"Punch Tracks"} />
                        </ListItem>
                        <ListItem
                          button
                          className={classes.nested}
                          component={RouterLink}
                          to={"/workouts"}
                          onClick={() => {
                            setNavOpen(false);
                          }}
                        >
                          <ListItemText primary={"Workouts"} />
                        </ListItem>
                        <ListItem
                          button
                          className={classes.nested}
                          component={RouterLink}
                          to={"/intro"}
                          onClick={() => {
                            setNavOpen(false);
                          }}
                        >
                          <ListItemText primary={"Intro Videos"} />
                        </ListItem>
                      </List>
                    </Collapse>
                  </>
                )}
              </List>
            </Drawer>
          </Box>

          <div style={{ flexGrow: 1 }} />
          {Brand !== "litesport" && <Chip color="secondary" label={Brand} />}

          {!is_prod && <Chip color="secondary" label="Development Test" />}
          <div style={{ flexGrow: 1 }} />
          <LogoutButton />
        </Toolbar>
      </AppBar>
      <Container className="flex-grow-1 mt-5" style={{ marginTop: "10px" }}>
        <Switch>
          {Object.entries(routes).map(([path, val]) => {
            let Component = val.component;
            return (
              <Route
                path={path}
                render={() => <Component />}
                exact={val.exact}
                key={path}
              />
            );
          })}
          <Route path={"/quickplay"}>
            <Quickplay />
          </Route>
          <Route path={"/workouts"}>
            <Workouts />
          </Route>
          <Route path={"/buildandrestore"}>
            <BuildAndRestore />
          </Route>
          <Route path={"/intro"}>
            <IntroIndex />
          </Route>
          {profile.role !== "LITEBOXER_CONTENT_AUTHOR" ? (
            <>
              <Route path={"/memberships"}>
                <MembershipIndex />
              </Route>
              <Route path={"/challenges"}>
                <CommunityChallengeIndex />
              </Route>
              <Route path={"/community_hub/content"}>
                <HubContentIndex />
              </Route>
              <Route path={"/flaggedimages"}>
                <UserProfileImages />
              </Route>
              <Route path={"/content_bundles"}>
                <ContentBundlesIndex />
              </Route>
            </>
          ) : null}
        </Switch>
      </Container>
      <Footer />
    </>
  );
};

export default App;
