import React from "react";
import {
  Button,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import * as trainerApi from "../api/trainer-api";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { ReactHookFormSelect, ReactHookFormCheckbox } from "./index";
import Autocomplete from "@mui/material/Autocomplete";
import CreateTrainerDialog from "./create-trainer-dialog";
import * as genreApi from "../api/genre-api";
import CreateGenreDialog from "./create-genre-dialog";
import { DateTimePicker } from "@mui/lab";

const UploadButton = React.forwardRef(
  ({ label, accept = "image/*", value, name }, ref) => {
    const classes = useStyles();
    let selectedFile = "No file selected";
    if (value && value.length > 0) {
      selectedFile = value[0].name;
    }
    return (
      <React.Fragment>
        <div>
          <input
            accept={accept}
            className={classes.input}
            id={name}
            type="file"
            name={name}
            ref={ref}
          />
          <label htmlFor={name}>
            <Button variant="contained" color="primary" component="span">
              {label}
            </Button>
            <span>{selectedFile}</span>
          </label>
        </div>
      </React.Fragment>
    );
  }
);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
  })
);

const getErrorMessage = (key, errors) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};

const BuildAndRestoreForm = ({
  onSubmit,
  defaultValues,
  hideItemId = false,
  hideFileFields = false,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data: trainers = [] } = useQuery("trainers", async () => {
    const token = await getAccessTokenSilently();
    return trainerApi.findAllTrainers(token);
  });

  const { data: genres = [] } = useQuery("genres", async () => {
    const token = await getAccessTokenSilently();
    return genreApi.findAllGenres(token);
  });

  const { register, handleSubmit, control, errors, getValues, setError } =
    useForm({
      defaultValues,
    });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data, setError);
      })}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            variant="outlined"
            name="displayName"
            label="Display Name"
            helperText={getErrorMessage("displayName", errors)}
            error={!!errors.displayName}
            color="secondary"
            inputRef={register({ required: true })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            variant="outlined"
            name="description"
            label="Description"
            multiline
            helperText={getErrorMessage("description", errors)}
            error={!!errors.description}
            color="secondary"
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            name="equipment"
            label="equipment"
            helperText={getErrorMessage("equipment", errors)}
            error={!!errors.equipment}
            color="secondary"
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            name="music"
            label="music"
            helperText={getErrorMessage("music", errors)}
            error={!!errors.music}
            color="secondary"
            inputRef={register}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <ReactHookFormSelect
              required
              fullWidth
              name="difficulty"
              label="Difficulty"
              control={control}
              variant="outlined"
              helperText={getErrorMessage("difficulty", errors)}
              controlProps={{ rules: { required: true } }}
            >
              <MenuItem value={0}>Beginner</MenuItem>
              <MenuItem value={1}>Intermediate</MenuItem>
              <MenuItem value={2}>Advanced</MenuItem>
            </ReactHookFormSelect>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            name="url"
            label="Video Url"
            helperText={getErrorMessage("url", errors)}
            color="secondary"
            inputRef={register({ required: true })}
          />
        </Grid>
        {!hideItemId ? (
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <TextField
                required
                helperText={getErrorMessage("itemId", errors)}
                error={!!errors.itemId}
                fullWidth
                variant="outlined"
                name="itemId"
                label="Item Id"
                color="secondary"
                inputRef={register({ required: true })}
              />
            </Grid>
          </Grid>
        ) : null}
        {!hideFileFields ? (
          <Grid item container spacing={2}>
            <Grid item>
              <UploadButton
                name="coverArt"
                label={"Cover Art"}
                helperText={getErrorMessage("coverArt", errors)}
                value={getValues("coverArt")}
                ref={register}
              />
            </Grid>
          </Grid>
        ) : null}
        {!hideFileFields ? (
          <Grid container spacing={2} item>
            <Grid item>
              <UploadButton
                name="track"
                accept={".mid,.tsv"}
                label={"Mid file"}
                helperText={getErrorMessage("track", errors)}
                value={getValues("track")}
                ref={register}
              />
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={6} style={{ textAlign: "center" }}>
          <TextField
            required
            fullWidth
            variant="outlined"
            name="length"
            label="Length"
            color="secondary"
            helperText={
              !!errors.length
                ? getErrorMessage("length", errors)
                : "Must be in format XmXs"
            }
            inputRef={register({ required: true })}
          />
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <ReactHookFormSelect
              required
              fullWidth
              name="status"
              label="Status"
              control={control}
              variant="outlined"
              color="secondary"
              helperText={getErrorMessage("status", errors)}
              controlProps={{ rules: { required: true } }}
            >
              <MenuItem value={"DRAFT"}>Draft</MenuItem>
              <MenuItem value={"SCHEDULED"}>Scheduled</MenuItem>
              <MenuItem value={"PUBLISHED"}>Published</MenuItem>
              <MenuItem value={"RETIRED"}>Retired</MenuItem>
            </ReactHookFormSelect>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={3}>
            <Controller
              name={"publishedDate"}
              control={control}
              render={(props) => {
                return (
                  <TextField
                    label="Published Date"
                    value={props.value}
                    disabled
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={"releaseAt"}
              control={control}
              render={(props) => {
                return (
                  <DateTimePicker
                    label="Release At"
                    value={props.value}
                    onChange={(newValue) => {
                      props.onChange(newValue);
                    }}
                    id="release-date-picker"
                    renderInput={(inputProps) => (
                      <TextField fullWidth {...inputProps} />
                    )}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={3}>
            <Controller
              name={"retiredDate"}
              control={control}
              render={(props) => {
                return (
                  <TextField
                    label="Retired Date"
                    value={props.value}
                    disabled
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={"retireAt"}
              control={control}
              render={(props) => {
                return (
                  <DateTimePicker
                    label="Retire At"
                    value={props.value}
                    onChange={(newValue) => {
                      props.onChange(newValue);
                    }}
                    id="retire-date-picker"
                    renderInput={(inputProps) => (
                      <TextField fullWidth {...inputProps} />
                    )}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <Controller
              render={(props) => (
                <Autocomplete
                  {...props}
                  options={
                    props.value === ""
                      ? [{ id: props.value, name: "" }, ...trainers]
                      : trainers
                  }
                  getOptionLabel={(option) => {
                    return option.name ? option.name : "";
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return option.id === value;
                    } else {
                      return option.id === value.id;
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Trainer" variant="outlined" />
                  )}
                  onChange={(_, data) => props.onChange(data)}
                />
              )}
              name="trainer"
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <CreateTrainerDialog />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={8}>
            <ReactHookFormSelect
              required
              fullWidth
              name="genres"
              label="Genres"
              control={control}
              variant="outlined"
              selectProps={{ multiple: true }}
              defaultValue={[]}
            >
              {genres.map((genre) => {
                return (
                  <MenuItem value={genre.name} key={genre.name}>
                    {genre.name}
                  </MenuItem>
                );
              })}
            </ReactHookFormSelect>
          </Grid>
          <Grid item xs={4}>
            <CreateGenreDialog />
          </Grid>
          <Grid item xs={6}>
            <ReactHookFormCheckbox
              id="isPremium"
              name={"isPremium"}
              label="Is Premium"
              control={control}
              color="secondary"
            />
          </Grid>
          <Grid item xs={6}>
            <ReactHookFormCheckbox
              id="isStandard"
              name={"isStandard"}
              label="Is Standard"
              control={control}
              color="secondary"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"vrEnabled"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="VR Enabled"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"lbEnabled"}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <Switch
                      name={"lbEnabled"}
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="LB Enabled"
                />
              );
            }}
          />
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default BuildAndRestoreForm;
