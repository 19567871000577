import { Container, Typography } from "@mui/material";
import * as React from "react";
import * as contentBundlesApi from "../../api/content-bundles-api";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ContentBundleForm } from "./content-bundle-form";

export const CreateContentBundle = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Create New Content Bundle
      </Typography>
      <ContentBundleForm
        imagesRequired={true}
        onSubmit={async (values, setError) => {
          const token = await getAccessTokenSilently();
          try {
            await contentBundlesApi.createContentBundle({
              token,
              ...values,
            });
            history.push("/content_bundles");
          } catch (e) {
            Object.keys(e.message.error).forEach((key) => {
              setError(key, {
                type: "manual",
                message: e.message.error[key],
              });
            });
          }
        }}
        defaultValues={{
          bundleSku: "",
          contentIds: [1, 2, 3, 4],
          title: "",
          image: "",
          description: null,
          difficulty: null,
          status: "",
        }}
      />
    </Container>
  );
};
