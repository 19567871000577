import { baseUrl } from "../utils/http";

const adminWorkoutUrl = `${baseUrl}/workouts`;

const findSummary = async (token, page, rowsPerPage) => {
  const response = await fetch(
    `${adminWorkoutUrl}/summary?${new URLSearchParams({
      page: page,
      rowsPerPage,
    })}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return await response.json();
};

export { findSummary };
