import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress } from "@mui/material";

const Loading = () => (
  <Dialog open={true} BackdropProps={{ invisible: true }}>
    <DialogTitle style={{ textAlign: "center" }}>
      <img
        src={process.env.PUBLIC_URL + "/Liteboxer.Symbol.RGB.Black.png"}
        style={{ width: "10vw" }}
        alt=""
      />
    </DialogTitle>
    <DialogContent style={{ textAlign: "center", overflow: "hidden" }}>
      <CircularProgress size="10vw" color="secondary" />
    </DialogContent>
  </Dialog>
);

export default Loading;
