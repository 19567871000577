import React from "react";
import { Container, Typography } from "@mui/material";
import { CompletionChallengeForm } from "./completion-challenge-form";
import * as challengeApi from "../../api/challenges-api";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

export const CreateCompletionChallenge = () => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Create New Completion Challenge
      </Typography>
      <CompletionChallengeForm
        onSubmit={async (values) => {
          const token = await getAccessTokenSilently();
          await challengeApi.createCompletionChallenge({ token, ...values });
          history.push("/challenges");
        }}
        imageRequired={true}
        defaultValues={{
          name: "",
          description: "",
          startAt: null,
          endAt: null,
          genre: "",
          deviceType: "",
          overviewImage: "",
          groupChallenge: false,
          includeQuickplay: false,
          includeTrainer: false,
          includeSparring: false,
          includeFreestyle: false,
          includeStrength: false,
          showCompletionAsPercent: false,
          totalWorkoutTime: 0,
          totalWorkoutCount: 0,
          totalWorkoutDays: 0,
          totalPunchesThrown: 0,
          totalPunchesHit: 0,
          totalCaloriesBurned: 0,
          minPerWorkoutScore: 0,
          maxPerWorkoutTime: 0,
          minPerWorkoutTime: 0,
          minPerWorkoutPercentComplete: 50,
          minQuickplayCount: 0,
          minTrainerCount: 0,
          minSparringCount: 0,
          minFreestyleCount: 0,
          minStrengthCount: 0,
        }}
      />
    </Container>
  );
};
