import { baseUrl } from "../utils/http";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";

export const findStats = async ({ token }) => {
  const response = await fetch(`${baseUrl}/memberships/stats`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const findSummaries = async ({ token }) => {
  const response = await fetch(`${baseUrl}/memberships`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const findDetails = async ({ token, membershipId }) => {
  const response = await fetch(`${baseUrl}/memberships/${membershipId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const unlinkDevice = async ({ token, membershipId }) => {
  return await fetch(
    `${baseUrl}/memberships/${membershipId}/unlink?deviceType=LB`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const unlinkGoDevice = async ({ token, membershipId }) => {
  return await fetch(
    `${baseUrl}/memberships/${membershipId}/unlink?deviceType=GO`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const unlinkVrDevice = async ({ token, membershipId }) => {
  return await fetch(
    `${baseUrl}/memberships/${membershipId}/unlink?deviceType=VR`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const findAllPlans = async ({ token }) => {
  const response = await fetch(`${baseUrl}/memberships/plans`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const createMembership = ({ token, userId, planId }) => {
  return fetch(`${baseUrl}/memberships`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ profileId: userId, planId }),
  });
};

export const cancelMembership = ({
  token,
  membershipId,
  cancelReason,
  additionalReason,
}) => {
  return fetch(`${baseUrl}/memberships/${membershipId}/cancel`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ cancelReason, additionalReason }),
  });
};
export const applyDiscount = ({
  token,
  membershipId,
  discountId,
  comment,
  months,
}) => {
  return fetch(
    `${baseUrl}/memberships/${membershipId}/discount/${discountId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ comment, months }),
    }
  );
};

export const findDiscounts = ({ token, planId }) => {
  return fetch(`${baseUrl}/memberships/plans/${planId}/discounts`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const useDiscounts = ({ token, planId }) => {
  return useQuery(
    ["discounts", "plan", planId],
    () => {
      return findDiscounts({ token, planId }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("could not fetch discounts");
        }
      });
    },
    { enabled: !!planId }
  );
};

export const resubscribeMembership = ({ token, membershipId }) => {
  return fetch(`${baseUrl}/memberships/${membershipId}/resubscribe`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const findCancellationSummary = ({ token }) => {
  return fetch(`${baseUrl}/memberships/cancels`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const useCancellationSummary = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["memberships", "cancellations"], async () => {
    const token = await getAccessTokenSilently({
      audience: `https://api.liteboxer.com`,
    });

    return findCancellationSummary({ token }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("could not fetch summaries");
      }
    });
  });
};
