import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

export const LBCard = ({ title, value }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card variant="outlined">
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            {title}
          </Typography>
          <div style={{ textAlign: "center" }}>
            <Typography variant="h4" style={{ fontSize: "3rem" }}>
              {value}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};
