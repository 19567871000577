import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { Controller } from "react-hook-form";

const ReactHookFormCheckbox = ({
  name,
  label,
  control,
  defaultValue,
  register,
  ...props
}) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={(props) => (
            <Checkbox
              onChange={(e) => props.onChange(e.target.checked)}
              checked={props.value}
            />
          )}
        />
      }
      label={label}
      {...props}
    />
  );
};

export default ReactHookFormCheckbox;
