import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useCommunityChallenges } from "../../api/challenges-api";
import { useHistory } from "react-router-dom";

export const CommunityChallengeList = () => {
  const history = useHistory();
  const columns = [
    {
      field: "overviewImageUrl",
      renderCell: (params) => {
        return (
          <img
            key={params.value}
            src={`${params.value}?height=36`}
            height="36px"
            width="36px"
            alt=""
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              if (params.row.challengeType.name === "ROUND") {
                history.push(`/challenges/rounds/${params.id}`);
              } else {
                history.push(`/challenges/completion/${params.id}`);
              }
            }}
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            {params.value}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: "challengeType",
      headerName: "Type",
      renderCell: (params) => {
        return <div>{params.value.name}</div>;
      },
      flex: 1,
    },
    {
      headerName: "Dates",
      field: "dates",
      width: 350,
      renderCell: (params) => {
        const startDate = params.row.startAt
          ? format(new Date(params.row.startAt), "MMM dd, yyyy p")
          : "N/A";
        const endDate = params.row.endAt
          ? format(new Date(params.row.endAt), "MMM dd, yyyy p")
          : "N/A";
        return (
          <div>
            {startDate} - {endDate}
          </div>
        );
      },
    },
    {
      field: "deviceType",
      headerName: "Device Type",
      width: 150,
    },
  ];
  const { data } = useCommunityChallenges();
  return (
    <div style={{ height: 600, width: "100%" }}>
      {data ? (
        <DataGrid rows={data} columns={columns} disableSelectionOnClick />
      ) : (
        <div>loading...</div>
      )}
    </div>
  );
};
