import { baseUrl } from "../utils/http";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";

// @TODO: Put this somewhere and export it
type ContentType = {
  id: string;
  name: string;
};

export type TrainerTrackResponse = {
  tracks: TrainerTrack[];
};

// @TODO: Make status enum?
export type TrainerTrack = {
  id: number;
  displayName: string;
  description: string;
  status: string;
  length: string;
  itemId: string;
  releaseAt: Date;
  retireAt: Date;
  contentFeaturedOn: Date;
  publishedDate: Date;
  retiredDate: Date;
  coverArt: string;
  track: string;
  trainer?: Trainer;
  trainerName: string;
  trainerId: number;
  brand?: Brand;
  requiredEquipment: any;
  url: string;
  isPremium: boolean;
  isStandard: boolean;
  includedInOtbApp: boolean;
  genres: any;
  equipment: string;
  music: string;
  difficulty: number;
  contentType: ContentType;
  contentDisplayName: string;
  imageUrl: string;
  additionalData: any;
  vrVideoPlayerSpec: any;
  vrEnabled: boolean;
  lbEnabled: boolean;
  lengthInSeconds: number;
  hwOverrideUrl: string;
  hwSouthpawOverrideUrl: string;
  filmedInVr: boolean;
  inBundle: boolean;
  chapters: TrainerTrackChapter[];
  cdnProvider: string;
  cdnAssetId: string;
  maxHealth: number;
  minAppVersion: string;
};

type TrainerTrackChapter = {
  playTimeSeconds: string;
};

// @TODO: Convert trainer API, put this there
export type Trainer = {
  id: string;
  name: string;
};

export type Brand = {
  id: string;
  name: string;
};

export type RequiredEquipment = {
  id: string;
  name: string;
};

type TokenArgs = {
  token?: string;
};

type CreateErrors = { [field: string]: string };

type CreateResponse = {
  status: string;
  error: CreateErrors;
};

export class CreateTrainerTrackError extends Error {
  constructor(readonly message: string, readonly errors: CreateErrors) {
    super(message);

    this.name = "ValidationError";
    this.errors = errors;
  }
}

const createTrainerTrack = async ({
  displayName,
  description,
  status,
  length,
  itemId,
  releaseAt,
  retireAt,
  contentFeaturedOn,
  token,
  coverArt,
  track,
  trainerId,
  brand,
  requiredEquipment,
  url,
  isPremium,
  isStandard,
  includedInOtbApp,
  genres,
  equipment,
  music,
  difficulty,
  contentType,
  additionalData,
  vrVideoPlayerSpec,
  vrEnabled,
  lbEnabled,
  filmedInVr,
  hwOverrideUrl,
  hwSouthpawOverrideUrl,
  maxHealth,
  minAppVersion,
}: TrainerTrack & TokenArgs) => {
  const formData = new FormData();
  formData.append("displayName", displayName);
  formData.append("description", description);
  formData.append("status", status);
  formData.append("length", length);
  formData.append("itemId", itemId);
  formData.append("url", url);
  if (releaseAt) {
    formData.append("releaseAt", releaseAt.toISOString());
  }
  if (retireAt) {
    formData.append("retireAt", retireAt.toISOString());
  }
  if (contentFeaturedOn) {
    formData.append("contentFeaturedOn", contentFeaturedOn.toISOString());
  }
  formData.append("coverArt", coverArt);
  formData.append("track", track);
  formData.append("trainer", trainerId.toString() ?? "0");
  formData.append("isPremium", isPremium.toString());
  formData.append("isStandard", isStandard.toString());
  formData.append("includedInOtbApp", includedInOtbApp.toString());
  if (genres) {
    formData.append("genres", JSON.stringify(genres));
  }
  if (brand) {
    formData.append("brand", JSON.stringify(brand));
  }

  if (requiredEquipment) {
    formData.append("requiredEquipment", JSON.stringify(requiredEquipment));
  }
  formData.append("equipment", equipment);
  formData.append("music", music);
  formData.append("difficulty", difficulty.toString());
  formData.append("vrEnabled", vrEnabled.toString());
  formData.append("lbEnabled", lbEnabled ? lbEnabled.toString() : "false");
  formData.append("filmedInVr", filmedInVr ? filmedInVr.toString() : "false");
  formData.append("hwOverrideUrl", hwOverrideUrl);
  formData.append("hwSouthpawOverrideUrl", hwSouthpawOverrideUrl);
  formData.append("contentType", contentType.id);
  formData.append("additionalData", additionalData);
  formData.append("vrVideoPlayerSpec", vrVideoPlayerSpec);
  formData.append("maxHealth", maxHealth.toString());
  formData.append("minAppVersion", minAppVersion);
  const response = await fetch(`${baseUrl}/content/video`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    if (response.status === 400) {
      const body = (await response.json()) as CreateResponse;
      const error = new CreateTrainerTrackError(body.status, body.error);
      throw error;
    } else {
      throw new Error("An unexpected error occured when saving this track");
    }
  }
  return await response.json();
};

export const useTrainerContent = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["content"], async () => {
    const token = await getAccessTokenSilently();
    return findAllTrainerTracks({ token: token });
  });
};

const findAllTrainerTracks = async ({
  token,
}: TokenArgs): Promise<TrainerTrackResponse> => {
  const response = await fetch(`${baseUrl}/content/video/all`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

const findAllContentTypes = async ({
  token,
}: TokenArgs): Promise<ContentType[]> => {
  const response = await fetch(`${baseUrl}/content_types/video`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

const findPlaysPerDay = async (
  { token }: TokenArgs,
  contentType: string,
  includeLB: boolean,
  includeGO: boolean,
  includeVR: boolean
) => {
  const response = await fetch(
    `${baseUrl}/content/video/${contentType}/plays_per_day?includeLB=${includeLB}&includeGO=${includeGO}&includeVR=${includeVR}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return await response.json();
};

type UpdateTrainerTrackByIdArgs = {
  token: string;
  trainerTrackId: number;
  contentType: string;
  hideItemId: boolean;
  hideFileFields: boolean;
  data: TrainerTrack;
};

const updateTrainerTrackById = async ({
  token,
  trainerTrackId,
  contentType,
  data,
}: UpdateTrainerTrackByIdArgs) => {
  const formData = new FormData();
  formData.append("displayName", data.displayName);
  formData.append("description", data.description);
  formData.append("status", data.status);
  formData.append("length", data.length);
  formData.append("url", data.url);
  if (data.releaseAt) {
    formData.append("releaseAt", data.releaseAt.toISOString());
  }
  if (data.retireAt) {
    formData.append("retireAt", data.retireAt.toISOString());
  }
  if (data.contentFeaturedOn) {
    formData.append("contentFeaturedOn", data.contentFeaturedOn.toISOString());
  }
  if (data.coverArt) {
    formData.append("coverArt", data.coverArt);
  }
  if (data.track) {
    formData.append("track", data.track);
  }
  formData.append("trainer", data.trainerId.toString());
  if (data.brand) {
    formData.append("brand", JSON.stringify(data.brand));
  }
  formData.append("isPremium", data.isPremium.toString());
  formData.append("isStandard", data.isStandard.toString());
  formData.append("includedInOtbApp", data.includedInOtbApp.toString());
  if (data.genres) {
    formData.append("genres", JSON.stringify(data.genres));
  }
  if (data.requiredEquipment) {
    formData.append(
      "requiredEquipment",
      JSON.stringify(data.requiredEquipment)
    );
  }
  formData.append("difficulty", data.difficulty.toString());
  formData.append("equipment", data.equipment);
  formData.append("music", data.music);
  formData.append("vrEnabled", data.vrEnabled.toString());
  formData.append(
    "lbEnabled",
    data.lbEnabled ? data.lbEnabled.toString() : "false"
  );
  formData.append(
    "filmedInVr",
    data.filmedInVr ? data.filmedInVr.toString() : "false"
  );
  formData.append(
    "hwOverrideUrl",
    data.hwOverrideUrl ? data.hwOverrideUrl : ""
  );
  formData.append(
    "hwSouthpawOverrideUrl",
    data.hwSouthpawOverrideUrl ? data.hwSouthpawOverrideUrl : ""
  );
  if (data.additionalData) {
    formData.append("additionalData", data.additionalData);
  }
  if (data.vrVideoPlayerSpec) {
    formData.append("vrVideoPlayerSpec", data.vrVideoPlayerSpec);
  }
  formData.append("maxHealth", data.maxHealth.toString());
  formData.append("minAppVersion", data.minAppVersion);
  const response = await fetch(
    `${baseUrl}/content/video/${contentType}/${trainerTrackId}?refreshOverlays=true`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  );

  const responseData = await response.json();
  if (!response.ok) {
    throw new Error(responseData.status);
  }
  return responseData;
};

const putTrainerTrackUmgAssociations = async (
  token: string,
  associations: any,
  trainerTrackId: number
) => {
  const response = await fetch(
    `${baseUrl}/content/video/${trainerTrackId}/umg`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(associations),
    }
  );

  const responseData = await response.json();
  if (!response.ok) {
    throw new Error(responseData.status);
  }
  return responseData;
};

const findAllTrainerTrackUmgAssociations = async (
  token: string,
  trainerTrackId: number
) => {
  const response = await fetch(
    `${baseUrl}/content/video/${trainerTrackId}/umg`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return await response.json();
};

const splitAndEncodeHardwareVideos = async (
  token: string,
  id: number,
  playbackId: string,
  contentType: string,
  hasVrVideoSpec: boolean
) => {
  const response = await fetch(`${baseUrl}/video/api/proxy`, {
    method: "POST",
    body: JSON.stringify({
      id: id,
      playbackId: playbackId,
      contentType: contentType,
      hasVrVideoSpec: hasVrVideoSpec,
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

export {
  createTrainerTrack,
  updateTrainerTrackById,
  findAllTrainerTracks,
  findAllContentTypes,
  putTrainerTrackUmgAssociations,
  findAllTrainerTrackUmgAssociations,
  findPlaysPerDay,
  splitAndEncodeHardwareVideos,
};
