import { baseUrl } from "../utils/http";

const adminContentTypeUrl = `${baseUrl}/content_types/video`;

const findAllContentTypes = async (token) => {
  const response = await fetch(adminContentTypeUrl, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

const createContentType = async (token, type, displayName) => {
  const response = await fetch(adminContentTypeUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type,
      displayName,
    }),
  });
  if (!(response.status === 200 || response.status === 201)) {
    const body = await response.json();
    const error = new Error();
    error.response = {
      code: response.status,
      data: body,
    };
    throw error;
  }
  return await response.json();
};
export { findAllContentTypes, createContentType };
