import { Route, Switch, useRouteMatch } from "react-router-dom";
import * as React from "react";
import { CommunityChallengeListPage } from "./community-challenge-list-page";
import { EditCompletionChallenge } from "./edit-completion-challenge";
import { CreateCompletionChallenge } from "./create-completion-challenge";
import { CreateRoundChallenge } from "./create-round-challenge";
import { EditRoundChallenge } from "./edit-round-challenge";

export const CommunityChallengeIndex = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} exact>
        <CommunityChallengeListPage />
      </Route>
      <Route path={`${match.path}/rounds/create`} exact>
        <CreateRoundChallenge />
      </Route>
      <Route path={`${match.path}/rounds/:id`}>
        <EditRoundChallenge />
      </Route>
      <Route path={`${match.path}/completion/create`} exact>
        <CreateCompletionChallenge />
      </Route>
      <Route path={`${match.path}/completion/:id`} exact>
        <EditCompletionChallenge />
      </Route>
    </Switch>
  );
};
