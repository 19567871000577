import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useHubContent } from "../../api/community-hub-api";
import { useHistory } from "react-router-dom";

export const HubContentList = () => {
  const history = useHistory();
  const columns = [
    {
      field: "imageUrl",
      headerName: "Background Image",
      renderCell: (params) => {
        return (
          <img
            key={params.value}
            src={`${params.value}?height=64`}
            height="64px"
            width="64px"
            alt=""
          />
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              history.push(`/community_hub/content/${params.id}`);
            }}
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            {params.row.title}
          </div>
        );
      },
      flex: 1,
    },
    {
      headerName: "Active Dates",
      field: "dates",
      width: 350,
      renderCell: (params) => {
        const activeFrom = params.row.activeFrom
          ? format(new Date(params.row.activeFrom), "yyyy-MM-dd p")
          : null;
        const activeTo = params.row.activeTo
          ? format(new Date(params.row.activeTo), "yyyy-MM-dd p")
          : null;
        var displayText = "";
        if (activeFrom && activeTo) {
          displayText = `${activeFrom} - ${activeTo}`;
        } else if (activeFrom) {
          displayText = `From ${activeFrom}`;
        } else if (activeTo) {
          displayText = `Until ${activeTo}`;
        } else {
          displayText = "Always";
        }
        return <div>{displayText} </div>;
      },
    },
    {
      headerName: "Devices",
      field: "devices",
      width: 150,
      renderCell: (params) => {
        const lbEnabled = params.row.lbEnabled ? "LB " : "";
        const goEnabled = params.row.goEnabled ? "GO " : "";
        const vrEnabled = params.row.vrEnabled ? "VR " : "";
        return (
          <div>
            {lbEnabled}
            {goEnabled}
            {vrEnabled}
          </div>
        );
      },
    },
    {
      headerName: "Member Types",
      field: "memberTypes",
      width: 150,
      renderCell: (params) => {
        const basicEnabled = params.row.basicEnabled ? "Basic " : "";
        const premiumEnabled = params.row.premiumEnabled ? "Premium " : "";
        return (
          <div>
            {basicEnabled}
            {premiumEnabled}
          </div>
        );
      },
    },
    {
      headerName: "Currently Visible",
      field: "visible",
      width: 150,
      renderCell: (params) => {
        const activeFrom = params.row.activeFrom
          ? new Date(params.row.activeFrom)
          : null;
        const activeTo = params.row.activeTo
          ? new Date(params.row.activeTo)
          : null;
        const currentDateTime = new Date();
        const basicEnabled = params.row.basicEnabled ? "Basic " : "";
        const premiumEnabled = params.row.premiumEnabled ? "Premium " : "";
        const lbEnabled = params.row.lbEnabled ? "LB " : "";
        const goEnabled = params.row.goEnabled ? "GO " : "";
        const vrEnabled = params.row.vrEnabled ? "VR " : "";
        if (
          (lbEnabled !== "" || goEnabled !== "" || vrEnabled !== "") &&
          (basicEnabled !== "" || premiumEnabled !== "") &&
          (activeFrom === null || activeFrom <= currentDateTime) &&
          (activeTo === null || activeTo >= currentDateTime)
        ) {
          return <div>YES</div>;
        } else {
          return <div>NO</div>;
        }
      },
    },
  ];
  const { data } = useHubContent();
  return (
    <div style={{ height: 600, width: "100%" }}>
      {data ? (
        <DataGrid rows={data} columns={columns} disableSelectionOnClick />
      ) : (
        <div>loading...</div>
      )}
    </div>
  );
};
