import React from "react";
import { FormHelperText } from "@mui/material";

export const UploadButton = React.forwardRef(
  ({ label, accept = "image/*", value, name, error }, ref) => {
    return (
      <React.Fragment>
        <div>
          <label>
            {label}{" "}
            <input
              accept={accept}
              id={name}
              type="file"
              name={name}
              ref={ref}
            />
          </label>

          {error ? (
            <FormHelperText error={!!error}>{error}</FormHelperText>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
);
