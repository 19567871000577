import { baseUrl } from "../utils/http";
import { usePaginatedQuery } from "react-query";

type LogSearchRequest = {
  token: string;
  page: number;
  rowsPerPage: number;
  logLevel?: string;
  start?: string;
  end?: string;
  profileId?: number;
  filter?: string;
  limit?: number;
  hours?: number;
  sort?: string;
};

export type LogEntry = {
  row: number;
  timestamp: string;
  caller: string;
  level: string;
  msg: string;
};

export type LogsResponse = {
  logs: LogEntry[];
};

export const searchLogs = async ({
  token,
  logLevel,
  start,
  end,
  profileId,
  filter,
  limit,
  hours,
  sort,
  page,
  rowsPerPage,
}: LogSearchRequest): Promise<LogsResponse> => {
  const requestUrl = new URL(`${baseUrl}/logs`);

  // Set parameters on the URL
  if (logLevel) requestUrl.searchParams.set("logLevel", logLevel);
  if (start) requestUrl.searchParams.set("start", start);
  if (end) requestUrl.searchParams.set("end", end);
  if (profileId) requestUrl.searchParams.set("profileId", profileId.toString());
  if (filter) requestUrl.searchParams.set("filter", filter);
  if (limit) requestUrl.searchParams.set("limit", limit.toString());
  if (hours) requestUrl.searchParams.set("hours", hours.toString());
  if (sort) requestUrl.searchParams.set("sort", sort);

  // Use the URL with the appended search parameters in the fetch request
  const response = await fetch(requestUrl.toString(), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  return await response.json();
};

export const useLogs = (
  token: string,
  page: number = 0,
  rowsPerPage: number = 100,
  profileId?: number,
  logLevel?: string,
  start?: string,
  end?: string,
  filter?: string,
  limit?: number,
  hours?: number,
  sort?: string
) => {
  return usePaginatedQuery<LogsResponse>(
    [
      "logs",
      token,
      profileId,
      logLevel,
      start,
      end,
      filter,
      limit,
      hours,
      sort,
      page,
      rowsPerPage,
    ],
    async () => {
      return await searchLogs({
        token,
        profileId,
        page,
        rowsPerPage,
        logLevel,
        start,
        end,
        filter,
        limit,
        hours,
        sort,
      });
    }
  );
};
