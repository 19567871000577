import React from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Link, useRouteMatch } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { PlaylistsList } from "./playlists-list";

export const PlaylistsListPage = () => {
  let match = useRouteMatch();
  return (
    <Container className="mb-5">
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={8} sm={8}>
          <Typography variant="h2">Playlists</Typography>
        </Grid>

        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
          <ButtonGroup variant="contained">
            <Button
              component={Link}
              to={`${match.path}/create`}
              startIcon={<AddIcon />}
            >
              Create
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <PlaylistsList />
    </Container>
  );
};
