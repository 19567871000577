import { Route, Switch, useRouteMatch } from "react-router-dom";
import * as React from "react";
import { ProgramListPage } from "./program-list-page";
import { EditProgram } from "./edit-program";
import { CreateProgram } from "./create-program";

export const ProgramsIndex = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} exact>
        <ProgramListPage />
      </Route>
      <Route path={`${match.path}/create`} exact>
        <CreateProgram />
      </Route>
      <Route path={`${match.path}/:id`}>
        <EditProgram />
      </Route>
    </Switch>
  );
};
