import React from "react";
import { Container, Typography } from "@mui/material";
import { CompletionChallengeForm } from "./completion-challenge-form";
import { useHistory, useParams } from "react-router-dom";
import { useCommunityChallenges } from "../../api/challenges-api";
import { useAuth0 } from "@auth0/auth0-react";
import * as challengeApi from "../../api/challenges-api";

export const EditCompletionChallenge = () => {
  const { id } = useParams();
  const communityChallengeQuery = useCommunityChallenges();
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  if (communityChallengeQuery.isLoading) {
    return <div>loading...</div>;
  }

  const challenge = communityChallengeQuery.data.find((d) => {
    return d.id.toString() === id;
  });

  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Edit Completion Challenge
      </Typography>

      <div>
        <Typography variant="h6" gutterBottom component="div">
          Detail Image
        </Typography>
        <img src={challenge.detailImageUrl} alt={""} />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Typography variant="h6" gutterBottom component="div">
          Overview Image
        </Typography>
        <img src={challenge.overviewImageUrl} alt={""} />
      </div>
      <CompletionChallengeForm
        imageRequired={false}
        onSubmit={async (values) => {
          const token = await getAccessTokenSilently();
          try {
            await challengeApi.updateCompletionChallenge({
              id,
              token,
              ...values,
            });
            history.push("/challenges");
          } catch (e) {
            console.error(e);
          }
        }}
        defaultValues={{
          name: challenge.name,
          description: challenge.description,
          startAt:
            challenge.startAt !== null ? new Date(challenge.startAt) : null,
          endAt: challenge.endAt !== null ? new Date(challenge.endAt) : null,
          genre:
            challenge.criteria?.genre !== undefined &&
            challenge.criteria?.genre !== null
              ? challenge.criteria?.genre
              : "",
          deviceType: challenge.deviceType,
          overviewImage: "",
          groupChallenge: challenge.criteria.groupChallenge,
          includeQuickplay: challenge.criteria.includeQuickplay,
          includeTrainer: challenge.criteria.includeTrainer,
          includeSparring: challenge.criteria.includeSparring,
          includeFreestyle: challenge.criteria.includeFreestyle,
          includeStrength: challenge.criteria.includeStrength,
          showProgressAsPercent: challenge.criteria.showProgressAsPercent,
          totalWorkoutTime:
            challenge.criteria.totalWorkoutTime !== null
              ? challenge.criteria.totalWorkoutTime
              : 0,
          totalWorkoutCount:
            challenge.criteria.totalWorkoutCount !== null
              ? challenge.criteria.totalWorkoutCount
              : 0,
          totalWorkoutDays:
            challenge.criteria.totalWorkoutDays !== null
              ? challenge.criteria.totalWorkoutDays
              : 0,
          totalPunchesThrown:
            challenge.criteria.totalPunchesThrown !== null
              ? challenge.criteria.totalPunchesThrown
              : 0,
          totalPunchesHit:
            challenge.criteria.totalPunchesHit !== null
              ? challenge.criteria.totalPunchesHit
              : 0,
          totalCaloriesBurned:
            challenge.criteria.totalCaloriesBurned !== null
              ? challenge.criteria.totalCaloriesBurned
              : 0,
          minPerWorkoutScore:
            challenge.criteria.minPerWorkoutScore !== null
              ? challenge.criteria.minPerWorkoutScore
              : 0,
          maxPerWorkoutTime:
            challenge.criteria.maxPerWorkoutTime !== null
              ? challenge.criteria.maxPerWorkoutTime
              : 0,
          minPerWorkoutTime:
            challenge.criteria.minPerWorkoutTime !== null
              ? challenge.criteria.minPerWorkoutTime
              : 0,
          minPerWorkoutPercentComplete:
            challenge.criteria.minPerWorkoutPercentComplete !== null
              ? challenge.criteria.minPerWorkoutPercentComplete
              : 0,
          minQuickplayCount:
            challenge.criteria.minQuickplayCount !== null
              ? challenge.criteria.minQuickplayCount
              : 0,
          minTrainerCount:
            challenge.criteria.minTrainerCount !== null
              ? challenge.criteria.minTrainerCount
              : 0,
          minSparringCount:
            challenge.criteria.minSparringCount !== null
              ? challenge.criteria.minSparringCount
              : 0,
          minFreestyleCount:
            challenge.criteria.minFreestyleCount !== null
              ? challenge.criteria.minFreestyleCount
              : 0,
          minStrengthCount:
            challenge.criteria.minStrengthCount !== null
              ? challenge.criteria.minStrengthCount
              : 0,
        }}
      />
    </Container>
  );
};
