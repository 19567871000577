import { baseUrl } from "../utils/http";

const profileUrl = `${baseUrl}/me`;

const findMyProfile = async (token) => {
  const response = await fetch(`${profileUrl}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

export { findMyProfile };
