import { baseUrl } from "../utils/http";

const adminUmgSongsUrl = `${baseUrl}/umg/songs`;
const adminUmgIngestionUrl = `${baseUrl}/umg/ingestion`;

const findAllUmgSongs = async ({
  token,
  displayName,
  artistName,
  page,
  rowsPerPage,
}) => {
  const queryParams = new URLSearchParams();
  if (Boolean(displayName)) {
    queryParams.append("name", displayName);
  }
  if (Boolean(artistName)) {
    queryParams.append("artist", artistName);
  }
  queryParams.append("page", page);
  queryParams.append("rowsPerPage", rowsPerPage);

  const response = await fetch(adminUmgSongsUrl + `?${queryParams}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

const runIngestion = async ({ token }) => {
  const response = await fetch(adminUmgIngestionUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response.status;
};

const findSignedUrl = async ({ token, songId }) => {
  const response = await fetch(`${adminUmgSongsUrl}/${songId}/track_url`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

// returns empty string if the song does not have a album cover image
const findCoverUrl = async ({ token, songId }) => {
  const response = await fetch(`${adminUmgSongsUrl}/${songId}/cover_url`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

export { findAllUmgSongs, runIngestion, findSignedUrl, findCoverUrl };
