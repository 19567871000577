import * as React from "react";
import Container from "@mui/material/Container";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useToken } from "../token-provider";
import * as membershipApi from "../api/membership-api";
import { useQuery } from "react-query";
import { Alert, Autocomplete } from "@mui/material";
import * as userApi from "../api/user-api";
import ColorButton from "../components/color-button";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

const membershipFilterOptions = (options, { inputValue, getOptionLabel }) => {
  const limit = 200;

  let input = stripDiacritics(inputValue.trim().toLowerCase());

  const output =
    input === ""
      ? []
      : options.filter((option) => {
          let candidate = stripDiacritics(getOptionLabel(option).toLowerCase());

          return candidate.indexOf(input) === 0;
        });
  return output.slice(0, limit);
}; // To replace with .findIndex() once we stop IE 11 support.

function stripDiacritics(string) {
  return typeof string.normalize !== "undefined"
    ? string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    : string;
}

export const CreateMembership = () => {
  const token = useToken();
  const { data = [] } = useQuery(["memberships", "plans"], () => {
    return membershipApi.findAllPlans({ token });
  });
  const { data: users = [], isLoading } = useQuery(["users"], () => {
    return userApi.findAllUsers({ token });
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm();
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const history = useHistory();
  return (
    <Container className="mb-5">
      <form
        onSubmit={handleSubmit((data) => {
          setErrorMessage(undefined);
          return membershipApi
            .createMembership({
              token,
              userId: data.user.id,
              planId: data.planId,
            })
            .then((response) => {
              if (response.ok) {
                history.push("/memberships");
              } else if (response.status === 400) {
                response.json().then((responseData) => {
                  setErrorMessage(responseData.status);
                });
              } else {
                setErrorMessage(
                  "Something unexpected occurred. Contact Dev team for assistance."
                );
              }
            });
        })}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"h3"}>Add Membership</Typography>
            <Alert severity="info">
              <div>
                {" "}
                Memberships should only be added for Liteboxer employees and
                VIPs.
              </div>
              <div>
                {" "}
                You will only be able to select membership plans which do not
                require billing details.
              </div>
            </Alert>
          </Grid>
          {errorMessage ? (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="age-native-simple">
                Membership Plan
              </InputLabel>
              <Controller
                control={control}
                name={"planId"}
                defaultValue={""}
                rules={{ required: true }}
                render={(props) => {
                  return (
                    <Select native {...props} error={!!errors.planId}>
                      <option aria-label="None" value="" />
                      {data
                        .filter((d) => d.isInternal)
                        .map((d) => {
                          return (
                            <option value={d.id} key={d.id}>
                              {d.name}
                            </option>
                          );
                        })}
                    </Select>
                  );
                }}
              />
              {errors.planId && errors.planId.type === "required" ? (
                <FormHelperText error={true}>
                  This field is required
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <Controller
                control={control}
                name={"user"}
                defaultValue={""}
                rules={{ required: true }}
                render={(props) => {
                  return (
                    <Autocomplete
                      {...props}
                      loading={isLoading}
                      options={users}
                      filterOptions={membershipFilterOptions}
                      getOptionLabel={(option) => {
                        if (option) {
                          return option.email;
                        } else {
                          return "";
                        }
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (value === "") {
                          return true;
                        } else if (value === option) {
                          return true;
                        }
                      }}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          error={!!errors.user}
                          label="User"
                          variant="outlined"
                          {...params}
                        />
                      )}
                      onChange={(_, data) => {
                        if (data) {
                          props.onChange(data);
                        }
                      }}
                    />
                  );
                }}
              />

              {errors.planId && errors.planId.type === "required" ? (
                <FormHelperText error={true}>
                  This field is required
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ColorButton
              color="green"
              variant="outlined"
              type="submit"
              disabled={isSubmitting}
            >
              Add Membership
            </ColorButton>
            <Button
              variant="outlined"
              style={{ marginLeft: 5 }}
              disabled={isSubmitting}
              onClick={() => {
                history.replace("/memberships");
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
