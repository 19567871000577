import { Route, Switch, useRouteMatch } from "react-router-dom";
import * as React from "react";
import { ProgramGroupsListPage } from "./program-group-list-page";
import { EditProgramGroup } from "./edit-program-group";
import { CreateProgramGroup } from "./create-program-group";

export const ProgramGroupsIndex = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} exact>
        <ProgramGroupsListPage />
      </Route>
      <Route path={`${match.path}/create`} exact>
        <CreateProgramGroup />
      </Route>
      <Route path={`${match.path}/:id`}>
        <EditProgramGroup />
      </Route>
    </Switch>
  );
};
