import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useToken } from "../token-provider";
import { LogEntry, useLogs } from "../api/logs-api";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {
  Container,
  MenuItem,
  Button,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Loading from "../components/loading";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import PropTypes from "prop-types"; // If using PropTypes
import ReactJson from "react-json-view";

interface LogDetailsProps {
  token: string;
  logs: LogEntry[];
  page?: number;
  rowsPerPage?: number;
  profileId?: number;
  logLevel?: string;
  start?: string;
  end?: string;
  filter?: string;
  limit?: number;
  hours?: number;
  sort?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  table: {
    minWidth: 650,
  },
  container: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  cancelBtn: {
    color: "red",
    borderColor: "red",
  },
}));

const LogComponent = ({ logString }: { logString: string }) => {
  const [expanded, setExpanded] = useState(false);
  const logObject = JSON.parse(logString);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <div>
        <button onClick={toggleExpand}>{expanded ? "-" : "+"}</button>
        {"  "}
        <span>{logObject.msg}</span>
      </div>
      {expanded && (
        <div>
          <ReactJson src={logObject} name={false} collapsed={1} />
        </div>
      )}
    </div>
  );
};

const LogDetails: React.FC<LogDetailsProps> = ({
  token,
  page,
  rowsPerPage,
  profileId,
  logLevel,
  start,
  end,
  filter,
  limit,
  hours,
  sort,
}) => {
  const classes = useStyles();

  const { data } = useLogs(
    token,
    page,
    rowsPerPage,
    profileId,
    logLevel,
    start,
    end,
    filter,
    limit,
    hours,
    sort
  );

  return (
    <>
      {data && data.logs.length === 0 ? (
        <Typography variant="h2" className={classes.subtitle}>
          No logs found.
        </Typography>
      ) : (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell>Level</TableCell>
                <TableCell>Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.logs &&
                data.logs.map((row) => (
                  <TableRow key={row.row}>
                    <TableCell>{row.timestamp}</TableCell>
                    <TableCell>{row.level}</TableCell>
                    <TableCell>
                      <LogComponent logString={row.msg} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

// For PropTypes
LogDetails.propTypes = {
  profileId: PropTypes.number,
  logLevel: PropTypes.oneOf(["warn", "info", "error"]),
  start: PropTypes.string,
  end: PropTypes.string,
  filter: PropTypes.string,
  limit: PropTypes.number,
  hours: PropTypes.number,
  sort: PropTypes.oneOf(["desc", "asc"]),
};

const Logs = () => {
  const classes = useStyles();
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      start: null,
      end: null,
      logLevel: "error",
      limit: 1000,
      profileId: "",
      filter: "",
      hours: 24,
      sort: "desc",
    },
  });

  const [queryParams, setQueryParams] = useState({
    profileId: undefined,
    logLevel: "error",
    start: undefined,
    end: undefined,
    filter: "",
    limit: 1000,
    hours: 24,
    sort: "desc",
    page: 0,
    rowsPerPage: 100,
  });
  const token = useToken(); // Assume useToken is your custom hook for fetching the auth token
  const { data, isLoading, isError } = useLogs(
    token,
    queryParams.page,
    queryParams.rowsPerPage,
    queryParams.profileId,
    queryParams.logLevel,
    queryParams.start || undefined,
    queryParams.end || undefined,
    queryParams.filter,
    queryParams.limit,
    queryParams.hours,
    queryParams.sort
  );

  const formatDate = (date: any) => (date ? date.toISOString() : null);

  const onSubmit = (data: any) => {
    setQueryParams({
      ...queryParams,
      profileId: data.profileId,
      logLevel: data.logLevel,
      start: formatDate(data.start),
      end: formatDate(data.end),
      filter: data.filter,
      limit: data.limit,
      hours: data.hours,
      sort: data.sort,
    });
  };

  return (
    <>
      <Typography variant="h1" className={classes.header}>
        Log Search
      </Typography>

      <Container className={classes.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Controller
                  name="start"
                  control={control}
                  as={
                    <DateTimePicker
                      label="Start Date"
                      onChange={(date) => setValue("start", date)}
                      renderInput={(params) => <TextField {...params} />}
                      value={watch("start")}
                    />
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="end"
                  control={control}
                  as={
                    <DateTimePicker
                      label="End Date"
                      onChange={(date) => setValue("end", date)}
                      renderInput={(params) => <TextField {...params} />}
                      value={watch("end")}
                    />
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="hours"
                  control={control}
                  as={<TextField type="number" label="Hours" />}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="limit"
                  control={control}
                  as={<TextField type="number" label="Limit" />}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="profileId"
                  control={control}
                  as={<TextField type="number" label="Profile ID" />}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="filter"
                  control={control}
                  as={<TextField label="Filter" />}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="logLevel"
                  control={control}
                  as={
                    <TextField select label="Log Level" style={{ width: 200 }}>
                      <MenuItem value="warn">Warn</MenuItem>
                      <MenuItem value="info">Info</MenuItem>
                      <MenuItem value="error">Error</MenuItem>
                    </TextField>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="sort"
                  control={control}
                  as={
                    <TextField select label="Sort Order" style={{ width: 200 }}>
                      <MenuItem value="desc">Descending</MenuItem>
                      <MenuItem value="asc">Ascending</MenuItem>
                    </TextField>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Button type="submit" variant="contained" color="primary">
                  Apply Filters
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </form>
        {isLoading && <Loading />}
        {!isLoading && !isError && data && (
          <LogDetails
            token={token}
            logs={data.logs}
            page={queryParams.page}
            rowsPerPage={queryParams.rowsPerPage}
            profileId={queryParams.profileId}
            logLevel={queryParams.logLevel}
            start={queryParams.start ? queryParams.start : undefined}
            end={queryParams.end ? queryParams.end : undefined}
            filter={queryParams.filter}
            limit={queryParams.limit}
            hours={queryParams.hours}
            sort={queryParams.sort}
          />
        )}
        {isError && <Typography>Error fetching logs.</Typography>}
      </Container>
    </>
  );
};

export default Logs;
