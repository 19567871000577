import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { UploadButton } from "../../components/upload-button";
import Button from "@mui/material/Button";
import { useGenres } from "../../api/genre-api";
import { DateTimePicker } from "@mui/lab";

const getErrorMessage = (key, errors) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};

export const CompletionChallengeForm = ({
  defaultValues,
  imageRequired,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    register,
    errors,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });
  const history = useHistory();
  const genreQuery = useGenres();

  if (genreQuery.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(async (values) => {
        return onSubmit(values);
      })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name={"name"}
            rules={{ required: true }}
            control={control}
            render={(props) => {
              return (
                <TextField
                  required
                  fullWidth
                  name={"name"}
                  label={"Name"}
                  helperText={getErrorMessage("name", errors)}
                  error={!!errors.name}
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"description"}
            control={control}
            render={(props) => {
              return (
                <TextField
                  fullWidth
                  name={"description"}
                  multiline
                  onChange={(value) => {
                    props.onChange(value.target.value);
                  }}
                  value={props.value}
                  label={"Description"}
                  rows={3}
                />
              );
            }}
          />
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={3}>
            <Controller
              name={"deviceType"}
              control={control}
              rules={{ required: true }}
              render={(props) => {
                return (
                  <TextField
                    required
                    fullWidth
                    select
                    name={"deviceType"}
                    label="Device Type"
                    helperText={getErrorMessage("deviceType", errors)}
                    error={!!errors.deviceType}
                    onChange={(value) => {
                      props.onChange(value.target.value);
                    }}
                    value={props.value}
                  >
                    <MenuItem value={"VR"}>VR</MenuItem>
                    <MenuItem value={"LB"}>LB</MenuItem>
                    <MenuItem value={"GO"}>GO</MenuItem>
                  </TextField>
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={3}>
            <Controller
              name={"startAt"}
              control={control}
              render={(props) => {
                return (
                  <DateTimePicker
                    label="Start At"
                    value={props.value}
                    onChange={(newValue) => {
                      props.onChange(newValue);
                    }}
                    id="start-date-picker"
                    renderInput={(inputProps) => (
                      <TextField fullWidth {...inputProps} />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={"endAt"}
              control={control}
              render={(props) => {
                return (
                  <DateTimePicker
                    label="End At"
                    value={props.value}
                    onChange={(newValue) => {
                      props.onChange(newValue);
                    }}
                    id="end-date-picker"
                    renderInput={(inputProps) => (
                      <TextField fullWidth {...inputProps} />
                    )}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <UploadButton
            name="overviewImage"
            label={"Overview Image"}
            value={getValues("overviewImage")}
            error={getErrorMessage("overviewImage", errors)}
            ref={register({ required: imageRequired })}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadButton
            name="detailImage"
            label={"Detail Image"}
            value={getValues("detailImage")}
            error={getErrorMessage("detailImage", errors)}
            ref={register({ required: imageRequired })}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name={"genre"}
                control={control}
                render={(props) => {
                  return (
                    <TextField
                      fullWidth
                      select
                      name={"genre"}
                      label="Genre"
                      helperText={getErrorMessage("genre", errors)}
                      error={!!errors.deviceType}
                      onChange={(value) => {
                        props.onChange(value.target.value);
                      }}
                      value={props.value}
                    >
                      <MenuItem value={""}>None</MenuItem>
                      {genreQuery.data?.map((genre) => {
                        return (
                          <MenuItem value={genre.name} key={genre.name}>
                            {genre.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={3}>
            <CheckboxField
              label={"Group Challenge"}
              name={"groupChallenge"}
              control={control}
              required={true}
            />
          </Grid>
          <Grid item xs={3}>
            <CheckboxField
              label={"Show Progress as Percent"}
              name={"showProgressAsPercent"}
              control={control}
              required={true}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <CheckboxField
              label={"Include Quickplay"}
              name={"includeQuickplay"}
              control={control}
              required={true}
            />
          </Grid>
          <Grid item xs={3}>
            <CheckboxField
              label={"Include Trainer"}
              name={"includeTrainer"}
              control={control}
              required={true}
            />
          </Grid>
          <Grid item xs={3}>
            <CheckboxField
              label={"Include Sparring"}
              name={"includeSparring"}
              control={control}
              required={true}
            />
          </Grid>
          <Grid item xs={3}>
            <CheckboxField
              label={"Include Freestyle"}
              name={"includeFreestyle"}
              control={control}
              required={true}
            />
          </Grid>
          <Grid item xs={3}>
            <CheckboxField
              label={"Include Strength"}
              name={"includeStrength"}
              control={control}
              required={true}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Total Workout Time"}
              name={"totalWorkoutTime"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Total Workout Count"}
              name={"totalWorkoutCount"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Total Workout Days"}
              name={"totalWorkoutDays"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Total Punches Thrown"}
              name={"totalPunchesThrown"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Total Punches Hit"}
              name={"totalPunchesHit"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Total Calories Burned"}
              name={"totalCaloriesBurned"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Min Per Workout Score"}
              name={"minPerWorkoutScore"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Min Per Workout Time"}
              name={"minPerWorkoutTime"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Max Per Workout Time"}
              name={"maxPerWorkoutTime"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Min Per Workout Complete %"}
              name={"minPerWorkoutPercentComplete"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Min Quickplay Count"}
              name={"minQuickplayCount"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Min Trainer Count"}
              name={"minTrainerCount"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Min Sparring Count"}
              name={"minSparringCount"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Min Freestyle Count"}
              name={"minFreestyleCount"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberField
              control={control}
              label={"Min Strength Count"}
              name={"minStrengthCount"}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <Button
          variant="contained"
          type={"submit"}
          sx={{ mr: 2 }}
          disabled={isSubmitting}
        >
          Submit
        </Button>
        <Button
          disabled={isSubmitting}
          variant="text"
          onClick={() => {
            history.push("/challenges");
          }}
        >
          Cancel
        </Button>
      </Grid>
    </form>
  );
};

const CheckboxField = ({ control, name, required, label }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={(props) => (
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              onChange={(e) => props.onChange(e.target.checked)}
              checked={props.value}
            />
          }
        />
      )}
    />
  );
};

export const NumberField = ({ control, name, required, label, errors }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={(props) => (
        <TextField
          fullWidth
          name={name}
          label={label}
          helperText={getErrorMessage(name, errors)}
          error={!!errors[name]}
          onChange={(value) => {
            props.onChange(value.target.value);
          }}
          value={props.value}
          type="number"
        />
      )}
    />
  );
};

export const HookTextField = ({ control, name, required, label, errors }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={(props) => (
        <TextField
          fullWidth
          name={name}
          label={label}
          helperText={getErrorMessage(name, errors)}
          error={!!errors[name]}
          onChange={(value) => {
            props.onChange(value.target.value);
          }}
          value={props.value}
        />
      )}
    />
  );
};
