import { Container, Typography } from "@mui/material";
import { PlaylistForm } from "./playlists-form";
import * as React from "react";
import * as playlistsApi from "../../api/playlists-api";
import { useParams, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Playlist } from "../../api/playlists-api";

export const EditPlaylist = () => {
  const { id } = useParams<{ id: string }>();
  const getAccessTokenSilently = useAuth0().getAccessTokenSilently;
  const playlistQuery = playlistsApi.usePlaylist(id);
  const history = useHistory();
  if (playlistQuery.isLoading) {
    return <div>loading...</div>;
  }
  const playlist = playlistQuery.data;
  return (
    <Container className="mb-5">
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Edit Playlist
      </Typography>
      <div>
        <Typography variant="h6" gutterBottom component="div">
          Image
        </Typography>
        <img src={playlist.imageUrl} alt={""} />
      </div>
      <PlaylistForm
        imagesRequired={false}
        onSubmit={async (playlist: Playlist, image: string) => {
          const token = await getAccessTokenSilently();
          await playlistsApi.updatePlaylist({
            token,
            id,
            playlist,
          });
          playlistQuery.refetch();
          history.push("/playlists");
        }}
        defaultValues={{
          id: id,
          name: playlist.name ? playlist.name : "",
          isPublic: playlist.isPublic ? playlist.isPublic : false,
          isFavorites: playlist.isFavorites ? playlist.isFavorites : false,
          brandId: playlist.brandId !== null ? playlist.brandId : "",
          isProgram: playlist.isProgram !== null ? playlist.isProgram : false,
          description:
            playlist.description !== null ? playlist.description : "",
          content: playlist.content !== null ? playlist.content : [],
          imageUrl: playlist.imageUrl !== null ? playlist.imageUrl : "",
          weeksToComplete:
            playlist.weeksToComplete !== null ? playlist.weeksToComplete : "",
          recommendedClassesPerWeek:
            playlist.recommendedClassesPerWeek !== null
              ? playlist.recommendedClassesPerWeek
              : "",
          minutesPerDay:
            playlist.minutesPerDay !== null ? playlist.minutesPerDay : "",
          brand: playlist.brand ? playlist.brand : "",
        }}
      />
    </Container>
  );
};
