import * as React from "react";
import DeviceList from "../components/device-list";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import DeviceWorkoutList from "../components/device-details";

const DeviceIndex = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:serialNumber`}>
        <DeviceWorkoutList />
      </Route>
      <Route path={match.path}>
        <DeviceList />
      </Route>
    </Switch>
  );
};

export default DeviceIndex;
