const artistsString = (artists) => {
  if (!artists) {
    return "";
  }
  const mainArtists = artists
    .filter((a) => {
      return a.type === "MainArtist";
    })
    .map((artist) => {
      if (artist.namesBeforeKeyName) {
        return `${artist.namesBeforeKeyName} ${artist.keyName}`;
      } else {
        return `${artist.keyName}`;
      }
    })
    .join(", ");
  const secondary = artists
    .filter((a) => {
      return a.type !== "MainArtist";
    })
    .map((artist) => {
      if (artist.namesBeforeKeyName) {
        return `${artist.namesBeforeKeyName} ${artist.keyName}`;
      } else {
        return `${artist.keyName}`;
      }
    });
  if (secondary.length !== 0) {
    return `${mainArtists} Feat. ${secondary.join(", ")}`;
  } else {
    return mainArtists;
  }
};

export default artistsString;
