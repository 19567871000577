import * as React from "react";
import * as umgApi from "../api/umg-api";
import { useAuth0 } from "@auth0/auth0-react";
import { usePaginatedQuery, useQueryCache } from "react-query";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Button,
  Grid,
  Snackbar,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import { ColorButton } from "../components";
import { Alert } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import IconButton from "@mui/material/IconButton";
import { useProfile } from "../auth-provider";

const headCells = [
  { id: "isrc", numeric: false, disablePadding: false, label: "isrc" },
  {
    id: "displayTitle",
    numeric: true,
    disablePadding: false,
    label: "Display Name",
  },
  { id: "genres", numeric: true, disablePadding: false, label: "Genres" },
  { id: "warning", numeric: true, disablePadding: false, label: "Warning" },
  { id: "artist", numeric: true, disablePadding: false, label: "Artist" },
  { id: "title", numeric: false, disablePadding: false, label: "Title" },
  {
    id: "downloadTrack",
    numeric: false,
    disablePadding: false,
    label: "Download Track",
  },
  {
    id: "downloadCover",
    numeric: false,
    disablePadding: false,
    label: "Download Cover",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const artistsString = (artists) => {
  if (!artists) {
    return "";
  }
  const mainArtists = artists
    .filter((a) => {
      return a.type === "MainArtist";
    })
    .map((artist) => {
      if (artist.namesBeforeKeyName) {
        return `${artist.namesBeforeKeyName} ${artist.keyName}`;
      } else {
        return `${artist.keyName}`;
      }
    })
    .join(", ");
  const secondary = artists
    .filter((a) => {
      return a.type !== "MainArtist";
    })
    .map((artist) => {
      if (artist.namesBeforeKeyName) {
        return `${artist.namesBeforeKeyName} ${artist.keyName}`;
      } else {
        return `${artist.keyName}`;
      }
    });
  if (secondary.length !== 0) {
    return `${mainArtists} Feat. ${secondary.join(", ")}`;
  } else {
    return mainArtists;
  }
};

const titleString = (song) => {
  if (song.title && song.subtitle) {
    return `${song.title} - ${song.subtitle}`;
  } else if (song.title) {
    return song.title;
  } else if (song.subtitle) {
    return song.subtitle;
  } else {
    return "";
  }
};

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const UMGIndex = () => {
  const profile = useProfile();
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [displayNameSearch, setDisplayNameSearch] = React.useState("");
  const [artistNameSearch, setArtistNameSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const queryCache = useQueryCache();
  const { resolvedData = { total: 0, content: [] } } = usePaginatedQuery(
    ["umg-songs", { displayNameSearch, artistNameSearch, page, rowsPerPage }],
    async () => {
      const token = await getAccessTokenSilently({
        audience: `https://api.liteboxer.com`,
      });
      return umgApi.findAllUmgSongs({
        token,
        displayName: displayNameSearch,
        artistName: artistNameSearch,
        page,
        rowsPerPage,
      });
    }
  );
  const [order, setOrder] = React.useState("asc");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [ingestionStatus, setIngestionStatus] = React.useState(200);
  const [orderBy, setOrderBy] = React.useState("calories");
  const [ingestionLoading, setIngestionLoading] = React.useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <Container className="mb-5">
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={6000}
          onClose={() => {
            setSnackBarOpen(false);
          }}
        >
          <Alert
            onClose={() => {
              setSnackBarOpen(false);
            }}
            severity={ingestionStatus === 200 ? "success" : "error"}
          >
            {ingestionStatus === 200
              ? "Ingestion successfully"
              : "Ingestion Failed!"}
          </Alert>
        </Snackbar>
        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h1">UMG Content</Typography>
          </Grid>
          {profile.role !== "LITEBOXER_CONTENT_AUTHOR" ? (
            <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
              <ColorButton
                disabled={ingestionLoading}
                color="green"
                onClick={async () => {
                  const token = await getAccessTokenSilently({
                    audience: `https://api.liteboxer.com`,
                  });
                  setIngestionLoading(true);
                  try {
                    const status = await umgApi.runIngestion({ token });
                    setIngestionStatus(status);
                    setSnackBarOpen(true);
                    if (status === 200) {
                      queryCache.invalidateQueries("umg-songs");
                    }
                    setIngestionLoading(false);
                  } catch (e) {
                    setIngestionLoading(false);
                  }
                }}
                variant="contained"
              >
                {ingestionLoading ? "Ingestion Running..." : "Run Ingestion"}
              </ColorButton>
            </Grid>
          ) : null}
        </Grid>
        <Paper>
          <form
            style={{ marginLeft: 4 }}
            noValidate
            autoComplete="off"
            onSubmit={(event) => {
              event.preventDefault();
              const { displayName, artistName } = event.target.elements;
              setDisplayNameSearch(displayName.value);
              setArtistNameSearch(artistName.value);
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField id="displayName" label="Display Name" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="artistName" label="Artist Name" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="secondary" type="submit">
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>
          <TableContainer>
            <Table aria-label="a dense table">
              <EnhancedTableHead
                order={order}
                classes={classes}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={resolvedData.total}
              />
              <TableBody>
                {stableSort(
                  resolvedData.content,
                  getComparator(order, orderBy)
                ).map((row) => (
                  <TableRow key={row.isrc}>
                    <TableCell component="th" scope="row">
                      {row.isrc}
                    </TableCell>
                    <TableCell>{row.displayTitle}</TableCell>
                    <TableCell>{row.genres?.join(", ")}</TableCell>
                    <TableCell>{row.parentalWarningType}</TableCell>
                    <TableCell>
                      <div>{artistsString(row.artists)}</div>
                    </TableCell>
                    <TableCell>
                      <div>{titleString(row)}</div>
                    </TableCell>
                    <TableCell>
                      <DownloadTrackButton songId={row.id} />
                    </TableCell>
                    <TableCell>
                      <DownloadCoverButton songId={row.id} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            component="div"
            count={resolvedData.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
    </>
  );
};

const DownloadCoverButton = ({ songId }) => {
  const [signedUrl, setSignedUrl] = React.useState(undefined);
  const { getAccessTokenSilently } = useAuth0();
  if (signedUrl === undefined) {
    return (
      <IconButton
        onClick={async () => {
          const token = await getAccessTokenSilently({
            audience: `https://api.liteboxer.com`,
          });
          const response = await umgApi.findCoverUrl({ token, songId });
          setSignedUrl(response.url);
        }}
        size="large"
      >
        <GetAppIcon />
      </IconButton>
    );
  } else if (signedUrl === "") {
    return <span>No cover image</span>;
  } else {
    return (
      <IconButton
        download
        href={signedUrl}
        onClick={() => {
          setSignedUrl(undefined);
        }}
        size="large"
      >
        <CloudDownloadIcon />
      </IconButton>
    );
  }
};
const DownloadTrackButton = ({ songId }) => {
  const [signedUrl, setSignedUrl] = React.useState(undefined);
  const { getAccessTokenSilently } = useAuth0();
  if (signedUrl === undefined) {
    return (
      <IconButton
        onClick={async () => {
          const token = await getAccessTokenSilently({
            audience: `https://api.liteboxer.com`,
          });
          const response = await umgApi.findSignedUrl({ token, songId });
          setSignedUrl(response.url);
        }}
        size="large"
      >
        <GetAppIcon />
      </IconButton>
    );
  } else {
    return (
      <IconButton
        download
        href={signedUrl}
        onClick={() => {
          setSignedUrl(undefined);
        }}
        size="large"
      >
        <CloudDownloadIcon />
      </IconButton>
    );
  }
};

export default UMGIndex;
