import { baseUrl } from "../utils/http";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";

type FindContentBundleProps = {
  token: string;
};

export const findContentBundles = async ({ token }: FindContentBundleProps) => {
  const response = await fetch(`${baseUrl}/content/bundles`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  if (!(response.status === 200)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

export const useContentBundles = () => {
  const auth0 = useAuth0();
  return useQuery(["content-bundles", "all"], async () => {
    const token = (await auth0.getAccessTokenSilently()) as string;
    return findContentBundles({ token });
  });
};

type CreateContentBundleProps = {
  token: string;
  bundleSku: string;
  contentIds: string;
  title: string;
  image: FileList;
  description: string;
  difficulty: string;
  status: string;
};
export const createContentBundle = async ({
  token,
  bundleSku,
  contentIds,
  title,
  image,
  description,
  difficulty,
  status,
}: CreateContentBundleProps) => {
  const formData = new FormData();
  if (bundleSku) {
    formData.append("bundleSku", bundleSku);
  }
  if (contentIds) {
    formData.append("contentIds", contentIds);
  }
  if (title) {
    formData.append("title", title);
  }
  if (image.length > 0) {
    formData.append("image", image[0]);
  }
  if (description) {
    formData.append("description", description);
  }
  if (difficulty) {
    formData.append("difficulty", difficulty);
  }
  if (status) {
    formData.append("status", status);
  }

  const response = await fetch(`${baseUrl}/content/bundles`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    var j = await response.json();
    var e = new Error();
    e.message = j;
    throw e;
  }
  return await response.json();
};

type UpdateContentBundleProps = CreateContentBundleProps & {
  id: number;
};
export const updateContentBundle = async ({
  token,
  bundleSku,
  contentIds,
  title,
  image,
  description,
  difficulty,
  status,
  id,
}: UpdateContentBundleProps) => {
  const formData = new FormData();
  if (bundleSku) {
    formData.append("bundleSku", bundleSku);
  }
  if (contentIds) {
    formData.append("contentIds", contentIds);
  }
  if (title) {
    formData.append("title", title);
  }
  if (image.length > 0) {
    formData.append("image", image[0]);
  }
  if (description) {
    formData.append("description", description);
  }
  if (difficulty) {
    formData.append("difficulty", difficulty);
  }
  if (status) {
    formData.append("status", status);
  }

  const response = await fetch(`${baseUrl}/content/bundles/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    var j = await response.json();
    var e = new Error();
    e.message = j;
    throw e;
  }
  return await response.json();
};
