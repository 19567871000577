import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

type useFetchProps<T> = {
  data: T | undefined;
  error: string | undefined;
};

export const useFetch = <T>(apiPath: string): useFetchProps<T> => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!apiPath) return;
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;

      try {
        const token = await getAccessTokenSilently({
          audience: `https://api.liteboxer.com`,
        });

        const response = await fetch(`${apiUrl}${apiPath}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const responseData = await response.json();

        if (responseData.status && responseData.status === "Unauthorized") {
          throw new Error("Unauthorized");
        }

        setData(responseData);
        setError(undefined);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        }
      }
    };

    fetchData();
  }, [apiPath, getAccessTokenSilently]);

  return { data, error };
};

function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (_e) => resolve(reader.result as string);
    reader.onerror = (_e) => reject(reader.error);
    reader.onabort = (_e) => reject(new Error("Read aborted"));
    reader.readAsDataURL(blob);
  });
}

export const useFetchDataUrl = (apiPath: string): useFetchProps<string> => {
  const [data, setData] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!apiPath) return;
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;

      try {
        const token = await getAccessTokenSilently({
          audience: `https://api.liteboxer.com`,
        });

        const response = await fetch(`${apiUrl}${apiPath}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const responseBlob = await response.blob();
        const dataUrl = await blobToDataURL(responseBlob);

        setData(dataUrl);
        setError(undefined);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        }
      }
    };

    fetchData();
  }, [apiPath, getAccessTokenSilently]);

  return { data, error };
};
