import Container from "@mui/material/Container";
import { DataGrid } from "@mui/x-data-grid";
import { findFlaggedUserPictures, unflagPicture } from "../api/user-api";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { set as setCookie, get as getCookie } from "es-cookie";
import { Button } from "@mui/material";
import { useToken } from "../token-provider";

const LB_CONSENT_COOKIE = "lb_view_user_profile_images";

const useStyles = makeStyles((theme) => ({
  TableRow: {
    verticalAlign: "center",
    "& *": {
      whiteSpace: "normal",
    },
  },
  ImageBlurred: {
    filter: "blur(15px)",
  },
  ProfileImage: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  UnflagContainer: {
    position: "relative",
  },
  UnflagButton: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    textAlign: "center",
  },
}));

const useFlaggedImages = () => {
  const auth0 = useAuth0();
  return useQuery(["user-flagged-images", "all"], async () => {
    const token = await auth0.getAccessTokenSilently();
    return findFlaggedUserPictures({ token });
  });
};

const highlightRowScores = (score) =>
  score === "LIKELY" || score === "VERY_LIKELY" ? (
    <strong style={{ color: "red" }}>{score}</strong>
  ) : (
    score
  );

const UserProfileImage = ({ imageUrl, profileId, refetch, isCurrentImage }) => {
  const classes = useStyles();
  const [isBlurred, setBlurred] = React.useState(true);
  const checkForImageConsent = (_) => {
    if (getCookie(LB_CONSENT_COOKIE) === "YES") {
      setBlurred(!isBlurred);
    } else {
      if (
        window.confirm(
          "Do you want to view flagged user profile images? They may be NSFW or offensive."
        )
      ) {
        setCookie(LB_CONSENT_COOKIE, "YES");
        setBlurred(!isBlurred);
      }
    }
  };
  const token = useToken();

  return (
    <div
      title={imageUrl}
      className={`${classes.TableRow} ${classes.UnflagContainer}`}
    >
      <img
        style={{ objectFit: "cover" }}
        className={`${isBlurred ? classes.ImageBlurred : ""} ${
          classes.ProfileImage
        }`}
        src={imageUrl}
        alt="Avatar"
        onClick={checkForImageConsent}
      />
      {!isBlurred && isCurrentImage && (
        <Button
          className={classes.UnflagButton}
          variant="contained"
          color="primary"
          onClick={() => {
            unflagPicture({ token, imageUrl, profileId }).then((response) => {
              refetch();
              if (!response.ok) {
                window.alert(
                  "Error unflagging the image in question; the user may have uploaded a new one."
                );
              }
            });
          }}
        >
          Unflag Image
        </Button>
      )}
    </div>
  );
};

export const UserProfileImages = () => {
  const { data, refetch } = useFlaggedImages();
  const classes = useStyles();
  const columns = [
    {
      field: "display_name",
      headerName: "Username",
      flex: 1,
      renderCell: ({ value, row }) => {
        return (
          <div title={value} className={classes.TableRow}>
            <RouterLink to={`/users/${row.user_id}/details`}>
              {value}
            </RouterLink>
            <p>{new Date(row.time).toLocaleString("en-US")}</p>
          </div>
        );
      },
    },
    {
      field: "racy_score",
      headerName: "Metadata",
      flex: 1,
      renderCell: ({ value, row }) => {
        return (
          <div title="Google Scores" className={classes.TableRow}>
            {value === "" ? (
              <>
                <div>
                  <strong>Google Scores</strong>
                </div>
                <p>
                  No Google info. <br />
                  (This image was manually moderated.)
                </p>
              </>
            ) : (
              <>
                <div>
                  <strong>Google Scores</strong>
                </div>
                <br />
                <code>
                  Racy:
                  <br />
                  {highlightRowScores(row.racy_score)}
                  <br />
                  Adult:
                  <br />
                  {highlightRowScores(row.adult_score)}
                  <br />
                  Violence:
                  <br />
                  {highlightRowScores(row.violence_score)}
                  <br />
                  Medical:
                  <br />
                  {highlightRowScores(row.medical_score)}
                  <br />
                  Spoof:
                  <br />
                  {row.spoof_score}
                </code>
              </>
            )}
          </div>
        );
      },
    },
    {
      field: "is_current_profile_image",
      headerName: "Current Image?",
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <div title={value.toString()} className={classes.TableRow}>
            {value ? "✅" : "🚫"}
          </div>
        );
      },
    },
    {
      field: "image_url",
      headerName: "Image",
      flex: 5,
      renderCell: ({ value, row }) => {
        return (
          <UserProfileImage
            imageUrl={value}
            profileId={row.user_id}
            refetch={refetch}
            isCurrentImage={row.is_current_profile_image}
          />
        );
      },
    },
  ];

  return (
    <Container className="mb-5">
      {" "}
      <div style={{ width: "100%", minHeight: 400 }}>
        <DataGrid
          getRowId={(row) => {
            return row.id;
          }}
          rows={data}
          columns={columns}
          pageSize={10}
          rowHeight={400}
          autoHeight
          disableSelectionOnClick
        />
      </div>
    </Container>
  );
};
