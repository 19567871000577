import { baseUrl } from "../utils/http";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";

type FindRoundChallengesProps = {
  token: string;
};

export const findRoundChallenges = async ({
  token,
}: FindRoundChallengesProps) => {
  const response = await fetch(`${baseUrl}/challenges/community`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  if (!(response.status === 200)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

export const useCommunityChallenges = () => {
  const auth0 = useAuth0();
  return useQuery(["community-challenges", "all"], async () => {
    const token = (await auth0.getAccessTokenSilently()) as string;
    return findRoundChallenges({ token });
  });
};

// @TODO: Replace with a real type
type Content = any;

type CreateRoundChallengeProps = {
  token: string;
  deviceType: string;
  startAt: Date;
  endAt: Date;
  name: string;
  description: string;
  overviewImage: FileList;
  detailImage: FileList;
  content: Content[];
};
export const createRoundChallenge = async ({
  token,
  deviceType,
  startAt,
  endAt,
  name,
  description,
  overviewImage,
  detailImage,
  content,
}: CreateRoundChallengeProps) => {
  const formData = new FormData();
  formData.append("challengeTypeId", "1");
  formData.append("overviewImage", overviewImage[0]);
  formData.append("detailImage", detailImage[0]);
  formData.append("deviceType", deviceType);
  if (startAt) {
    formData.append("startAt", startAt.toISOString());
  }
  if (endAt) {
    formData.append("endAt", endAt.toISOString());
  }

  formData.append("name", name);
  formData.append("description", description);
  formData.append(
    "content",
    JSON.stringify(
      content.map((c) => {
        return { id: c.id };
      })
    )
  );
  const response = await fetch(`${baseUrl}/challenges/community`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

type UpdateRoundChallengeProps = CreateCompletionChallengeProps & {
  id: number;
};
export const updateRoundChallenge = async ({
  token,
  deviceType,
  startAt,
  endAt,
  name,
  description,
  overviewImage,
  detailImage,
  content,
  id,
}: UpdateRoundChallengeProps) => {
  const formData = new FormData();
  formData.append("challengeTypeId", "1");
  if (overviewImage.length > 0) {
    formData.append("overviewImage", overviewImage[0]);
  }
  if (detailImage.length > 0) {
    formData.append("detailImage", detailImage[0]);
  }
  formData.append("deviceType", deviceType);
  if (startAt) {
    formData.append("startAt", startAt.toISOString());
  }
  if (endAt) {
    formData.append("endAt", endAt.toISOString());
  }

  formData.append("name", name);
  formData.append("description", description);
  formData.append(
    "content",
    JSON.stringify(
      content.map((c) => {
        return { id: c.id };
      })
    )
  );
  const response = await fetch(`${baseUrl}/challenges/community/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

type CreateCompletionChallengeProps = {
  token: string;
  deviceType: string;
  startAt: Date;
  endAt: Date;
  name: string;
  description: string;
  overviewImage: FileList;
  detailImage: FileList;
  content: Content[];
  id: number;
  genre: string;
  groupChallenge: boolean;
  includeQuickplay: boolean;
  includeTrainer: boolean;
  maxPerWorkoutTime: string;
  includeSparring: boolean;
  includeFreestyle: boolean;
  includeStrength: boolean;
  showProgressAsPercent: boolean;
  totalWorkoutTime: number;
  totalWorkoutCount: number;
  totalWorkoutDays: number;
  totalPunchesThrown: number;
  totalPunchesHit: number;
  totalCaloriesBurned: number;
  minPerWorkoutScore: number;
  minPerWorkoutTime: number;
  minPerWorkoutPercentComplete: number;
  minQuickplayCount: number;
  minTrainerCount: number;
  minSparringCount: number;
  minFreestyleCount: number;
  minStrengthCount: number;
};
export const createCompletionChallenge = async ({
  token,
  deviceType,
  startAt,
  endAt,
  name,
  description,
  overviewImage,
  detailImage,
  genre,
  groupChallenge,
  includeQuickplay,
  includeTrainer,
  maxPerWorkoutTime,
  includeSparring,
  includeFreestyle,
  includeStrength,
  showProgressAsPercent,
  totalWorkoutTime,
  totalWorkoutCount,
  totalWorkoutDays,
  totalPunchesThrown,
  totalPunchesHit,
  totalCaloriesBurned,
  minPerWorkoutScore,
  minPerWorkoutTime,
  minPerWorkoutPercentComplete,
  minQuickplayCount,
  minTrainerCount,
  minSparringCount,
  minFreestyleCount,
  minStrengthCount,
}: CreateCompletionChallengeProps) => {
  const formData = new FormData();
  formData.append("challengeTypeId", "2");
  formData.append("overviewImage", overviewImage[0]);
  formData.append("detailImage", detailImage[0]);
  formData.append("deviceType", deviceType);
  if (startAt) {
    formData.append("startAt", startAt.toISOString());
  }
  if (endAt) {
    formData.append("endAt", endAt.toISOString());
  }

  formData.append("name", name);
  formData.append("description", description);
  if (genre) {
    formData.append("genre", genre);
  }
  if (groupChallenge) {
    formData.append("groupChallenge", groupChallenge.toString());
  }
  if (includeQuickplay) {
    formData.append("includeQuickplay", "true");
  }
  if (totalWorkoutTime) {
    formData.append("totalWorkoutTime", totalWorkoutTime.toString());
  }
  if (includeTrainer) {
    formData.append("includeTrainer", "true");
  }
  if (maxPerWorkoutTime) {
    formData.append("maxPerWorkoutTime", maxPerWorkoutTime);
  }
  if (includeSparring) {
    formData.append("includeSparring", includeSparring.toString());
  }
  if (includeFreestyle) {
    formData.append("includeFreestyle", includeFreestyle.toString());
  }
  if (includeStrength) {
    formData.append("includeStrength", includeStrength.toString());
  }
  if (showProgressAsPercent) {
    formData.append("showProgressAsPercent", showProgressAsPercent.toString());
  }
  if (totalWorkoutCount) {
    formData.append("totalWorkoutCount", totalWorkoutCount.toString());
  }
  if (totalWorkoutDays) {
    formData.append("totalWorkoutDays", totalWorkoutDays.toString());
  }
  if (totalPunchesThrown) {
    formData.append("totalPunchesThrown", totalPunchesThrown.toString());
  }
  if (totalPunchesHit) {
    formData.append("totalPunchesHit", totalPunchesHit.toString());
  }
  if (totalCaloriesBurned) {
    formData.append("totalCaloriesBurned", totalCaloriesBurned.toString());
  }
  if (minPerWorkoutScore) {
    formData.append("minPerWorkoutScore", minPerWorkoutScore.toString());
  }
  if (minPerWorkoutTime) {
    formData.append("minPerWorkoutTime", minPerWorkoutTime.toString());
  }
  if (minPerWorkoutPercentComplete) {
    formData.append(
      "minPerPercentComplete",
      minPerWorkoutPercentComplete.toString()
    );
  }
  if (minQuickplayCount) {
    formData.append("minQuickplayCount", minQuickplayCount.toString());
  }
  if (minTrainerCount) {
    formData.append("minTrainerCount", minTrainerCount.toString());
  }
  if (minSparringCount) {
    formData.append("minSparringCount", minSparringCount.toString());
  }
  if (minFreestyleCount) {
    formData.append("minFreestyleCount", minFreestyleCount.toString());
  }
  if (minStrengthCount) {
    formData.append("minStrengthCount", minStrengthCount.toString());
  }
  const response = await fetch(`${baseUrl}/challenges/community/competition`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (!(response.status === 200 || response.status === 201)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};

type UpdateCompletionChallengeProps = CreateCompletionChallengeProps & {
  id: number;
};
export const updateCompletionChallenge = async ({
  token,
  deviceType,
  startAt,
  endAt,
  name,
  description,
  overviewImage,
  detailImage,
  genre,
  groupChallenge,
  includeQuickplay,
  includeTrainer,
  maxPerWorkoutTime,
  includeSparring,
  includeFreestyle,
  includeStrength,
  showProgressAsPercent,
  totalWorkoutTime,
  totalWorkoutCount,
  totalWorkoutDays,
  totalPunchesThrown,
  totalPunchesHit,
  totalCaloriesBurned,
  minPerWorkoutScore,
  minPerWorkoutTime,
  minPerWorkoutPercentComplete,
  minQuickplayCount,
  minTrainerCount,
  minSparringCount,
  minFreestyleCount,
  minStrengthCount,
  id,
}: UpdateCompletionChallengeProps) => {
  const formData = new FormData();
  formData.append("challengeTypeId", "2");
  if (overviewImage.length > 0) {
    formData.append("overviewImage", overviewImage[0]);
  }
  if (detailImage.length > 0) {
    formData.append("detailImage", detailImage[0]);
  }
  formData.append("deviceType", deviceType);
  if (startAt) {
    formData.append("startAt", startAt.toISOString());
  }
  if (endAt) {
    formData.append("endAt", endAt.toISOString());
  }

  formData.append("name", name);
  formData.append("description", description);
  if (genre) {
    formData.append("genre", genre);
  }
  if (groupChallenge) {
    formData.append("groupChallenge", groupChallenge.toString());
  }
  if (includeQuickplay) {
    formData.append("includeQuickplay", "true");
  }
  if (totalWorkoutTime) {
    formData.append("totalWorkoutTime", totalWorkoutTime.toString());
  }
  if (includeTrainer) {
    formData.append("includeTrainer", "true");
  }
  if (maxPerWorkoutTime) {
    formData.append("maxPerWorkoutTime", maxPerWorkoutTime.toString());
  }
  if (includeSparring) {
    formData.append("includeSparring", includeSparring.toString());
  }
  if (includeFreestyle) {
    formData.append("includeFreestyle", includeFreestyle.toString());
  }
  if (includeStrength) {
    formData.append("includeStrength", includeStrength.toString());
  }
  if (showProgressAsPercent) {
    formData.append("showProgressAsPercent", showProgressAsPercent.toString());
  }
  if (totalWorkoutCount) {
    formData.append("totalWorkoutCount", totalWorkoutCount.toString());
  }
  if (totalWorkoutDays) {
    formData.append("totalWorkoutDays", totalWorkoutDays.toString());
  }
  if (totalPunchesThrown) {
    formData.append("totalPunchesThrown", totalPunchesThrown.toString());
  }
  if (totalPunchesHit) {
    formData.append("totalPunchesHit", totalPunchesHit.toString());
  }
  if (totalCaloriesBurned) {
    formData.append("totalCaloriesBurned", totalCaloriesBurned.toString());
  }
  if (minPerWorkoutScore) {
    formData.append("minPerWorkoutScore", minPerWorkoutScore.toString());
  }
  if (minPerWorkoutTime) {
    formData.append("minPerWorkoutTime", minPerWorkoutTime.toString());
  }
  if (minPerWorkoutPercentComplete) {
    formData.append(
      "minPerPercentComplete",
      minPerWorkoutPercentComplete.toString()
    );
  }
  if (minQuickplayCount) {
    formData.append("minQuickplayCount", minQuickplayCount.toString());
  }
  if (minTrainerCount) {
    formData.append("minTrainerCount", minTrainerCount.toString());
  }
  if (minSparringCount) {
    formData.append("minSparringCount", minSparringCount.toString());
  }
  if (minFreestyleCount) {
    formData.append("minFreestyleCount", minFreestyleCount.toString());
  }
  if (minStrengthCount) {
    formData.append("minStrengthCount", minStrengthCount.toString());
  }
  const response = await fetch(
    `${baseUrl}/challenges/community/competition/${id}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  );
  if (!(response.status === 200 || response.status === 201)) {
    throw new Error("Error fetching data");
  }
  return await response.json();
};
