import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Switch as MuiSwitch,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Link,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  useParams,
} from "react-router-dom";

import {
  ColorButton,
  Loading,
  ReactHookFormCheckbox,
  ReactHookFormSelect,
  SearchTable,
  SnackbarMessage,
  statusChip,
} from "../components";
import { useFetch } from "../utils";
import { secondsToMMSS } from "../utils/formatters";
import * as quickplayApi from "../api/quickplayApi";
import QuickplayForm from "../components/quickplay-form";
import { useToken } from "../token-provider";
import { useQuery, useQueryCache } from "react-query";
import UmgSongSelectControl from "../components/umg-song-select-dialog";
import QuickplayPlaysPerDayOnApp from "../components/quickplay-plays-per-day-on-app";
import * as genreApi from "../api/genre-api";
import CreateGenreDialog from "../components/create-genre-dialog";
import { DateTimePicker } from "@mui/lab";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Brand } from "../app";
import { useEffect } from "react";

const getErrorMessage = (key, errors) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};

const tableHeadings = [
  {
    id: "imageUrl",
    numeric: false,
    disablePadding: true,
    label: "",
    format: (value) => {
      return (
        <img
          key={value}
          src={`${value}?height=36`}
          height="36px"
          width="36px"
          alt=""
        />
      );
    },
  },
  {
    id: "displayName",
    numeric: false,
    disablePadding: true,
    label: "Track Name",
  },
  {
    id: "artist",
    numeric: false,
    disablePadding: true,
    label: "Artist",
    format: (value) => {
      return value.name;
    },
    widescreenOnly: true,
  },
  {
    id: "lengthInSeconds",
    numeric: true,
    disablePadding: false,
    label: "Length",
    format: (value) => {
      return secondsToMMSS(value);
    },
    widescreenOnly: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    format: (value) => {
      return statusChip(value);
    },
  },
  {
    id: "isPremium",
    numeric: false,
    disablePadding: true,
    label: "Premium",
    format: (value) => {
      return value && statusChip("PREMIUM");
    },
  },
  {
    id: "isStandard",
    numeric: false,
    disablePadding: true,
    label: "Standard",
    format: (value) => {
      return value && statusChip("STANDARD");
    },
  },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
  })
);

const UploadButton = React.forwardRef(
  ({ label, accept = "image/*", value, name }, ref) => {
    const classes = useStyles();
    let selectedFile = " No file selected";
    if (value && value.length > 0) {
      selectedFile = " " + value[0].name;
    }
    return (
      <React.Fragment>
        <div>
          <input
            accept={accept}
            className={classes.input}
            id={name}
            type="file"
            name={name}
            ref={ref}
          />
          <label htmlFor={name}>
            <Button variant="contained" color="primary" component="span">
              {label}
            </Button>
            <span>{selectedFile}</span>
          </label>
        </div>
      </React.Fragment>
    );
  }
);

const QuickplayList = withAuth0((props) => {
  const { data, error } = useFetch(`/content/song`);
  if (!data && !error) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Container className="mb-5">
        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h1">Punch Tracks</Typography>
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
            <ColorButton
              color="green"
              component={Link}
              to={"/quickplay/create"}
              variant="contained"
            >
              Add New{" "}
            </ColorButton>
          </Grid>
        </Grid>
        {data && (
          <SearchTable
            rows={data.songs}
            headings={tableHeadings}
            history={props.history}
            umgAssociations={false}
          />
        )}
        <QuickplayPlaysPerDayOnApp />
        {error && (
          <SnackbarMessage severity="error">{this.state.error}</SnackbarMessage>
        )}
      </Container>
    </React.Fragment>
  );
});

const QuickplayEdit = () => {
  const { id: songContentId } = useParams();
  const [submitResult, setSubmitResult] = React.useState(undefined);
  const token = useToken();
  const queryCache = useQueryCache();
  let initialData = queryCache.getQueryData([
    "quickplayContent",
    songContentId,
  ]);
  if (!initialData) {
    initialData = {
      itemId: "",
      imageUrl: "",
      length: "",
      sha1Lava: "",
      sha1Artwork: "",
      displayName: "",
      umgSong: undefined,
      description: "",
      status: "",
      publishedDate: "",
      retiredDate: "",
      retireAt: null,
      releaseAt: null,
      isPremium: false,
      isStandard: false,
      lbEnabled: false,
      vrEnabled: false,
      maxHealth: 0,
    };
  }
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState,
    getValues,
    errors,
  } = useForm({
    defaultValues: initialData,
  });
  const isPremium = watch("isPremium");
  const isStandard = watch("isStandard");

  const { getAccessTokenSilently } = useAuth0();

  const { data: genres = [] } = useQuery("genres", async () => {
    const token = await getAccessTokenSilently();
    return genreApi.findAllGenres(token);
  });

  const { data: result, isLoading } = useQuery(
    ["quickplayContent", songContentId],
    async () => {
      return await quickplayApi.findById({ token, id: songContentId });
    },
    {
      onSuccess: (data) => {
        // if form is changed, don't update from server
        if (!formState.isDirty) {
          reset({
            ...data,
            umgSong: data.umgSong ? data.umgSong : undefined,
            length: `${data.lengthInSeconds}s`,
            publishedDate:
              data.publishedDate !== null
                ? new Date(data.publishedDate).toISOString().substr(0, 10)
                : "",
            retiredDate:
              data.retiredDate !== null
                ? new Date(data.retiredDate).toISOString().substr(0, 10)
                : "",
            releaseAt:
              data.releaseAt !== null ? new Date(data.releaseAt) : null,
            retireAt: data.retireAt !== null ? new Date(data.retireAt) : null,
          });
        }
      },
    }
  );
  useEffect(() => {
    if (isStandard) {
      setValue("isPremium", false);
    }
  }, [isStandard, setValue]);

  useEffect(() => {
    if (isPremium) {
      setValue("isStandard", false);
    }
  }, [isPremium, setValue]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container className="mb-5">
      <h1>Punch Track: {result && result.displayName}</h1>
      <form
        onSubmit={handleSubmit((data) => {
          try {
            const { umgSong, coverArt, track, ...request } = data;
            if (umgSong) {
              request.umgSongId = data.umgSong.id;
            }
            if (coverArt) {
              request.coverArt = coverArt[0];
            }
            if (track) {
              request.track = track[0];
            }

            quickplayApi
              //.updateQuickplay({ token, id: songContentId, data: request })
              .upsertQuickplay({ token, id: songContentId, data: request })
              .then(() => {
                queryCache.invalidateQueries([
                  "quickplayContent",
                  songContentId,
                ]);
                setSubmitResult({
                  result: "success",
                  message: "Successfully Saved!",
                });
                window.location.reload(false);
              });
          } catch (error) {
            setSubmitResult({
              result: "error",
              message: error.message,
            });
          }
        })}
      >
        <input type="text" required name="itemId" ref={register} hidden />
        <input type="text" required name="imageUrl" ref={register} hidden />

        <input type="text" required name="sha1Lava" ref={register} hidden />
        <input type="text" required name="sha1Artwork" ref={register} hidden />
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            {result.imageUrl && (
              <img
                src={`${result.imageUrl}?height=512`}
                style={{
                  maxWidth: "70vw",
                  maxHeight: "70vw",
                  width: "512px",
                  height: "512px",
                }}
                alt=""
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="displayName"
              label="Display Name"
              color="secondary"
              inputRef={register}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ onChange, value }) => {
                return (
                  <UmgSongSelectControl onChange={onChange} value={value} />
                );
              }}
              name="umgSong"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              name="description"
              multiline
              label="Description"
              color="secondary"
              inputRef={register}
            />
          </Grid>
          <Grid item xs={6}>
            {result.inBundle && (
              <div style={{ marginBottom: "1em", color: "red" }}>
                This content is in a bundle, and cannot be retired
              </div>
            )}
            <ReactHookFormSelect
              required
              fullWidth
              name="status"
              label="Status"
              control={control}
              variant="outlined"
              color="secondary"
              defaultValue={result.status || ""}
              disabled={result.inBundle}
            >
              <MenuItem value={"DRAFT"}>Draft</MenuItem>
              <MenuItem value={"SCHEDULED"}>Scheduled</MenuItem>
              <MenuItem value={"PUBLISHED"}>Published</MenuItem>
              <MenuItem value={"RETIRED"}>Retired</MenuItem>
            </ReactHookFormSelect>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={3}>
              <Controller
                name={"publishedDate"}
                control={control}
                render={(props) => {
                  return (
                    <TextField
                      label="Published Date"
                      value={props.value}
                      disabled
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={"releaseAt"}
                control={control}
                render={(props) => {
                  return (
                    <DateTimePicker
                      label="Release At"
                      value={props.value}
                      onChange={(newValue) => {
                        props.onChange(newValue);
                      }}
                      id="release-date-picker"
                      renderInput={(inputProps) => (
                        <TextField fullWidth {...inputProps} />
                      )}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={3}>
              <Controller
                name={"retiredDate"}
                control={control}
                render={(props) => {
                  return (
                    <TextField
                      label="Retired Date"
                      value={props.value}
                      disabled
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={"retireAt"}
                control={control}
                render={(props) => {
                  return (
                    <DateTimePicker
                      label="Retire At"
                      value={props.value}
                      disabled={result.inBundle}
                      onChange={(newValue) => {
                        props.onChange(newValue);
                      }}
                      id="retire-date-picker"
                      renderInput={(inputProps) => (
                        <TextField fullWidth {...inputProps} />
                      )}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <ReactHookFormCheckbox
              name="isPremium"
              label="Premium"
              control={control}
              defaultValue={result.isPremium}
              color="secondary"
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <ReactHookFormCheckbox
                name="isStandard"
                label="Standard"
                control={control}
                defaultValue={result.isStandard}
                color="secondary"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="length"
              label="Length"
              color="secondary"
              helperText={
                !!errors.length
                  ? getErrorMessage("length", errors)
                  : "Must be in format XmXs"
              }
              inputRef={register({ required: true })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <ReactHookFormSelect
              required
              fullWidth
              name="genres"
              label="Genres"
              control={control}
              variant="outlined"
              selectProps={{ multiple: true }}
            >
              {genres.map((genre) => {
                return (
                  <MenuItem value={genre.name} key={genre.name}>
                    {genre.name}
                  </MenuItem>
                );
              })}
            </ReactHookFormSelect>
          </Grid>
          <Grid item xs={4}>
            <CreateGenreDialog />
          </Grid>
          <Grid
            item
            xs={12}
            visibility={Brand === "litesport" ? "visible" : "hidden"}
          >
            <TextField
              required
              fullWidth
              type="number"
              variant="outlined"
              name="maxHealth"
              label="Max Health"
              color="secondary"
              helperText={
                !!errors.length
                  ? getErrorMessage("maxHealth", errors)
                  : "Must be a number"
              }
              inputRef={register({ required: true })}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"vrEnabled"}
              control={control}
              render={(props) => {
                return (
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        checked={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.checked);
                        }}
                      />
                    }
                    label="VR Enabled"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"lbEnabled"}
              control={control}
              render={(props) => {
                return (
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        name={"lbEnabled"}
                        checked={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.checked);
                        }}
                      />
                    }
                    label="LB Enabled"
                  />
                );
              }}
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <UploadButton
                name="coverArt"
                label={"Replace Cover Art"}
                value={getValues("coverArt")}
                error={getErrorMessage("coverArt", errors)}
                ref={register}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item>
              <UploadButton
                name="track"
                accept={".lava"}
                label={"Replace Lava file"}
                helperText={getErrorMessage("track", errors)}
                value={getValues("track")}
                ref={register}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
      {submitResult && (
        <SnackbarMessage
          severity={submitResult.result}
          onClose={() => setSubmitResult(null)}
        >
          {submitResult.message}
        </SnackbarMessage>
      )}
    </Container>
  );
};

const QuickplayCreate = () => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  return (
    <Container className="mb-5" maxWidth={"md"}>
      <h1>Create Punch Tracks</h1>
      <QuickplayForm
        defaultValues={{
          itemId: "",
          artist: "",
          isPremium: false,
          isStandard: false,
          displayName: "",
          description: "",
          length: "",
          status: "",
          publishedDate: "",
          retiredDate: "",
          retireAt: null,
          releaseAt: null,
          genres: [],
          umgSong: "",
          vrEnabled: false,
          lbEnabled: false,
          maxHealth: 0,
        }}
        onSubmit={async (values, setError) => {
          const token = await getAccessTokenSilently();
          const { coverArt, track, artist, umgSong, ...rest } = values;
          try {
            await quickplayApi.createQuickplay({
              ...rest,
              length: values.length,
              coverArt: coverArt[0],
              track: track[0],
              artist: artist.id,
              umgSongId: umgSong ? umgSong.id : undefined,
              token,
            });
            history.push("/quickplay");
          } catch (e) {
            if (e.response.data && e.response.data.error) {
              Object.keys(e.response.data.error).forEach((key) => {
                setError(key, {
                  type: "manual",
                  message: e.response.data.error[key],
                });
              });
            }
          }
        }}
      />
    </Container>
  );
};

export function Quickplay() {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/create`} component={QuickplayCreate} />
      <Route path={`${match.path}/:id`} component={QuickplayEdit} />
      <Route path={match.path} component={QuickplayList} />
    </Switch>
  );
}

export default Quickplay;
