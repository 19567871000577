import { baseUrl } from "../utils/http";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";

export type Equipment = {
  id: number;
  name: string;
};

export const findAllEquipment = async (token: string): Promise<Equipment[]> => {
  const response = await fetch(`${baseUrl}/equipment`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

export const useEquipment = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["equipment"], async () => {
    const token = await getAccessTokenSilently();
    return findAllEquipment(token);
  });
};
