import * as React from "react";
import { TableCell, Typography, TextField } from "@mui/material";
import { useToken } from "../token-provider";
import { useQuery } from "react-query";
import * as trainerContentApi from "../api/trainer-track-api";
import * as contentTypeApi from "../api/content-type-api";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import * as _ from "lodash";
import { FormControlLabel, Switch as MuiSwitch } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Controller, useForm } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const TrainerPlaysPerDayOnApp = (onSubmit, defaultValues) => {
  const classes = useStyles();
  const [sort, setSort] = useState("daysUp");
  const [dir, setDir] = useState("asc");
  const token = useToken();
  const [valLB, setvalLB] = useState("true");
  const [valGO, setvalGO] = useState("true");
  const [valVR, setvalVR] = useState("true");
  const [valContentType, setContentType] = useState("all");

  const { data: contentTypes = [] } = useQuery("contentTypes", async () => {
    return contentTypeApi.findAllContentTypes(token);
  });

  const { data = [], isLoading } = useQuery(
    ["trainer-plays-per-day", { valContentType, valLB, valGO, valVR }],
    () => {
      return trainerContentApi.findPlaysPerDay(
        { token },
        valContentType,
        valLB,
        valGO,
        valVR
      );
    }
  );

  const sortedResults = _.orderBy(
    data.map((d) => {
      return {
        ...d,
        ppd: d.daysUp === 0 ? "-" : d.plays / d.daysUp,
      };
    }),
    sort,
    dir
  );

  const { control } = useForm((defaultValues = {}));

  return (
    <div style={{ marginBottom: 50 }}>
      <Typography variant="h4">Plays per day on app</Typography>
      <Grid item container spacing={2}>
        <Grid item xs={2}>
          <Controller
            name={"includeLB"}
            defaultValue={true}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                        setvalLB(e.target.checked);
                      }}
                    />
                  }
                  label="Include LB"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name={"includeGO"}
            defaultValue={true}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                        setvalGO(e.target.checked);
                      }}
                    />
                  }
                  label="Include GO"
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <Controller
            name={"includeVR"}
            defaultValue={true}
            control={control}
            render={(props) => {
              return (
                <FormControlLabel
                  control={
                    <MuiSwitch
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                        setvalVR(e.target.checked);
                      }}
                    />
                  }
                  label="Include VR"
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            defaultValue={"all"}
            render={(props) => (
              <Autocomplete
                {...props}
                options={
                  props.value === ""
                    ? [{ id: props.value, name: "" }, ...contentTypes]
                    : contentTypes
                }
                getOptionLabel={(option) => {
                  return option.name ? option.name : "";
                }}
                isOptionEqualToValue={(option, value) => {
                  if (value === "" || value === "all") {
                    return true;
                  } else {
                    setContentType(value.id);
                    return option.id === value.id;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Content Type"
                    variant="outlined"
                  />
                )}
                onChange={(_, data) => props.onChange(data)}
              />
            )}
            name="contentType"
            control={control}
          />
        </Grid>
      </Grid>
      {isLoading ? <div>loading...</div> : null}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Content Type</TableCell>
              <TableCell>Display name</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === "ppd"}
                  direction={dir}
                  onClick={() => {
                    setSort("ppd");
                    setDir((prev) => {
                      if (prev === "asc") {
                        return "desc";
                      }
                      return "asc";
                    });
                  }}
                >
                  Plays Per Day
                  {sort === "ppd" ? (
                    <span className={classes.visuallyHidden}>
                      {dir === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel
                  active={sort === "plays"}
                  direction={dir}
                  onClick={() => {
                    setSort("plays");
                    setDir((prev) => {
                      if (prev === "asc") {
                        return "desc";
                      }
                      return "asc";
                    });
                  }}
                >
                  Plays
                  {sort === "plays" ? (
                    <span className={classes.visuallyHidden}>
                      {dir === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sort === "daysUp"}
                  direction={dir}
                  onClick={() => {
                    setSort("daysUp");
                    setDir((prev) => {
                      if (prev === "asc") {
                        return "desc";
                      }
                      return "asc";
                    });
                  }}
                >
                  Days on app
                  {sort === "daysUp" ? (
                    <span className={classes.visuallyHidden}>
                      {dir === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedResults.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.contentDisplayName}
                </TableCell>
                <TableCell>{row.displayName}</TableCell>
                <TableCell>
                  {row.daysUp === 0 ? "-" : row.plays / row.daysUp}
                </TableCell>
                <TableCell>{row.plays}</TableCell>
                <TableCell>{row.daysUp}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TrainerPlaysPerDayOnApp;
