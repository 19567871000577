import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import React from "react";
import { saveAs } from "file-saver";

const uint32ToUInt8Array = (val) => {
  let arrayBuffer = new ArrayBuffer(4);
  let dataView = new DataView(arrayBuffer);
  dataView.setUint32(0, val, false);
  return new Uint8Array(arrayBuffer);
};

const makeLava = async (midi, ogg) => {
  let midiData = new Uint8Array(await midi.arrayBuffer());
  let oggData = new Uint8Array(await ogg.arrayBuffer());

  let fileVersion = 1;
  let headerLength = 20;

  let lavaData = new Blob([
    uint32ToUInt8Array(fileVersion), // File version
    uint32ToUInt8Array(headerLength), // Audio offset
    uint32ToUInt8Array(oggData.length),
    uint32ToUInt8Array(headerLength + oggData.length), // MIDI offset
    uint32ToUInt8Array(midiData.length),
    oggData,
    midiData,
  ]);

  saveAs(lavaData, midi.name + ".lava");
};

export const Volcano = () => {
  const [oggFile, setOggFile] = React.useState(null);
  const [midiFile, setMidiFile] = React.useState(null);

  return (
    <Container className="mb-5">
      <Typography variant="h1" style={{ marginBottom: "10px" }}>
        Volcano
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} align="center">
          <Typography variant="h4">
            Select an OGG file and a MIDI file to create a Lava file!
          </Typography>
        </Grid>
        <Grid item xs={5} align="center">
          <Button variant="contained" component="label">
            Select OGG file
            <input
              type="file"
              onChange={(event) => setOggFile(event.target.files[0])}
              hidden
            />
          </Button>
        </Grid>
        <Grid item xs={7}>
          {oggFile && oggFile.name}
        </Grid>
        <Grid item xs={5} align="center">
          <Button variant="contained" component="label">
            Select MIDI file
            <input
              type="file"
              onChange={(event) => setMidiFile(event.target.files[0])}
              hidden
            />
          </Button>
        </Grid>
        <Grid item xs={7}>
          {midiFile && midiFile.name}
        </Grid>
        <Grid item xs={12} align="center">
          {midiFile && oggFile && (
            <Button
              variant="contained"
              component="label"
              onClick={() => makeLava(midiFile, oggFile)}
            >
              Erupt
            </Button>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Volcano;
