import { baseUrl } from "../utils/http";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";

export type Brand = {
  id: number;
  name: string;
};

export const findAllBrands = async (token: string): Promise<Brand[]> => {
  const response = await fetch(`${baseUrl}/brands`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

export const useBrands = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["brands"], async () => {
    const token = await getAccessTokenSilently();
    return findAllBrands(token);
  });
};
