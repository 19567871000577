import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import * as artistApi from "../api/artistApi";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { ReactHookFormCheckbox, ReactHookFormSelect } from "./index";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import * as genreApi from "../api/genre-api";
import CreateGenreDialog from "./create-genre-dialog";
import CreateArtistDialog from "./create-artist-dialog";
import UmgSongSelectControl from "./umg-song-select-dialog";
import { DateTimePicker } from "@mui/lab";
import { Brand } from "../app";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
  })
);

const getErrorMessage = (key, errors) => {
  return errors[key] && errors[key].type === "required"
    ? "This field is required"
    : errors[key] && errors[key].type === "min"
    ? "This field is required"
    : errors[key] && errors[key].type === "manual"
    ? errors[key].message
    : "";
};

const UploadButton = React.forwardRef(
  ({ label, accept = "image/*", value, name }, ref) => {
    const classes = useStyles();
    let selectedFile = "No file selected";
    if (value && value.length > 0) {
      selectedFile = value[0].name;
    }
    return (
      <React.Fragment>
        <div>
          <input
            accept={accept}
            className={classes.input}
            id={name}
            type="file"
            name={name}
            ref={ref}
          />
          <label htmlFor={name}>
            <Button variant="contained" color="primary" component="span">
              {label}
            </Button>
            <span>{selectedFile}</span>
          </label>
        </div>
      </React.Fragment>
    );
  }
);

const QuickplayForm = ({ onSubmit, defaultValues }) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data: artists = [] } = useQuery("artists", async () => {
    const token = await getAccessTokenSilently();
    return artistApi.findAllArtists(token);
  });
  const { data: genres = [] } = useQuery("genres", async () => {
    const token = await getAccessTokenSilently();
    return genreApi.findAllGenres(token);
  });
  const { register, handleSubmit, control, errors, getValues, setError } =
    useForm({
      defaultValues,
    });
  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data, setError);
      })}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            variant="outlined"
            name="displayName"
            label="Display Name"
            helperText={getErrorMessage("displayName", errors)}
            error={!!errors.displayName}
            color="secondary"
            inputRef={register({ required: true })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            name="description"
            multiline
            label="Description"
            helperText={getErrorMessage("description", errors)}
            color="secondary"
            inputRef={register}
          />
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Controller
              render={({ onChange, value }) => {
                return (
                  <UmgSongSelectControl onChange={onChange} value={value} />
                );
              }}
              name="umgSong"
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <UploadButton
              name="coverArt"
              label={"Cover Art"}
              helperText={getErrorMessage("coverArt", errors)}
              value={getValues("coverArt")}
              ref={register}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <UploadButton
              name="track"
              accept={".lava"}
              label={"Lava file"}
              helperText={getErrorMessage("track", errors)}
              value={getValues("track")}
              ref={register}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6} style={{ textAlign: "center" }}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="length"
              label="Length"
              color="secondary"
              helperText={
                !!errors.length
                  ? getErrorMessage("length", errors)
                  : "Must be in format XmXs"
              }
              error={!!errors.length}
              inputRef={register({ required: true })}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <ReactHookFormSelect
              required
              fullWidth
              name="status"
              label="Status"
              control={control}
              variant="outlined"
              color="secondary"
              helperText={getErrorMessage("status", errors)}
              controlProps={{ rules: { required: true } }}
            >
              <MenuItem value={"DRAFT"}>Draft</MenuItem>
              <MenuItem value={"SCHEDULED"}>Scheduled</MenuItem>
              <MenuItem value={"PUBLISHED"}>Published</MenuItem>
              <MenuItem value={"RETIRED"}>Retired</MenuItem>
            </ReactHookFormSelect>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <Controller
              name={"publishedDate"}
              control={control}
              render={(props) => {
                return (
                  <TextField
                    label="Published Date"
                    value={props.value}
                    disabled
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={"releaseAt"}
              control={control}
              render={(props) => {
                return (
                  <DateTimePicker
                    label="Release At"
                    value={props.value}
                    onChange={(newValue) => {
                      props.onChange(newValue);
                    }}
                    id="release-date-picker"
                    renderInput={(inputProps) => (
                      <TextField fullWidth {...inputProps} />
                    )}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={3}>
            <Controller
              name={"retiredDate"}
              control={control}
              render={(props) => {
                return (
                  <TextField
                    label="Retired Date"
                    value={props.value}
                    disabled
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={"retireAt"}
              control={control}
              render={(props) => {
                return (
                  <DateTimePicker
                    label="Retire At"
                    value={props.value}
                    onChange={(newValue) => {
                      props.onChange(newValue);
                    }}
                    id="retire-date-picker"
                    renderInput={(inputProps) => (
                      <TextField fullWidth {...inputProps} />
                    )}
                  />
                );
              }}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <ReactHookFormCheckbox
                name="isPremium"
                label="Is Premium"
                control={control}
                color="secondary"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <ReactHookFormCheckbox
                  name="isStandard"
                  label="Is Standard"
                  control={control}
                  color="secondary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <Controller
              render={(props) => (
                <Autocomplete
                  {...props}
                  options={
                    props.value === ""
                      ? [{ id: props.value, name: "" }, ...artists]
                      : artists
                  }
                  getOptionLabel={(option) => {
                    return option.name ? option.name : "";
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return option.id === value;
                    } else {
                      return option.id === value.id;
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Artist"
                      variant="outlined"
                      required
                    />
                  )}
                  onChange={(_, data) => props.onChange(data)}
                />
              )}
              name="artist"
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <CreateArtistDialog />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={8}>
            <ReactHookFormSelect
              required
              fullWidth
              name="genres"
              label="Genres"
              control={control}
              variant="outlined"
              selectProps={{ multiple: true }}
              helperText={getErrorMessage("genres", errors)}
            >
              {genres.map((genre) => {
                return (
                  <MenuItem value={genre.name} key={genre.name}>
                    {genre.name}
                  </MenuItem>
                );
              })}
            </ReactHookFormSelect>
          </Grid>
          <Grid item xs={4}>
            <CreateGenreDialog />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"vrEnabled"}
              control={control}
              render={(props) => {
                return (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.checked);
                        }}
                      />
                    }
                    label="VR Enabled"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"lbEnabled"}
              control={control}
              render={(props) => {
                return (
                  <FormControlLabel
                    control={
                      <Switch
                        name={"lbEnabled"}
                        checked={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.checked);
                        }}
                      />
                    }
                    label="LB Enabled"
                  />
                );
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            visibility={Brand === "litesport" ? "visible" : "hidden"}
          >
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              name="maxHealth"
              label="Max Health"
              helperText={getErrorMessage("maxHealth", errors)}
              color="secondary"
              inputRef={register}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
export default QuickplayForm;
