import { baseUrl } from "../utils/http";

const adminTrainerUrl = `${baseUrl}/trainers`;

const findAllTrainers = async (token) => {
  const response = await fetch(adminTrainerUrl, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

const createTrainer = async (token, name) => {
  const response = await fetch(adminTrainerUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
    }),
  });
  if (!(response.status === 200 || response.status === 201)) {
    const body = await response.json();
    const error = new Error();
    error.response = {
      code: response.status,
      data: body,
    };
    throw error;
  }
  return await response.json();
};

export { findAllTrainers, createTrainer };
