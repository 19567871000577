import React from "react";
import { Loading } from "../components";
import { useFetch } from "../utils";
import { withAuth0 } from "@auth0/auth0-react";
import {
  Card,
  CardContent,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  Link,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBoundary } from "react-error-boundary";
import * as statsApi from "../api/stats-api";
import { useToken } from "../token-provider";
import { useQuery } from "react-query";
import { subDays, format, isValid, isAfter } from "date-fns";
import DatePicker from "@mui/lab/DatePicker";
import List from "@mui/material/List";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const LBCard = ({ title, value }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card variant="outlined">
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            {title}
          </Typography>
          <div style={{ textAlign: "center" }}>
            <Typography variant="h4" style={{ fontSize: "3rem" }}>
              {value}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

const KPIs = ({
  activeUnitsForPeriod,
  totalWorkoutsForPeriod,
  workoutsPerActiveUnitForPeriod,
  formattedStartDate,
  formattedEndDate,
}) => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Typography variant="h3">
        KPIs ({formattedStartDate} - {formattedEndDate})
      </Typography>
      <Grid container spacing={3}>
        <LBCard title={"Active Units"} value={activeUnitsForPeriod} />
        <LBCard title={"Total Workouts"} value={totalWorkoutsForPeriod} />
        <LBCard
          title={"Workouts per active unit"}
          value={
            workoutsPerActiveUnitForPeriod
              ? workoutsPerActiveUnitForPeriod.toFixed(2)
              : "-"
          }
        />
      </Grid>
    </Container>
  );
};

const InactiveDeviceDialog = ({ onClose, open, inactiveUnits }) => {
  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Inactive devices</DialogTitle>
      <List>
        {inactiveUnits?.map((unit) => {
          return (
            <ListItem
              key={unit}
              component={RouterLink}
              to={`/device/${unit}`}
              button
            >
              <ListItemText primary={unit} />
            </ListItem>
          );
        })}
      </List>
    </Dialog>
  );
};

const useEngagementStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  container: {
    marginBottom: theme.spacing(2),
  },
}));
const EngagementBreakDown = ({
  totalWorkoutTimePerUnit,
  numOfActiveMembersPerActiveUnit,
  numActiveDaysInMonthPerActiveUnit,
  numUnitsStoppedActive,
  inactiveUnits,
  formattedEndDate,
  formattedStartDate,
}) => {
  const classes = useEngagementStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <Container className={classes.container}>
      <Typography variant="h3">
        Engagement Breakdown ({formattedStartDate} - {formattedEndDate})
      </Typography>
      <Grid container spacing={3}>
        <LBCard
          title={"Total workout time per unit"}
          value={
            totalWorkoutTimePerUnit
              ? (totalWorkoutTimePerUnit / 60).toFixed(2) + "m"
              : "-"
          }
        />
        <LBCard
          title={"# of active members per active unit"}
          value={
            numOfActiveMembersPerActiveUnit
              ? numOfActiveMembersPerActiveUnit.toFixed(2)
              : "-"
          }
        />
        {/* TODO <LBCard*/}
        {/*  title={"# of active days in month per active unit"}*/}
        {/*  value={numActiveDaysInMonthPerActiveUnit}*/}
        {/*/>*/}
        <LBCard
          title={
            <>
              # of units that stopped being active{" "}
              <Link
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                View
              </Link>
              <InactiveDeviceDialog
                inactiveUnits={inactiveUnits}
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
              />
            </>
          }
          value={numUnitsStoppedActive}
        />
      </Grid>
    </Container>
  );
};

const WorkoutsBreakDown = ({
  punchTracksForPeriod,
  classesForPeriod,
  sparringForPeriod,
  buildAndRestoreForPeriod,
  freestyleForPeriod,
  formattedEndDate,
  formattedStartDate,
}) => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Typography variant="h3">
        Engagement Breakdown ({formattedStartDate} - {formattedEndDate})
      </Typography>
      <Grid container spacing={3}>
        <LBCard
          title={"Punch tracks (over 50%)"}
          value={punchTracksForPeriod}
        />
        <LBCard title={"Classes (over 50%)"} value={classesForPeriod} />
        <LBCard
          title={"Sparring Sessions (over 50%)"}
          value={sparringForPeriod}
        />
        <LBCard
          title={"Build and restore (over 50%)"}
          value={buildAndRestoreForPeriod}
        />
        <LBCard title={"Freestyle (over 60s)"} value={freestyleForPeriod} />
      </Grid>
    </Container>
  );
};

const AllTime = ({
  totalUnits,
  totalMembers,
  totalWorkouts,
  totalUnitsProcessedOmni,
}) => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Typography variant="h3">All time</Typography>
      <Grid container spacing={3}>
        <LBCard
          title={"Total Units Processed (Omni)"}
          value={totalUnitsProcessedOmni}
        />
        <LBCard title={"Total Active Units"} value={totalUnits} />
        <LBCard title={"Total Members"} value={totalMembers} />
        <LBCard title={"Total Workouts"} value={totalWorkouts} />
      </Grid>
    </Container>
  );
};

const Overview = withAuth0(() => {
  const token = useToken();
  const [startDate, setStartDate] = React.useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = React.useState(new Date());

  const { data } = useQuery(
    ["dashboard-stats", startDate, endDate],
    () => {
      return statsApi.findStats(token, startDate, endDate);
    },
    {
      enabled:
        isAfter(endDate, startDate) && isValid(startDate) && isValid(endDate),
    }
  );
  const formattedStartDate = isValid(startDate)
    ? format(startDate, "MM/dd/yyyy")
    : "Invalid Date";
  const formattedEndDate = isValid(endDate)
    ? format(endDate, "MM/dd/yyyy")
    : "Invalid Date";
  const classes = useStyles();
  const cards = [
    {
      endPoint: "/users/count",
      element: "count",
      count: false,
      label: "Total Users",
    },
    {
      endPoint: "/content/song",
      element: "songs",
      count: true,
      label: "Punch Tracks",
    },
    {
      endPoint: "/content/video/all",
      element: "tracks",
      count: true,
      label: "Workouts",
    },
  ];

  for (let i = 0; i < cards.length; i++) {
    // Get outtahere eslint I know what I'm doing
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data, error } = useFetch(cards[i].endPoint);
    cards[i].data = data;
    cards[i].error = error;
  }

  for (let i = 0; i < cards.length; i++) {
    if (!cards[i].data && !cards[i].error) {
      return <Loading />;
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Container className={classes.container}>
        <Typography variant="h3">Overview</Typography>
        <Grid container spacing={3}>
          {cards.map((val, _) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={val.label}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      {val.label}
                    </Typography>
                    <div style={{ textAlign: "center" }}>
                      <Typography variant="h3" style={{ fontSize: "4rem" }}>
                        {val.count
                          ? (val.element ? val.data[val.element] : val.data)
                              .length
                          : val.element
                          ? val.data[val.element]
                          : val.data}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>

      <>
        <Container className={classes.container}>
          <DatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="start-date-picker"
            renderInput={(props) => <TextField label="Start Date" {...props} />}
            error={isAfter(startDate, endDate)}
            helperText={
              isAfter(startDate, endDate)
                ? "Start Date must be before end date"
                : undefined
            }
            value={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
          />
          <DatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="end-date-picker"
            value={endDate}
            renderInput={(props) => <TextField label="End Date" {...props} />}
            onChange={(date) => {
              setEndDate(date);
            }}
          />
        </Container>
        <KPIs
          formattedStartDate={formattedStartDate}
          formattedEndDate={formattedEndDate}
          activeUnitsForPeriod={data?.activeUnitsForPeriod}
          totalWorkoutsForPeriod={data?.totalWorkoutsForPeriod}
          workoutsPerActiveUnitForPeriod={data?.workoutsPerActiveUnitForPeriod}
        />
        <EngagementBreakDown
          formattedStartDate={formattedStartDate}
          formattedEndDate={formattedEndDate}
          totalWorkoutTimePerUnit={data?.totalWorkoutTimePerUnit}
          numOfActiveMembersPerActiveUnit={data?.numActiveMembersPerActiveUnit}
          numActiveDaysInMonthPerActiveUnit={
            data?.numActiveDaysInMonthPerActiveUnit
          }
          numUnitsStoppedActive={data?.numUnitsThatStoppedBeingActive}
          inactiveUnits={data?.inactiveDeviceIds}
        />
        <WorkoutsBreakDown
          formattedStartDate={formattedStartDate}
          formattedEndDate={formattedEndDate}
          punchTracksForPeriod={data?.totalPunchTracksForPeriod}
          classesForPeriod={data?.classesForPeriod}
          sparringForPeriod={data?.sparringForPeriod}
          buildAndRestoreForPeriod={data?.buildAndRestoreForPeriod}
          freestyleForPeriod={data?.freestyleForPeriod}
        />
        <AllTime
          totalUnits={data?.totalActiveUnits}
          totalMembers={data?.totalMembers}
          totalWorkouts={data?.totalWorkouts}
          totalUnitsProcessedOmni={data?.totalOmniUnits}
        />
      </>
    </ErrorBoundary>
  );
});

export default Overview;
