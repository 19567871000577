import { Route, Switch, useRouteMatch } from "react-router-dom";
import * as React from "react";
import { PlaylistsListPage } from "./playlists-list-page";
import { EditPlaylist } from "./edit-playlist";
import { CreatePlaylist } from "./create-playlist";

export const PlaylistsIndex = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} exact>
        <PlaylistsListPage />
      </Route>
      <Route path={`${match.path}/create`} exact>
        <CreatePlaylist />
      </Route>
      <Route path={`${match.path}/:id`}>
        <EditPlaylist />
      </Route>
    </Switch>
  );
};
