import { baseUrl } from "../utils/http";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";

const findAllGenres = async (token) => {
  const response = await fetch(`${baseUrl}/genres`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

export const useGenres = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["genres"], async () => {
    const token = await getAccessTokenSilently();
    return findAllGenres(token);
  });
};

const createGenre = async (token, name) => {
  const response = await fetch(`${baseUrl}/genres`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
    }),
  });
  if (!(response.status === 200 || response.status === 201)) {
    const body = await response.json();
    const error = new Error();
    error.response = {
      code: response.status,
      data: body,
    };
    throw error;
  }
  return await response.json();
};

export { findAllGenres, createGenre };
